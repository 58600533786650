import { ReactElement, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '@/components/molecules/Header';
import { SideBar } from '@/components/molecules/SideBar';

import { Container, MainContainer } from './styles';

export const MainTemplate: React.FC = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(true);

  const handleDrawerOpen = (): void => setIsOpen(true);

  const handleDrawerClose = (): void => setIsOpen(false);

  return (
    <Container>
      <Header isOpen={isOpen} onDrawerOpen={handleDrawerOpen} />
      <SideBar isOpen={isOpen} onClose={handleDrawerClose} />
      <MainContainer isOpen={isOpen}>
        <Outlet />
      </MainContainer>
    </Container>
  );
};
