import DoneAllIcon from '@mui/icons-material/DoneAll';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import { Form } from 'react-final-form';

import { SelectLocale } from '@/components/atoms/SelectLocale';
import { TextFormField } from '@/components/atoms/TextFormField/input';
import { useToast } from '@/components/atoms/Toast/hooks';
import { composeValidators, isEmail, required } from '@/constants/validate';
import { useCreateInvite } from '@/lib/graphql/mutations/invites/useCreateInvite';

interface IHandleSaveParams extends Record<string, string> {}

export const SendInviteForm: FC = () => {
  const { createInvite, error: inviteError, reset } = useCreateInvite();
  const [locale, setLocale] = useState<string>('ru');
  const { showToast } = useToast();

  const handleCreate = async (value: IHandleSaveParams): Promise<void> => {
    await createInvite(value.email, locale);
  };

  if (inviteError != null) {
    showToast('Пользователь с таким email уже существует', 'error');
    reset();
  }

  return (
    <Form initialValues="" onSubmit={handleCreate}>
      {({ handleSubmit }) => (
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Stack direction="row">
            <TextFormField
              label="email"
              name="email"
              validate={composeValidators(required, isEmail)}
            />
            <Tooltip title="Отправить инвайт">
              <IconButton size="large" type="submit">
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <SelectLocale value={locale} onChangeLocale={setLocale} />
        </form>
      )}
    </Form>
  );
};
