import { Visibility } from '@mui/icons-material';
import { Container, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { FC, useState } from 'react';

import ModalWindow from '@/components/atoms/ModalWindow';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useUpdateProducts } from '@/lib/graphql/mutations/useUpdateProducts';
import {
  IProductItem,
  UpdateProductInput,
} from '@/types/productItem.interface';

interface HideSelectedButtonProps {
  ids: Set<string>;
  products: IProductItem[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export const HideSelectedButton: FC<HideSelectedButtonProps> = ({
  ids,
  products,
  setSelectedProducts,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { updateProducts, loading } = useUpdateProducts();

  const { showToast } = useToast();

  const handleOpen = (): void => setIsOpen(true);
  const handleClose = (): void => setIsOpen(false);

  const handleUpdateClick = async (): Promise<void> => {
    const updateProductsInput = products.reduce<UpdateProductInput[]>(
      (acc, product) => {
        if (ids.has(product.id)) {
          acc.push({ id: product.id, isHidden: !product.isHidden });
        }
        return acc;
      },
      [],
    );

    await updateProducts(updateProductsInput);

    showToast(`Products visibility status updated`);

    setSelectedProducts(new Set<string>());

    handleClose();
  };

  return (
    <Container>
      <IconButton onClick={handleOpen}>
        <Visibility />
      </IconButton>
      <ModalWindow isOpen={isOpen} onClose={handleClose}>
        <Stack p={1} spacing={4}>
          <Typography>
            Are you sure you want to change products visibility status?
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button
              color="error"
              disabled={loading}
              variant="contained"
              onClick={handleUpdateClick}
            >
              Update
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </ModalWindow>
    </Container>
  );
};
