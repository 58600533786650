import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { ICharacteristicItem } from '@/types/characteristic.interface';

interface ICharacteristicResponseData {
  characteristic: ICharacteristicItem;
}

interface ICharacteristicVariables {
  id: string;
}

interface ICharacteristic {
  characteristic?: ICharacteristicItem;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const CHARACTERISTIC_QUERY = gql`
  query characteristic($id: String!) {
    characteristic(id: $id) {
      id
      name
      measures {
        id
        name
      }
    }
  }
`;

export const useCharacteristic = (id: string): ICharacteristic => {
  const { data, loading, refetch } = useQuery<
    ICharacteristicResponseData,
    ICharacteristicVariables
  >(CHARACTERISTIC_QUERY, {
    variables: { id },
  });

  const characteristic = data?.characteristic;

  return { characteristic, loading, refetch };
};
