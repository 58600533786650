import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { IMeasureItem } from '@/types/measure.interface';

interface IMeasureResponseData {
  measure: IMeasureItem;
}

interface IMeasureVariables {
  id: string;
}

interface IMeasures {
  measure?: IMeasureItem;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const MEASURE = gql`
  query measure($id: String!) {
    measure(id: $id) {
      id
      name
    }
  }
`;

export const useMeasure = (id: string): IMeasures => {
  const { data, loading, refetch } = useQuery<
    IMeasureResponseData,
    IMeasureVariables
  >(MEASURE, { variables: { id } });

  const measure = data?.measure;

  return { measure, loading, refetch };
};
