import { debounce } from 'lodash';
import { useState } from 'react';
import { Form } from 'react-final-form';

import { Pagination } from '@/components/atoms/Pagination';
import { DEFAULT_INITIAL_PAGE_NUMBER } from '@/constants/constants';
import { useProducts } from '@/lib/graphql/queries/useProducts';
import { ProductListParamsInput } from '@/lib/graphql/queries/useProducts';
import {
  ProductOrderField,
  ProductVisibleSortOrderField,
} from '@/lib/graphql/types/product-fields.enum';
import useQueryState from '@/lib/hooks/useQueryState';
import * as scheme from '@/lib/utils/io-ts/codec';
import { formattedDate } from '@/utils/date';

import { ControlPanel } from './components/ControlPanel';
import { FiltersPanel } from './components/FiltersPanel';
import { ListItems } from './components/List';
import { DEBOUNCE_DELAY } from './const';
import { Paper } from './styles';

export type ProductListParams = ProductListParamsInput;

export const ProductList: React.FC = () => {
  const [page, setPage] = useQueryState('page', scheme.numericString, {
    defaultValue: DEFAULT_INITIAL_PAGE_NUMBER,
  });

  const initialFilterValues = {
    minPrice: null,
    maxPrice: null,
    fromDate: null,
    toDate: null,
    sortBy: ProductOrderField.new,
    showHiddenProducts: true,
    visibilityStatus: ProductVisibleSortOrderField.all,
    search: '',
    organizationsIds: []
  };

  const [formValues, setFormValues] = useState<ProductListParams>(initialFilterValues);
  const [selectedProducts, setSelectedProducts] = useState(new Set<string>());

  const handleToggleProduct = (id: string): void => {
    setSelectedProducts((prevSelectedProducts) => {
      const newSelectedProducts = new Set(prevSelectedProducts);
      newSelectedProducts.has(id)
        ? newSelectedProducts.delete(id)
        : newSelectedProducts.add(id);
      return newSelectedProducts;
    });
  };

  const handleChangeFilterValues = debounce(
    (
      values: ProductListParams,
    ): void => {
      setFormValues(values);
    },
    DEBOUNCE_DELAY,
  );
  
  const {
    sortBy,
    showHiddenProducts,
    visibilityStatus,
    search,
    organizationsIds,
    minPrice,
    maxPrice,
    fromDate,
    toDate,
  } = formValues;

  const { products, total, loading } = useProducts({
    page,
    sortBy,
    showHiddenProducts,
    visibilityStatus,
    search,
    organizationsIds,
    minPrice: minPrice && Number(minPrice),
    maxPrice: maxPrice && Number(maxPrice),
    fromDate: fromDate && formattedDate(fromDate),
    toDate,
  });

  const onSubmit = () => {
    return
  };

  return (
    <>
      {selectedProducts.size !== 0 ? (
        <ControlPanel
          products={products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      ) : null}
      <Paper>
      <Form
        initialValues={formValues}
        render={() => (
          <form>
            <FiltersPanel changeFilterValues={handleChangeFilterValues}/>
          </form>
        )}
        onSubmit={onSubmit}
      />
        <ListItems
          handleToggleProduct={handleToggleProduct}
          loading={loading}
          products={products}
          selectedProducts={selectedProducts}
        />
        <Pagination page={page} total={total} onChange={setPage} />
      </Paper>
    </>
  );
};
