import { SxProps } from '@mui/material';
import { ReactElement } from 'react';

import { IBannerItem } from '@/types/banner.interface';

import { BannerBox, ImageBox, Text, TextStack, Title } from './styles';

interface IBanner {
  banner: IBannerItem;
  sx?: { title: SxProps; text: SxProps };
}

export const Banner = ({ banner, sx }: IBanner): ReactElement => {
  const { title, text, backgroundImageUrl, backgroundColor } = banner;

  return (
    <BannerBox>
      <TextStack>
        <Title sx={sx?.title} variant="h3">
          {title}
        </Title>
        <Text sx={sx?.text} variant="body1">
          {text}
        </Text>
      </TextStack>
      <ImageBox
        backgroundColor={backgroundColor}
        backgroundImageUrl={backgroundImageUrl}
      ></ImageBox>
    </BannerBox>
  );
};
