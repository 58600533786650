import styled from '@emotion/styled';
import { Paper as BasePaper, Typography } from '@mui/material';

export const Paper = styled(BasePaper)`
  &.MuiPaper-root {
    width: 100%;
    padding: 20px 20px 40px 20px;
  }
`;

export const TextElement = styled(Typography)`
  font-weight: 600;
  margin: 5px;
`;
