import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { TEST_RESULT_DTO_FRAGMENT } from '../../fragments';

import { ITestResultResponseItem } from './useTestResults';

export interface ITestResult {
  testResult?: ITestResultResponseItem;
  total: number;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export interface ITestResultResponse {
  scrapeTestResultById?: ITestResultResponseItem;
  total: number;
}

export const TEST_RESULT_BY_ID = gql`
  ${TEST_RESULT_DTO_FRAGMENT}
  query scrapeTestResultById($id: String!) {
    scrapeTestResultById(id: $id) {
      ...TestResultDTOFragment
    }
  }
`;

export const useTestResult = (id: string | undefined): ITestResult => {
  const { data, loading, refetch } = useQuery<ITestResultResponse>(
    TEST_RESULT_BY_ID,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id },
    },
  );

  const testResult = data?.scrapeTestResultById;

  const total = data?.total ?? 0;

  return { testResult, total, loading, refetch };
};
