import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 30px;
`;

export const MainImage = styled.img`
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  object-fit: contain;
  padding: 10px;
`;

export const RestImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 10px;
`;

export const SmallImage = styled.img`
  width: 100%;
  max-width: 150px;
  max-height: 150px;
  object-fit: contain;
  padding: 5px;
`;

export const EmptyImage = styled.div`
  width: 500px;
  height: 400px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #000;
  margin: 30px;
`;
