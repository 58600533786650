import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { IBannerItem } from '@/types/banner.interface';

import { BANNER_DTO_FRAGMENT } from '../fragments';

interface IBanners {
  banners?: IBannerItem[];
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const BANNERS = gql`
  ${BANNER_DTO_FRAGMENT}
  query banners {
    banners {
      ...BannerDTOFragment
    }
  }
`;

export const useBanners = (): IBanners => {
  const { data, loading, refetch } = useQuery<IBanners>(BANNERS);

  const banners = data?.banners;

  return { banners, loading, refetch };
};
