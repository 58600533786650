import { Button } from '@mui/material';
import { ReactElement } from 'react';

import { Stack } from './styles';

interface IApprovalControls {
  isSaveButtonDisabled: boolean;
  onApproveClick: () => Promise<void>;
  onRejectClick: () => Promise<void>;
}

export const ApprovalControls = ({
  onApproveClick,
  onRejectClick,
  isSaveButtonDisabled,
}: IApprovalControls): ReactElement => {
  return (
    <Stack direction="row">
      <Button
        disabled={isSaveButtonDisabled}
        variant="contained"
        onClick={onApproveClick}
      >
        Сохранить
      </Button>
      <Button color="error" variant="contained" onClick={onRejectClick}>
        Уведомить об отсутствии
      </Button>
    </Stack>
  );
};
