import { FC } from 'react';

import { IProductItem } from '@/types/productItem.interface';

import { ProductInfoComponent } from './components/CommercialComponent';
import { ImageComponent } from './components/ImageComponent';
import { BoldText, Container, Description, Text } from './styles';

export const ProductCardAppearance: FC<{ product?: IProductItem }> = ({
  product,
}) => {
  return (
    <>
      <Container>
        <ImageComponent images={product?.images} />
        <ProductInfoComponent product={product} />
      </Container>
      <Description>
        <BoldText>Description: </BoldText>
        <Text>{product?.description}</Text>
      </Description>
      <Description>
        <BoldText>Characteristics: </BoldText>
        {product?.characteristics?.map((characteristic, index) => (
          <Text key={index}>
            {characteristic.characteristic.name}
            {characteristic.measure
              ? `, ${characteristic.measure.name}`
              : ''}: {characteristic.value}
          </Text>
        ))}
      </Description>
    </>
  );
};
