import styled from '@emotion/styled';
import {
  Button as BaseButton,
  IconButton as IconButtonBase,
  Typography,
} from '@mui/material';

export const IconButton = styled(IconButtonBase)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Container = styled.div`
  height: 660px;
  padding: 58px;
  position: relative;
  width: 550px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);

  & > form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    width: 100%;
  }
`;

export const Title = styled(Typography)`
  text-align: left;
  width: 100%;
  font-size: 20px;
`;

export const Button = styled(BaseButton)`
  text-transform: uppercase;
  font-size: 16px;
`;
