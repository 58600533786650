import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { QueryParams } from '@/types/query-params.type';
import { IScraperTask } from '@/types/scraper-task.interface';

import { SCRAPER_TASK_DTO_FRAGMENT } from '../fragments';

interface ScraperTasksParamsInput extends QueryParams {
  sourceId?: number;
}

interface ScraperTaskPageDTO {
  nodes: IScraperTask[];
  total: number;
}

interface QueryScraperTasksResult {
  scraperTasks: ScraperTaskPageDTO;
}

export interface IScraperTasks {
  scraperTasks: IScraperTask[];
  total: number;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const SCRAPER_TASKS = gql`
  ${SCRAPER_TASK_DTO_FRAGMENT}
  query scraperTasks($params: ScraperTasksParamsInput) {
    scraperTasks(params: $params) {
      nodes {
        ...ScraperTaskDTOFragment
        user {
          email
        }
        source {
          name
        }
      }
      total
    }
  }
`;

export const useScraperTasks = (
  params?: ScraperTasksParamsInput,
): IScraperTasks => {
  const { data, loading, refetch } = useQuery<QueryScraperTasksResult>(
    SCRAPER_TASKS,
    {
      fetchPolicy: 'cache-and-network',
      variables: { params },
    },
  );

  const scraperTasks = data?.scraperTasks.nodes ?? [];

  const total = data?.scraperTasks.total ?? 0;

  return { scraperTasks, total, loading, refetch };
};
