import { FetchResult, gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { INVITES_DTO_FRAGMENT } from '../../fragments';
import { IInvite, INVITES } from '../../queries/invites/useInvites';

interface ICreateInviteResponseData {
  createInvite: IInvite;
}

interface ICreateInviteVariables {
  email: string;
  locale: string;
}

interface ICreateInvite extends MutationResult<ICreateInviteResponseData> {
  createInvite: (
    email: string,
    locale: string,
  ) => Promise<FetchResult<ICreateInviteResponseData>>;
}

export const CREATE_INVITE = gql`
  ${INVITES_DTO_FRAGMENT}

  mutation createInvite($email: String!, $locale: String!) {
    createInvite(email: $email, locale: $locale) {
      ...InviteDTOFragment
    }
  }
`;

export const useCreateInvite = (): ICreateInvite => {
  const [mutate, otherOptions] = useMutation<
    ICreateInviteResponseData,
    ICreateInviteVariables
  >(CREATE_INVITE, {
    refetchQueries: [INVITES],
  });

  const createInvite = useCallback(
    (
      email: string,
      locale: string,
    ): Promise<FetchResult<ICreateInviteResponseData>> =>
      mutate({
        variables: { email, locale },
      }),
    [mutate],
  );

  return { createInvite, ...otherOptions };
};
