import React from 'react';
import { Field } from 'react-final-form';

import { Container, ErrorText, TextInput } from './styles';

interface Props {
  label?: string;
  name: string;
  disabled?: boolean;
  validator?: (value: string) => string | undefined;
}

export const InputField: React.FC<Props> = ({
  label,
  name,
  disabled,
  validator,
}) => {
  return (
    <Field name={name} validate={validator}>
      {({ input, meta }) => {
        const error = Boolean(meta.error) && meta.touched;
        const errorText = Boolean(meta.error);

        return (
          <Container>
            <TextInput
              required
              disabled={disabled}
              error={error}
              name={input.name}
              placeholder={label ?? name}
              value={input.value}
              onBlur={input.onBlur}
              onChange={input.onChange}
            />
            {error ? <ErrorText>{errorText}</ErrorText> : null}
          </Container>
        );
      }}
    </Field>
  );
};
