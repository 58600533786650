import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface IUseRemoveFeedback {
  removeFeedback: (id: string) => Promise<FetchResult<boolean>>;
}

export const REMOVE_FEEDBACK = gql`
  mutation removeFeedback($id: String!) {
    removeFeedback(id: $id)
  }
`;

export const useRemoveFeedback = (): IUseRemoveFeedback => {
  const [mutate] = useMutation<boolean>(REMOVE_FEEDBACK);

  const removeFeedback = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: {
          id,
        },
      }),
    [mutate],
  );

  return { removeFeedback };
};
