import styled from '@emotion/styled';
import { Drawer as BaseDrawer, css } from '@mui/material';

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
  height: 70px;
`;

const hiddenSideBar = css`
  overflow-x: hidden;
  transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;

const openSideBar = css`
  transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;

export const Drawer = styled(BaseDrawer)<{ open: boolean }>`
  & .MuiDrawer-paperAnchorLeft {
    height: 100%;
    white-space: nowrap;
    flex-shrink: 0;
    z-index: 1;
    ${(props) => (props.open ? openSideBar : hiddenSideBar)}
  }
`;
