import { Column } from './types';

export const columns: readonly Column[] = [
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'inviteCode', label: 'Код', minWidth: 100 },
  { id: 'link', label: 'Ссылка', minWidth: 100 },
  {
    id: 'toggleIsActive',
    label: 'Активировать/Деактивировать',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'resend',
    label: 'Отправить заново',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'delete',
    label: 'Удалить',
    minWidth: 170,
    align: 'right',
  },
];
