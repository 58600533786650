import { FetchResult, gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { ISourceItem } from '@/types/sourceItem.interface';

import { SOURCE_DTO_FRAGMENT } from '../fragments';

export interface IRemoveProductsFromSourceResponseData {
  removeProductsFromSource: ISourceItem[];
}

export interface IRemoveProductsFromSourceVariables {
  id: number;
}

interface IUseRemoveProductsFromSource
  extends MutationResult<IRemoveProductsFromSourceResponseData> {
  removeProductsFromSource: (
    id: number,
  ) => Promise<FetchResult<IRemoveProductsFromSourceResponseData>>;
}

export const REMOVE_PRODUCTS_FROM_SOURCE = gql`
  ${SOURCE_DTO_FRAGMENT}
  mutation removeProductsFromSource($id: Int!) {
    removeProductsFromSource(id: $id) {
      ...SourceDTOFragment
    }
  }
`;

export const useRemoveProductsFromSource = (): IUseRemoveProductsFromSource => {
  const [mutate, otherOptions] = useMutation<
    IRemoveProductsFromSourceResponseData,
    IRemoveProductsFromSourceVariables
  >(REMOVE_PRODUCTS_FROM_SOURCE);

  const removeProductsFromSource = useCallback(
    (id: number): Promise<FetchResult<IRemoveProductsFromSourceResponseData>> =>
      mutate({ variables: { id } }),
    [mutate],
  );

  return { removeProductsFromSource, ...otherOptions };
};
