import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '@/components/atoms/Loader';
import { BannerForm } from '@/components/molecules/BannerForm';
import { useBanner } from '@/lib/graphql/queries/useBanner';

export const EditBanner: FC = () => {
  const { id } = useParams();
  const { banner, loading } = useBanner(id);

  if (loading) return <Loader />;
  if (banner) return <BannerForm initialValues={banner} />;
  return null;
};
