import { Paper, Stack } from '@mui/material';
import { ReactElement } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { LoginButton } from '@/components/atoms/buttons/LoginButton';
import { PasswordField } from '@/components/atoms/PasswordField/input';
import { TextFormField } from '@/components/atoms/TextFormField/input';
import { composeValidators, isEmail, required } from '@/constants/validate';
import { useSignIn } from '@/lib/graphql/mutations/useSignIn';
import {
  setLocalStorageAccessToken,
  setLocalStorageRefreshToken,
} from '@/lib/utils/session';

import { ContainerStack } from './styles';

interface ILoginForm {
  email: string;
  password: string;
}

export const LoginForm = (): ReactElement => {
  const navigate = useNavigate();
  const { signIn } = useSignIn();

  const defaultValues = {
    email: '',
    password: '',
  };

  const handleSubmit = async (values: ILoginForm): Promise<void> => {
    const { email, password } = values;
    const session = await signIn(email, password);
    if (session) {
      setLocalStorageAccessToken(session.accessToken);
      setLocalStorageRefreshToken(session.refreshToken);
      navigate('/');
    }
  };

  return (
    <Stack gap={2} m="auto" p={3} width="auto">
      <Paper elevation={3}>
        <ContainerStack gap={2} p={3}>
          <Form initialValues={defaultValues} onSubmit={handleSubmit}>
            {({ handleSubmit, hasValidationErrors }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextFormField
                    label="Email"
                    name="email"
                    validate={composeValidators(required, isEmail)}
                  />
                  <PasswordField
                    label="Password"
                    name="password"
                    validate={required}
                  />
                  <LoginButton disabled={hasValidationErrors} type="submit" />
                </Stack>
              </form>
            )}
          </Form>
        </ContainerStack>
      </Paper>
    </Stack>
  );
};
