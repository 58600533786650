import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  ICharacteristicItem,
  ICreateCharacteristicInput,
} from '@/types/characteristic.interface';

export interface IUpdateCharacteristicResponseData {
  updateCharacteristic: ICharacteristicItem;
}

interface IUpdateCharacteristicVariables {
  id?: string;
  name: string;
  measuresIds: string[];
}

export interface IUpdateCharacteristicInput extends ICreateCharacteristicInput {
  id?: string;
}

interface IUpdateCharacteristic {
  updateCharacteristic: (
    characteristic: IUpdateCharacteristicInput,
  ) => Promise<FetchResult<IUpdateCharacteristicResponseData>>;
  loading: boolean;
  error?: ApolloError;
}

export const UPDATE_CHARACTERISTIC = gql`
  mutation updateCharacteristic(
    $id: String!
    $name: String!
    $measuresIds: [String!]!
  ) {
    updateCharacteristic(
      options: { id: $id, name: $name, measuresIds: $measuresIds }
    ) {
      id
      name
      measures {
        id
        name
      }
    }
  }
`;

export const useUpdateCharacteristic = (): IUpdateCharacteristic => {
  const [mutate, { loading, error }] = useMutation<
    IUpdateCharacteristicResponseData,
    IUpdateCharacteristicVariables
  >(UPDATE_CHARACTERISTIC);

  const updateCharacteristic = useCallback(
    (
      characteristic: IUpdateCharacteristicInput,
    ): Promise<FetchResult<IUpdateCharacteristicResponseData>> =>
      mutate({
        variables: characteristic,
      }),
    [mutate],
  );

  return { updateCharacteristic, loading, error };
};
