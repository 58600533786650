import { isEmpty, isNil } from 'lodash';
import { FC } from 'react';

import { ICatalog } from '@/types/catalog.interface';

import useToggle from '../../hooks/hooks';

import { CatalogDownloadButton } from './components/CatalogDownloadButton';
import { CatalogsModal } from './components/CatalogsModal';
import { Text } from './styles';

export const CatalogsDownload: FC<{ catalogs?: ICatalog[] }> = ({
  catalogs,
}) => {
  const [isCatalogsModalShow, toggleCatalogsModalShow] = useToggle();

  const handleCatalogDownload = (fileUrl?: string): void => {
    if (fileUrl != null && fileUrl !== '') open(fileUrl);
  };

  const hasCatalogs = !isNil(catalogs) && !isEmpty(catalogs);

  if (!hasCatalogs) return <Text>Catalogs are not loaded</Text>;

  return (
    <>
      <CatalogDownloadButton
        catalogs={catalogs}
        onCatalogDownloadClick={handleCatalogDownload}
        onToggleCatalogsModalShow={toggleCatalogsModalShow}
      />

      <CatalogsModal
        catalogs={catalogs}
        isOpen={isCatalogsModalShow}
        onClose={toggleCatalogsModalShow}
        onFileDownload={handleCatalogDownload}
      />
    </>
  );
};
