import styled from '@emotion/styled';

import { Colors } from '@/variables/colors';

export const Select = styled.select`
  padding: 10px;
  border-radius: 4px;
  background-color: ${Colors.WildSand};
  border-color: ${Colors.Divider};
  font-size: 16px;
`;

export const Container = styled.div`
  width: 100%;
`;
