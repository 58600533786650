import React from 'react';

import { IFeedbackItem } from '@/types/feedback.interface';
import { formattedDate } from '@/utils/date';

import { Message } from './components/Message';
import { ClientInformation, Contacts, Container, Text } from './styles';

export const Information: React.FC<{ feedback: IFeedbackItem }> = ({
  feedback,
}) => {
  const { text, name, email, createdAt } = feedback;

  return (
    <Container>
      <Message text={text} />
      <ClientInformation>
        <Contacts>
          <Text>{name}</Text>
          <Text>{email}</Text>
        </Contacts>
        <Text>{formattedDate(createdAt)}</Text>
      </ClientInformation>
    </Container>
  );
};
