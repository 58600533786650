export function setLocalStorageAccessToken(token: string): void {
  localStorage.setItem('access-token', token);
}

export function getLocalStorageAccessToken(): string | null {
  return localStorage.getItem('access-token');
}

export function setLocalStorageRefreshToken(token: string): void {
  localStorage.setItem('refresh-token', token);
}

export function getLocalStorageRefreshToken(): string | null {
  return localStorage.getItem('refresh-token');
}

export function clearLocalStorageSession(): void {
  localStorage.removeItem('access-token');
  localStorage.removeItem('refresh-token');
}
