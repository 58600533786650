import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { CATEGORY_DTO_FRAGMENT } from '../../fragments';

export interface ICategoryItem {
  id: string;
  name: string;
  keywords?: string;
  childCategories?: ICategoryItem[];
  parentCategory?: ICategoryItem | null;
  parentCategoryId?: string | null;
}
export interface ICategory {
  categories: ICategoryItem[];
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export interface ICategoryResponse {
  getCategories: ICategoryItem[];
}

export const CATEGORIES = gql`
  ${CATEGORY_DTO_FRAGMENT}
  query getCategories {
    getCategories {
      ...CategoryDTOFragment
    }
  }
`;
export const useCategories = (): ICategory => {
  const { data, loading, refetch } = useQuery<ICategoryResponse>(CATEGORIES, {
    fetchPolicy: 'cache-and-network',
  });

  const categories = data?.getCategories ?? [];

  return { categories, loading, refetch };
};
