import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { IRequestItem } from '@/types/requests.interface';

import { USER_REQUESTS_DTO_FRAGMENT } from '../fragments';

interface IGetAllRequests {
  requests?: IRequestItem[];
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
  error?: ApolloError;
}

interface IGetAllResponse {
  getAllRequests?: IRequestItem[];
}

export const GET_ALL_REQUESTS = gql`
  ${USER_REQUESTS_DTO_FRAGMENT}

  query getAllRequests {
    getAllRequests {
      ...UserRequestDTOFragment
    }
  }
`;

export const useGetAllRequests = (): IGetAllRequests => {
  const { data, loading, refetch, error } =
    useQuery<IGetAllResponse>(GET_ALL_REQUESTS);

  const requests = data?.getAllRequests;

  return { requests, loading, refetch, error };
};
