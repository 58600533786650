import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '@/components/atoms/Loader';

import { MeasureForm } from '../components/MeasureForm';

import { useMeasure } from './hooks/use-measure';
import { useUpdateMeasure } from './hooks/use-update-measure';
import { Container } from './styles';

export const UpdateMeasurePage: FC = () => {
  const { id = '' } = useParams();

  const { measure, loading } = useMeasure(id);
  const { updateMeasure } = useUpdateMeasure();

  if (loading) return <Loader />;
  if (!measure) return null;

  return (
    <Container>
      <MeasureForm measure={measure} onSubmit={updateMeasure} />
    </Container>
  );
};
