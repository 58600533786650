import { Stack } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { Loader } from '@/components/atoms/Loader';

import { useAccess } from '../hooks/useAccess';

export const AdminGuard = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { isAdmin, loading } = useAccess();

  if (!loading && !isAdmin) {
    return <Navigate to={'/login'} />;
  }

  if (loading)
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ width: '100%', height: '100%' }}
      >
        <Loader />
      </Stack>
    );

  return children;
};
