import { either } from 'fp-ts';
import * as t from 'io-ts';
import { omit } from 'lodash';
import qs from 'qs';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type UseQueryParamsResult<T> = [
  params: Partial<T>,
  setParams: (params: T) => void,
];

function decode<T>(result: t.Validation<T>, fallback: T): T {
  return either.getOrElse(() => fallback)(result);
}

interface Options<T extends t.Mixed> {
  defaultValue?: t.TypeOf<T>;
  drop?: string[];
}

export default function useQueryState<T extends t.Mixed>(
  key: string,
  codec: T,
  options: Options<T>,
): UseQueryParamsResult<t.TypeOf<T>> {
  const location = useLocation();
  const navigate = useNavigate();

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const value = decode<t.TypeOf<T>>(
    codec.decode(params[key]),
    options.defaultValue,
  );

  const setParams = useCallback(
    (newParams: T) => {
      const search = qs.stringify(
        { ...omit(params, options.drop ?? []), [key]: newParams },
        { encode: true },
      );
      navigate(`${location.pathname}?${search}`);
    },
    [params, options.drop, key, navigate, location.pathname],
  );

  return [value, setParams];
}
