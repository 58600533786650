import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface IStopScrapingAllSources {
  stopScrapingAllSources: () => Promise<FetchResult<string>>;
}

export const STOP_SCRAPING_ALL_SOURCES = gql`
  mutation stopScrapingAllSources {
    stopScrapingAllSources
  }
`;

export const useStopScrapingAllSources = (): IStopScrapingAllSources => {
  const [mutate] = useMutation(STOP_SCRAPING_ALL_SOURCES);

  const stopScrapingAllSources = useCallback(
    (): Promise<FetchResult<string>> => mutate(),
    [mutate],
  );

  return { stopScrapingAllSources };
};
