import { TextField } from '@mui/material';
import { FieldValidator } from 'final-form';
import { FC } from 'react';
import { Field } from 'react-final-form';

interface ITextFieldProps<T> {
  name?: string;
  label?: string;
  multiline?: boolean;
  validate?: FieldValidator<T>;
}

export const TextFormField: FC<ITextFieldProps<string>> = ({
  name = '',
  label = '',
  multiline,
  validate,
}) => {
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <TextField
          {...input}
          fullWidth
          error={meta.touched ? Boolean(meta.error) : undefined}
          helperText={meta.touched ? Boolean(meta.error) : null}
          label={label}
          multiline={multiline}
        />
      )}
      validate={validate}
    />
  );
};
