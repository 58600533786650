import styled from '@emotion/styled';

import { Button as ButtonBase } from './components/Button';

export const Actions = styled.div`
  display: flex;
`;

export const Button = styled(ButtonBase)`
  height: 40px;
`;

export const Feedback = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 0 20px 20px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 1px rgba(34, 60, 80, 0.2);
`;
