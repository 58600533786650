import { ProductVisibleSortOrderField } from '@/lib/graphql/types/product-fields.enum';

import { Option } from './types';

export const productVisibleSortOptions: Option<ProductVisibleSortOrderField>[] =
  [
    {
      label: 'Disbled',
      value: ProductVisibleSortOrderField.hidden,
    },
    {
      label: 'Active',
      value: ProductVisibleSortOrderField.active,
    },
    {
      label: 'All',
      value: ProductVisibleSortOrderField.all,
    },
  ];
