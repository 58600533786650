import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { ReactElement } from 'react';

interface IEditButton {
  id: string;
  onClick: (id: string) => void;
}

export const EditButton = ({ id, onClick }: IEditButton): ReactElement => {
  const handleChange = (): void => onClick(id);

  return (
    <IconButton onClick={handleChange}>
      <EditIcon />
    </IconButton>
  );
};
