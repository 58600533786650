import { FC } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useUpdateProduct } from '@/lib/graphql/mutations/useUpdateProduct';
import { IProductItem } from '@/types/productItem.interface';

import { ProductCardAppearance } from './components/ProductCardAppearance';
import { ProductCardStatus } from './components/ProductCardStatus';
import { ProductCategory } from './components/ProductCategory';
import { Button, Container } from './styles';
import { ISaveProductForm } from './types';

interface IProduct {
  product: IProductItem;
}

export const ProductEditPage: FC<IProduct> = ({ product }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const categoryId = product?.categoryChain?.[0]?.id ?? null;
  const subcategoryId = product?.categoryChain?.[1]?.id ?? null;
  const subchildCategoryId = product?.categoryChain?.[2]?.id ?? null;

  const { updateProduct } = useUpdateProduct();

  const handleFormSubmit = async (values: ISaveProductForm): Promise<void> => {
    await updateProduct({
      id: values.id,
      categoryId:
        values.subchildCategoryId ?? values.subcategoryId ?? values.categoryId,
      moderationStatus: {
        name: values.moderationStatus?.name,
        description: values.moderationStatus?.description,
        productId: values.id,
      },
    });
  };
  const handleClose = (): void => navigate(`/products`);

  return (
    <Container>
      <Form
        initialValues={{
          id,
          categoryId,
          subcategoryId,
          subchildCategoryId,
          moderationStatus: {
            name: product?.moderationStatus?.name,
            description: product?.moderationStatus?.description,
          },
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ProductCategory />
            <ProductCardAppearance product={product} />
            <ProductCardStatus />
            <Button type="submit" variant="contained">
              Save
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </form>
        )}
        onSubmit={handleFormSubmit}
      />
    </Container>
  );
};
