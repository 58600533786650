import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IBannerItem } from '@/types/banner.interface';

import { BANNER_DTO_FRAGMENT } from '../fragments';

interface ICreateBanner {
  createBanner: (banner: IBannerItem) => Promise<FetchResult<IBannerItem>>;
}

export const CREATE_BANNER = gql`
  ${BANNER_DTO_FRAGMENT}
  mutation createBanner(
    $isActive: Boolean!
    $name: String!
    $goToUrl: String!
    $title: String!
    $text: String
    $textColor: String
    $titleColor: String
    $backgroundColor: String
    $backgroundImageUrl: String!
    $locale: String!
  ) {
    createBanner(
      createBannerInput: {
        isActive: $isActive
        name: $name
        goToUrl: $goToUrl
        title: $title
        text: $text
        titleColor: $titleColor
        textColor: $textColor
        backgroundColor: $backgroundColor
        backgroundImageUrl: $backgroundImageUrl
        locale: $locale
      }
    ) {
      ...BannerDTOFragment
    }
  }
`;

export const useCreateBanner = (): ICreateBanner => {
  const [mutate] = useMutation(CREATE_BANNER);

  const createBanner = useCallback(
    (banner: IBannerItem): Promise<FetchResult<IBannerItem>> =>
      mutate({
        variables: banner,
      }),
    [mutate],
  );

  return { createBanner };
};
