import { useContext } from 'react';

import { ToastContextType } from './interfaces';
import { ToastContext } from './сontext';

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (context == null) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
