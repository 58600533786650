import { FC } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner';
import { CancelButton } from '@/components/atoms/buttons/CancelButton';
import { SaveButton } from '@/components/atoms/buttons/SaveButton';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useCreateBanner } from '@/lib/graphql/mutations/useCreateBanner';
import { useUpdateBanner } from '@/lib/graphql/mutations/useUpdateBanner';
import { useBanners } from '@/lib/graphql/queries/useBanners';
import { IBannerItem } from '@/types/banner.interface';
import { Colors } from '@/variables/colors';

import { ActiveSwitcher } from './components/ActiveSwitcher';
import { BannerFormFields } from './components/BannerFormFields';
import { bannerSx, ButtonStack, HeaderStack, Title } from './styles';

interface IButtonForm {
  initialValues?: IBannerItem;
}

const defaultValues = {
  id: '',
  name: 'Name',
  title: 'Title',
  text: 'Text',
  backgroundColor: Colors.Alabaster,
  isActive: false,
  titleColor: Colors.CuttySark,
  textColor: Colors.Emperor,
  locale: '',
};

export const BannerForm: FC<IButtonForm> = ({ initialValues }) => {
  const { updateBanner } = useUpdateBanner();
  const { createBanner } = useCreateBanner();
  const { refetch } = useBanners();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleSave = async (formData: IBannerItem): Promise<void> => {
    const perform = initialValues ? updateBanner : createBanner;
    await perform(formData);
    showToast('Баннер сохранен');
    await refetch();
    navigate('/banners');
  };

  const handleCancel = (): void => navigate('/banners');

  return (
    <Form<IBannerItem>
      initialValues={initialValues ?? defaultValues}
      onSubmit={handleSave}
    >
      {({ handleSubmit, values, form, hasValidationErrors }) => (
        <form onSubmit={handleSubmit}>
          <HeaderStack>
            <Title variant="h3">Name:{values.name}</Title>
            <ActiveSwitcher
              value={values.isActive}
              onChange={(value) => form.change('isActive', value)}
            />
          </HeaderStack>
          <Banner banner={values} sx={bannerSx} />
          <BannerFormFields />
          <ButtonStack>
            <SaveButton disabled={hasValidationErrors} type={'submit'} />
            <CancelButton type={'button'} onClick={handleCancel} />
          </ButtonStack>
        </form>
      )}
    </Form>
  );
};
