import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { CATEGORY_DTO_FRAGMENT } from '../../fragments';
import { ICategoryItem } from '../../queries/categories/useCategories';

type IPickCategoryItemProps = 'name' | 'keywords' | 'parentCategoryId';

export interface ICreateCategoryItem
  extends Pick<ICategoryItem, IPickCategoryItemProps> {}

interface ICreateCategory {
  createCategory: (
    category: ICreateCategoryItem,
  ) => Promise<FetchResult<ICategoryItem>>;
}

export const CREATE_CATEGORY = gql`
  ${CATEGORY_DTO_FRAGMENT}
  mutation createCategory($createCategoryInput: CreateCategoryInput!) {
    createCategory(createCategoryInput: $createCategoryInput) {
      ...CategoryDTOFragment
    }
  }
`;

export const useCreateCategory = (): ICreateCategory => {
  const [mutate] = useMutation(CREATE_CATEGORY);

  const createCategory = useCallback(
    (category: ICreateCategoryItem): Promise<FetchResult<ICategoryItem>> =>
      mutate({
        variables: {
          createCategoryInput: {
            name: category.name,
            keywords: category.keywords,
            parentCategoryId: category.parentCategoryId,
          },
        },
      }),
    [mutate],
  );

  return { createCategory };
};
