import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { ReactElement } from 'react';

import { Container } from './styles';

interface ActivityStatusProps {
  isActive: boolean;
}

export const ActivityStatus = ({
  isActive,
}: ActivityStatusProps): ReactElement => {
  return (
    <Container>
      <Chip
        color={isActive ? 'success' : 'error'}
        icon={
          isActive ? <CheckIcon color="success" /> : <CloseIcon color="error" />
        }
        label={isActive ? 'Активна' : 'Не активна'}
        sx={{ pl: 1 }}
        variant="outlined"
      />
    </Container>
  );
};
