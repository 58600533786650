import styled from '@emotion/styled';
import {
  Button as BaseButton,
  FormControl as BaseFormControl,
} from '@mui/material';

export const FormControl = styled(BaseFormControl)`
  min-width: 120px;
  margin: 0 0 15px;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 50px 0 0;
`;

export const Button = styled(BaseButton)`
  margin: 15px 0 0;
`;
