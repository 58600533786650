import { FetchResult } from '@apollo/client';
import { Paper } from '@mui/material';
import { FC } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { CancelButton } from '@/components/atoms/buttons/CancelButton';
import { SaveButton } from '@/components/atoms/buttons/SaveButton';
import { TextFormField } from '@/components/atoms/TextFormField/input';
import { useToast } from '@/components/atoms/Toast/hooks';
import { required } from '@/constants/validate';
import { IMeasureItem } from '@/types/measure.interface';

import { ICreateMeasureResponseData } from '../../CreateMeasurePage/hooks';
import { IUpdateMeasureResponseData } from '../../UpdateMeasurePage/hooks/use-update-measure';

import { Actions, Form } from './styles';

interface MeasureFormProps {
  measure: IMeasureItem;
  onSubmit: (
    measure: IMeasureItem,
  ) => Promise<
    FetchResult<ICreateMeasureResponseData | IUpdateMeasureResponseData>
  >;
}

export const MeasureForm: FC<MeasureFormProps> = ({ measure, onSubmit }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleSave = async (formData: IMeasureItem): Promise<void> => {
    try {
      await onSubmit(formData);
      showToast('Величина сохранена');
      navigate('/measures');
    } catch (e) {
      showToast('Ошибка во время сохранения величины', 'error');
    }
  };

  const handleCancel = (): void => navigate('/measures');

  return (
    <Paper>
      <FinalForm<IMeasureItem> initialValues={measure} onSubmit={handleSave}>
        {({ handleSubmit, hasValidationErrors }) => (
          <Form onSubmit={handleSubmit}>
            <TextFormField label="Название" name="name" validate={required} />
            <Actions>
              <SaveButton disabled={hasValidationErrors} type="submit" />
              <CancelButton type="button" onClick={handleCancel} />
            </Actions>
          </Form>
        )}
      </FinalForm>
    </Paper>
  );
};
