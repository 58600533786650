import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';

import { ICharacteristicItem } from '@/types/characteristic.interface';

import { columns } from './columns';
import { CharacteristicTableRow } from './components/CharacteristicRow';
import { useCharacteristics } from './hooks';
import { TableCell, TableContainer, TablePaper } from './styles';

export const CharacteristicTable: FC = () => {
  const { characteristics } = useCharacteristics();

  return (
    <TablePaper>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} minWidth={column.minWidth}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {characteristics?.map((row: ICharacteristicItem) => (
              <CharacteristicTableRow key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TablePaper>
  );
};
