import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { MEASURES_QUERY } from '@/lib/graphql/queries/measures/useMeasures';

interface IRemoveMeasureVariables {
  id: string;
}

interface IRemoveMeasure {
  removeMeasure: (id: string) => Promise<FetchResult<boolean>>;
  loading: boolean;
  error?: ApolloError;
}

export const REMOVE_MEASURE = gql`
  mutation removeMeasure($id: String!) {
    removeMeasure(id: $id)
  }
`;

export const useRemoveMeasure = (): IRemoveMeasure => {
  const [mutate, { loading, error }] = useMutation<
    boolean,
    IRemoveMeasureVariables
  >(REMOVE_MEASURE);

  const removeMeasure = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: { id },
        refetchQueries: [MEASURES_QUERY],
      }),
    [mutate],
  );

  return { removeMeasure, loading, error };
};
