import styled from '@emotion/styled';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAll from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';

import { Colors } from '@/variables/colors';

export const Approved = styled(DoneAll)`
  color: ${Colors.KellyGreen};
`;

export const OnModeration = styled(AccessTimeIcon)`
  color: ${Colors.Amber};
`;

export const NeedChanges = styled(EditIcon)`
  color: ${Colors.SunsetOrange};
`;
