import { ListItemText, Switch } from '@mui/material';
import { ReactElement } from 'react';

import { Justify } from './styles';

interface IActiveSwitcher {
  value: boolean;
  onChange: (value: boolean) => void;
}

export const ActiveSwitcher = ({
  value,
  onChange,
}: IActiveSwitcher): ReactElement => {
  const handleChange = (): void => {
    onChange(!value);
  };

  return (
    <Justify>
      <Switch checked={value} name="isActive" onChange={handleChange} />
      <ListItemText primary={`${value ? 'Active' : 'Inactive'}`} />
    </Justify>
  );
};
