import { Button } from '@mui/material';
import { FC } from 'react';

import { ButtonsContainer, ModalContainer, ModalTitle } from './styles';

interface ModalFormProps {
  title: string;
  buttonText: string;
  onClick: () => void;
  onClose: () => void;
}

export const ModalForm: FC<ModalFormProps> = ({
  title,
  buttonText,
  onClick,
  onClose,
}) => {
  return (
    <ModalContainer>
      <ModalTitle>{title}</ModalTitle>
      <ButtonsContainer>
        <Button color="error" variant="contained" onClick={onClick}>
          {buttonText}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Отмена
        </Button>
      </ButtonsContainer>
    </ModalContainer>
  );
};
