import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { FC } from 'react';

import ModalWindow from '@/components/atoms/ModalWindow';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useUpdateProduct } from '@/lib/graphql/mutations/useUpdateProduct';
import { useModal } from '@/lib/hooks/useModal';

import { ButtonsContainer, ModalContainer, ModalTitle } from './styles';

interface HideButtonProps {
  id: string;
  isHidden: boolean;
}

export const HideButton: FC<HideButtonProps> = ({ id, isHidden }) => {
  const [isOpen, toggle] = useModal();

  const { updateProduct } = useUpdateProduct();

  const { showToast } = useToast();

  const handleUpdateClick = async (): Promise<void> => {
    await updateProduct({ id, isHidden: !isHidden });

    isHidden ? showToast(`Продукт показывается`) : showToast(`Продукт скрыт`);

    toggle();
  };

  const handleToggle = (): void => toggle();

  const title = isHidden
    ? 'Вы действительно хотите ПОКАЗЫВАТЬ продукт?'
    : 'Вы действительно хотите СКРЫТЬ продукт?';

  return (
    <>
      <IconButton onClick={handleToggle}>
        {isHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
      <ModalWindow isOpen={isOpen} onClose={handleToggle}>
        <ModalContainer>
          <ModalTitle>{title}</ModalTitle>
          <ButtonsContainer>
            <Button
              color="error"
              variant="contained"
              onClick={handleUpdateClick}
            >
              {isHidden ? 'Показывать' : 'Скрыть'}
            </Button>
            <Button variant="outlined" onClick={handleToggle}>
              Отмена
            </Button>
          </ButtonsContainer>
        </ModalContainer>
      </ModalWindow>
    </>
  );
};
