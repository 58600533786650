import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { INVITES_DTO_FRAGMENT } from '../../fragments';

export interface IInvite {
  id: string;
  email: string;
  inviteCode: string;
  isActive: boolean;
}

export interface IInvitesResponse {
  invites: IInvite[];
  total: number;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const INVITES = gql`
  ${INVITES_DTO_FRAGMENT}
  query invites {
    invites {
      ...InviteDTOFragment
    }
  }
`;

export const useInvites = (): IInvitesResponse => {
  const { data, loading, refetch } = useQuery<IInvitesResponse>(INVITES, {
    fetchPolicy: 'cache-and-network',
  });

  const invites = data?.invites ?? [];

  const total = data?.total ?? 0;

  return { invites, total, loading, refetch };
};
