import styled from '@emotion/styled';
import { Paper as BasePaper } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  height: 95%;
  width: 80%;
`;

export const Paper = styled(BasePaper)`
  &.MuiPaper-root {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 0 32px;
    padding: 20px 0 10px 0;
  }
`;
