import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useFormState } from 'react-final-form';

import { ISaveProductForm } from '@/components/pages/ProductEditPage/types';
import { ICategoryItem } from '@/lib/graphql/queries/categories/useCategories';

import { Select } from './styles';

interface Props {
  name: 'categoryId' | 'subcategoryId' | 'subchildCategoryId';
  categories: ICategoryItem[];
  label: string;
  onChange: (event: SelectChangeEvent<Maybe<string>>) => void;
}

export const CategorySelect: React.FC<Props> = ({
  name,
  categories,
  onChange,
  label,
}) => {
  const { values } = useFormState<ISaveProductForm>();

  const selectedValue = values?.[name];
  return (
    <FormControl>
      <InputLabel>Product {label}</InputLabel>
      <Select
        label={`Product ${label}`}
        value={String(selectedValue)}
        onChange={onChange}
      >
        {categories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
