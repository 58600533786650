import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { TEST_RESULT_DTO_FRAGMENT } from '../../fragments';

export interface ITestedSource {
  id: string;
  areAllTestsPassed: boolean;
  items: ITestResultItem[];
  source: {
    name: string;
  };
}

export interface ITestResultResponseItem {
  id: string;
  createdAt: Date;
  testedSources: ITestedSource[];
}

export interface ITestResultItem {
  id: string;
  name: string;
  result: boolean;
  productName: string;
}

export interface ITestResults {
  testResults: ITestResultResponseItem[];
  total: number;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export interface ITestResultsResponse {
  scrapeTestResults: ITestResultResponseItem[];
  total: number;
}

export const TEST_RESULTS = gql`
  ${TEST_RESULT_DTO_FRAGMENT}
  query scrapeTestResults {
    scrapeTestResults {
      ...TestResultDTOFragment
    }
  }
`;

export const useTestResults = (): ITestResults => {
  const { data, loading, refetch } = useQuery<ITestResultsResponse>(
    TEST_RESULTS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const testResults = data?.scrapeTestResults ?? [];

  const total = data?.total ?? 0;

  return { testResults, total, loading, refetch };
};
