import {
  Category as CategoryIcon,
  ChevronLeft as ChevronLeftIcon,
  Feedback as FeedbackIcon,
  Person as PersonIcon,
  Source as SourceIcon,
  ViewCarousel as ViewCarouselIcon,
} from '@mui/icons-material';
import { Divider, IconButton, Stack } from '@mui/material';
import { ReactElement } from 'react';

import { ListItem } from './components/ListItem';
import { Drawer, IconContainer } from './styles';

interface SideBarProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SideBar = (props: SideBarProps): ReactElement => {
  const { isOpen, onClose } = props;
  return (
    <Drawer anchor="left" open={isOpen} variant="persistent">
      <Stack width="300px">
        <IconContainer>
          <IconButton onClick={onClose}>
            <ChevronLeftIcon />
          </IconButton>
        </IconContainer>
        <Divider />
        <ListItem label="Products" path="/products" />
        <ListItem icon={<SourceIcon />} label="Sources" path="/sources" />
        <ListItem icon={<ViewCarouselIcon />} label="Banners" path="/banners" />
        <ListItem icon={<FeedbackIcon />} label="Feedbacks" path="/feedbacks" />
        <ListItem
          icon={<CategoryIcon />}
          label="Categories"
          path="/categories"
        />
        <ListItem
          icon={<FeedbackIcon />}
          label="User requests"
          path="user_requests"
        />
        <Divider />
        <ListItem
          icon={<PersonIcon />}
          label="Organizations"
          path="organizations"
        />
        <Divider />
        <ListItem
          icon={<FeedbackIcon />}
          label="Scraper tests"
          path="/test-results"
        />
        <ListItem icon={<FeedbackIcon />} label="Invites" path="/invites" />
        <Divider />
        <ListItem label="Characteristics" path="/characteristics" />
        <ListItem label="Measures" path="/measures" />
        <Divider />
      </Stack>
    </Drawer>
  );
};
