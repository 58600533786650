import { Select } from 'mui-rff';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

type SelectInputProps = FieldRenderProps<string, HTMLElement>;

export const SelectInput: React.FC<SelectInputProps> = ({
  input,
  ...rest
}: SelectInputProps) => <Select {...input} {...rest} />;
