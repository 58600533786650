import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface IUseRemoveBanner {
  removeBanner: (id: string) => Promise<FetchResult<boolean>>;
}

export const REMOVE_BANNER = gql`
  mutation removeBanner($id: String!) {
    removeBanner(id: $id)
  }
`;

export const useRemoveBanner = (): IUseRemoveBanner => {
  const [mutate] = useMutation<boolean>(REMOVE_BANNER);

  const removeBanner = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: {
          id,
        },
      }),
    [mutate],
  );

  return { removeBanner };
};
