import { TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { IMeasureItem } from '@/types/measure.interface';

import { useRemoveMeasure } from './hooks';
import { CreateIcon, DeleteIcon } from './styles';

interface MeasureTableRowProps {
  row: IMeasureItem;
}

export const MeasureTableRow: FC<MeasureTableRowProps> = ({
  row: { id, name },
}) => {
  const navigate = useNavigate();
  const { removeMeasure } = useRemoveMeasure();

  const handleDelete = (): void => {
    void removeMeasure(id);
  };

  const handleUpdate = (): void => {
    navigate(`/measures/${id}/edit`);
  };

  return (
    <TableRow key={id} hover role="checkbox">
      <TableCell>{name}</TableCell>
      <TableCell onClick={handleUpdate}>
        <CreateIcon />
      </TableCell>
      <TableCell onClick={handleDelete}>
        <DeleteIcon />
      </TableCell>
    </TableRow>
  );
};
