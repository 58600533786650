import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const ButtonContent = styled(Typography)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
