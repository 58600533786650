import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { CHARACTERISTIC_QUERY } from '@/components/pages/scenes/Characteristics/UpdateCharacteristicPage/hooks/use-characteristic';

interface IRemoveCharacteristicVariables {
  id: string;
}

interface IRemoveCharacteristic {
  removeCharacteristic: (id: string) => Promise<FetchResult<boolean>>;
  loading: boolean;
  error?: ApolloError;
}

export const REMOVE_CHARACTERISTIC = gql`
  mutation removeCharacteristic($id: String!) {
    removeCharacteristic(id: $id)
  }
`;

export const useRemoveCharacteristic = (): IRemoveCharacteristic => {
  const [mutate, { loading, error }] = useMutation<
    boolean,
    IRemoveCharacteristicVariables
  >(REMOVE_CHARACTERISTIC);

  const removeCharacteristic = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: { id },
        refetchQueries: [CHARACTERISTIC_QUERY],
      }),
    [mutate],
  );

  return { removeCharacteristic, loading, error };
};
