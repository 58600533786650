import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { GET_REQUEST_BY_ID } from '../queries/useGetRequestById';

interface IUseRemoveAttachmentFile {
  removeAttachmentFile: (id: string) => Promise<FetchResult<boolean>>;
}
export const REMOVE_ATTACHMENT_FILE = gql`
  mutation removeAttachmentFile($id: String!) {
    removeAttachmentFile(id: $id)
  }
`;

export const useRemoveAttachmentFile = (): IUseRemoveAttachmentFile => {
  const [mutate] = useMutation<boolean>(REMOVE_ATTACHMENT_FILE, {
    refetchQueries: [GET_REQUEST_BY_ID],
  });

  const removeAttachmentFile = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: {
          id,
        },
      }),
    [mutate],
  );

  return { removeAttachmentFile };
};
