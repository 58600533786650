import { PlayCircle, StopCircle } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MouseEvent, ReactElement, useState } from 'react';

import { useToast } from '@/components/atoms/Toast/hooks';
import { useStartScraping } from '@/lib/graphql/mutations/useStartScraping';
import { useStopScraping } from '@/lib/graphql/mutations/useStopScraping';
import { ScrapeMode } from '@/types/scrapeMode.enum';
import { ScrapingStatus } from '@/types/sourceItem.interface';

interface ScrapingActionsProps {
  sourceId: number;
  name: string | undefined;
  status: ScrapingStatus;
  refetchSources: () => void;
}

export default function ScrapingActions(
  props: ScrapingActionsProps,
): ReactElement {
  const { sourceId, name, status, refetchSources } = props;
  const { showToast } = useToast();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { startScraping } = useStartScraping();
  const { stopScraping, loading } = useStopScraping();

  const handleClose = (): void => setAnchorEl(null);

  const handleStartButtonClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleStopButtonClick = async (
    e: MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.stopPropagation();
    await stopScraping(sourceId);
    refetchSources();
    showToast(`Скрапинг источника ${name} остановлен`);
  };

  const handleProdButtonClick = async (
    e: MouseEvent<HTMLLIElement>,
  ): Promise<void> => {
    e.stopPropagation();
    await startScraping({ sourceId, mode: ScrapeMode.Prod });
    refetchSources();
    setAnchorEl(null);
    showToast(`Скрапинг источника ${name} включен в prod режиме`);
  };

  const handleDevButtonClick = async (
    e: MouseEvent<HTMLLIElement>,
  ): Promise<void> => {
    e.stopPropagation();
    await startScraping({ sourceId, mode: ScrapeMode.Dev });
    refetchSources();
    setAnchorEl(null);
    showToast(`Скрапинг источника ${name} включен в dev режиме`);
  };

  return (
    <>
      {status === ScrapingStatus.InProgress ? (
        <IconButton disabled={loading} onClick={handleStopButtonClick}>
          <StopCircle />
        </IconButton>
      ) : (
        <IconButton onClick={handleStartButtonClick}>
          <PlayCircle />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={handleProdButtonClick}>Prod</MenuItem>
        <MenuItem onClick={handleDevButtonClick}>Dev</MenuItem>
      </Menu>
    </>
  );
}
