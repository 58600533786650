import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  margin: 0 40px 0 30px;
  flex-direction: column;
  width: 100%;
  word-wrap: break-all;
`;

export const ClientInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0 0;
  justify-content: space-between;
  font-size: 14px;
  border-top: 0.5px solid gray;
`;

export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.span`
  font-family: Roboto;
`;
