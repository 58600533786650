import styled from '@emotion/styled';
import { Paper as BasePaper } from '@mui/material';

export const OrganizationContainer = styled(BasePaper)`
  &.MuiPaper-root {
    width: 100%;
    padding: 10px 20px;
    margin: 0 0 32px;
  }
`;

export const GridTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
