import Button from '@mui/material/Button';
import { FC } from 'react';

interface ICreateButton {
  onClick: () => void;
}
export const CreateButton: FC<ICreateButton> = ({ onClick }) => {
  return (
    <Button variant="contained" onClick={onClick}>
      Create
    </Button>
  );
};
