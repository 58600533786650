import Button from '@mui/material/Button';
import { FC } from 'react';

import { SaveIcon } from './style';
interface ISaveButton {
  onClick?: () => void;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
}
export const SaveButton: FC<ISaveButton> = (props: ISaveButton) => {
  return (
    <Button variant="contained" {...props}>
      Save
      <SaveIcon />
    </Button>
  );
};
