import styled from '@emotion/styled';

export const CommercialContent = styled.div`
  width: 100%;
  height: max-content;
  max-width: 550px;
  padding: 25px 30px;
  margin: 30px;

  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const BrandAndProviderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PriceAndStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
