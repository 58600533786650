import styled from '@emotion/styled';

import ModalWindow from '@/components/atoms/ModalWindow';

export const Modal = styled(ModalWindow)`
  display: flex;
  flex-direction: column;
  min-width: 450px;
  width: auto;
  max-width: 100%;
  height: auto;
`;
