import styled from '@emotion/styled';

import { Colors } from '@/variables/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TextInput = styled.textarea<{ error?: boolean }>`
  width: 100%;
  height: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding: 16px 14px;
  border: ${({ error }) =>
    error ? `2px solid ${Colors.SunsetOrange}` : `2px solid ${Colors.Silver}`};

  &:focus {
    border-color: ${({ error }) =>
      error ? `${Colors.SunsetOrange}` : `${Colors.Black}`};
    outline: none;
  }
`;

export const ErrorText = styled.div`
  font-size: 14px;
  color: ${Colors.SunsetOrange};
`;
