import { FC } from 'react';

import { IProductItem } from '@/types/productItem.interface';

import { BoldText, Text } from '../../styles';

import { CatalogsDownload } from './components/CatalogsDownload';
import { TdModelsDownload } from './components/TdModelsDownload';
import {
  BrandAndProviderWrapper,
  CommercialContent,
  Container,
  Fields,
  PriceAndStatusWrapper,
} from './styles';

export const ProductInfoComponent: FC<{ product?: IProductItem }> = ({
  product,
}) => {
  return (
    <Container>
      <CommercialContent>
        <Fields>
          <Text>
            <BoldText>Name: </BoldText>
            {product?.name}
          </Text>
          <Text>
            <BoldText>Vendor code: </BoldText>
            {product?.vendorCode}
          </Text>
          <BrandAndProviderWrapper>
            <Text>
              <BoldText>Brand: </BoldText>
              {product?.productBrand?.name}
            </Text>
            <Text>
              <BoldText>Provider: </BoldText>
              {product?.productProvider?.name}
            </Text>
          </BrandAndProviderWrapper>
          <PriceAndStatusWrapper>
            <Text>
              <BoldText>Stock status: </BoldText>
              {product?.stockStatus}
            </Text>
            <Text>
              <BoldText>Price: </BoldText>
              {`${product?.price} ₽`}
            </Text>
          </PriceAndStatusWrapper>
          <CatalogsDownload catalogs={product?.catalogs} />
          <TdModelsDownload tdModels={product?.tdModels} />
        </Fields>
      </CommercialContent>
    </Container>
  );
};
