import { Box, styled } from '@mui/material';

import { Colors } from '@/variables/colors';

export const Window = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${Colors.White};
  border: 2px solid ${Colors.Alabaster};
  border-radius: 4px;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 8px;
`;
