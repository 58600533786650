import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IMeasureItem } from '@/types/measure.interface';

export interface ICreateMeasureResponseData {
  createMeasure: IMeasureItem;
}

interface ICreateMeasureVariables {
  name: string;
}

interface ICreateMeasure {
  createMeasure: (
    measure: IMeasureItem,
  ) => Promise<FetchResult<ICreateMeasureResponseData>>;
  loading: boolean;
  error?: ApolloError;
}

export const CREATE_MEASURE = gql`
  mutation createMeasure($name: String!) {
    createMeasure(name: $name) {
      id
      name
    }
  }
`;

export const useCreateMeasure = (): ICreateMeasure => {
  const [mutate, { loading, error }] = useMutation<
    ICreateMeasureResponseData,
    ICreateMeasureVariables
  >(CREATE_MEASURE);

  const createMeasure = useCallback(
    (measure: IMeasureItem): Promise<FetchResult<ICreateMeasureResponseData>> =>
      mutate({
        variables: measure,
      }),
    [mutate],
  );

  return { createMeasure, loading, error };
};
