import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Title = styled(Typography)`
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
