import { FC } from 'react';

import { Container, Select } from './styles';

interface SelectLocaleProps {
  onChangeLocale: (locale: string) => void;
  value: string;
}

export const SelectLocale: FC<SelectLocaleProps> = ({
  onChangeLocale,
  value,
}) => {
  const handleChangeLocale = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    onChangeLocale(event.target.value);
  };

  return (
    <Container>
      <Select
        required
        name="locale"
        value={value}
        onChange={handleChangeLocale}
      >
        <option value="ru">Russian</option>
        <option value="en">English</option>
      </Select>
    </Container>
  );
};
