import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { IMeasureItem } from '@/types/measure.interface';

interface IMeasuresResponseData {
  measures: IMeasureItem[];
}

interface IMeasures {
  measures: IMeasureItem[];
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const MEASURES_QUERY = gql`
  query measures {
    measures {
      id
      name
    }
  }
`;

export const useMeasures = (): IMeasures => {
  const { data, loading, refetch } =
    useQuery<IMeasuresResponseData>(MEASURES_QUERY);

  const measures = data?.measures ?? [];

  return { measures, loading, refetch };
};
