import { Stack } from '@mui/material';
import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { Loader } from '@/components/atoms/Loader';
import { useSource } from '@/lib/graphql/queries/useSource';

import ScrapeHistory from './components/ScrapeHistory';
import { SourceForm } from './components/SourceForm';

export const SourcePage: FC = () => {
  const { id } = useParams();
  const { source, loading } = useSource(Number(id));

  if (loading) return <Loader />;

  if (!source) return <Navigate to="/404" />;

  return (
    <Stack gap={5} width="100%">
      <SourceForm source={source} />

      <ScrapeHistory sourceId={source.id} />
    </Stack>
  );
};
