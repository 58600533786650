import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  IProductItem,
  UpdateProductInput,
} from '@/types/productItem.interface';

import { PRODUCT_DTO_FRAGMENT } from '../fragments';

interface IUpdateProducts {
  updateProducts: (
    updateProductsInput: UpdateProductInput[],
  ) => Promise<FetchResult<IProductItem[]>>;
  loading: boolean;
}

export const UPDATE_PRODUCTS = gql`
  ${PRODUCT_DTO_FRAGMENT}
  mutation updateProducts($updateProductsInput: [UpdateProductInput!]!) {
    updateProducts(updateProductsInput: $updateProductsInput) {
      ...ProductDTOFragment
    }
  }
`;

export const useUpdateProducts = (): IUpdateProducts => {
  const [mutate, { loading }] = useMutation(UPDATE_PRODUCTS);

  const updateProducts = useCallback(
    (
      updateProductsInput: UpdateProductInput[],
    ): Promise<FetchResult<IProductItem[]>> =>
      mutate({
        variables: { updateProductsInput },
      }),
    [mutate],
  );

  return { updateProducts, loading };
};
