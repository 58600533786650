import styled from '@emotion/styled';
import BaseCreateIcon from '@mui/icons-material/Create';
import BaseDeleteIcon from '@mui/icons-material/Delete';

export const CreateIcon = styled(BaseCreateIcon)`
  cursor: pointer;
`;

export const DeleteIcon = styled(BaseDeleteIcon)`
  cursor: pointer;
`;
