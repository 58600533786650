import { Alert, Snackbar } from '@mui/material';
import React from 'react';

import { useToast } from './hooks';

const Toast: React.FC = () => {
  const { toast, hideToast, severity } = useToast();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      open={!!toast}
      onClose={hideToast}
    >
      <Alert severity={severity} sx={{ width: '100%' }} onClose={hideToast}>
        {toast}
      </Alert>
    </Snackbar>
  );
};
export default Toast;
