import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  from,
  fromPromise,
  GraphQLRequest,
  HttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { StatusCodes } from 'http-status-codes';

import { browserNavigator } from '../utils/browser-navigator';
import {
  clearLocalStorageSession,
  getLocalStorageAccessToken,
  getLocalStorageRefreshToken,
  setLocalStorageAccessToken,
} from '../utils/session';

import { REFRESH } from './mutations/refresh';

let isRefreshing = false;
let pendingRequests: Array<(value: void | Promise<void>) => void> = [];

const isRefreshRequest = (operation: GraphQLRequest): boolean =>
  operation.operationName === 'refresh';

const resolvePendingRequests = (): void => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const getNewAccessToken = async (): Promise<string | null> => {
  try {
    const result: FetchResult<{ refresh: string }> = await apolloClient.mutate({
      mutation: REFRESH,
    });
    const accessToken = result.data?.refresh ?? null;
    return accessToken;
  } catch {
    return null;
  }
};

const refreshObservable = (): Observable<void> => {
  isRefreshing = true;
  return fromPromise(
    getNewAccessToken()
      .then((accessToken) => {
        if (accessToken) {
          setLocalStorageAccessToken(accessToken);
        } else {
          browserNavigator.navigate('/login');
          clearLocalStorageSession();
        }
      })
      .finally(() => {
        resolvePendingRequests();
        isRefreshing = false;
      }),
  );
};

const pendingObservable = (): Observable<void> =>
  fromPromise(
    new Promise<void>((resolve) => {
      pendingRequests.push(() => resolve());
    }),
  );

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (isRefreshRequest(operation)) return;

  if (graphQLErrors == null) return;

  for (const error of graphQLErrors) {
    const response = error.extensions.response as { statusCode?: StatusCodes };
    const statusCode = response?.statusCode;
    const refreshToken = getLocalStorageRefreshToken();

    if (refreshToken == null || statusCode !== StatusCodes.FORBIDDEN) continue;

    const observable = isRefreshing ? pendingObservable() : refreshObservable();

    return observable.flatMap(() => forward(operation));
  }
});

const authLink = new ApolloLink((operation, forward) => {
  const token = isRefreshRequest(operation)
    ? getLocalStorageRefreshToken()
    : getLocalStorageAccessToken();

  operation.setContext({
    headers: {
      authorization: token ?? '',
    },
  });

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: process.env.API_URL,
});

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({}),
});
