import { FC, ReactElement } from 'react';

import { Button } from './styles';

export interface CommercialButtonProps {
  children: ReactElement | ReactElement[];
  variant?: 'contained' | 'text' | 'outlined';
  className?: string;
  onClick: () => void;
}

const CommercialButton: FC<CommercialButtonProps> = ({
  onClick,
  children,
  variant = 'contained',
  className,
}) => {
  return (
    <Button className={className} variant={variant} onClick={onClick}>
      {children}
    </Button>
  );
};

export default CommercialButton;
