import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { ISocialNetworkItem, ISourceItem } from '@/types/sourceItem.interface';

import {
  SOURCE_DTO_FRAGMENT,
  SOURCE_SOCIAL_NETWORKS_DTO_FRAGMENT,
} from '../fragments';

interface ISource {
  source?: ISourceItem;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export interface ISourceResponseData {
  source: ISourceItem;
}

export const SOURCE = gql`
  ${SOURCE_DTO_FRAGMENT}
  ${SOURCE_SOCIAL_NETWORKS_DTO_FRAGMENT}
  query source($id: Int!) {
    source(id: $id) {
      ...SourceDTOFragment
      socialNetworks {
        ...SourceSocialNetworkDTOFragment
      }
    }
  }
`;

export const useSource = (id: number | undefined): ISource => {
  const { data, loading, refetch } = useQuery<ISourceResponseData>(SOURCE, {
    variables: { id },
  });

  const source = data?.source;

  const updatedSource = source && {
    ...source,
    socialNetworks: source.socialNetworks.map(
      (network: ISocialNetworkItem) => ({
        ...network,
        sourceId: source.id,
      }),
    ),
  };

  return { source: updatedSource, loading, refetch };
};
