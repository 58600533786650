import { FC } from 'react';

import { ListPage } from '../../ListPage';
import { CharacteristicTable } from '../components/CharacteristicTable';

export const CharacteristicsPage: FC = () => (
  <ListPage
    createButtonTitle="Создать характеристику"
    createRoute="/characteristics/create"
  >
    <CharacteristicTable />
  </ListPage>
);
