import { Typography } from '@mui/material';
import { ReactElement } from 'react';

import { Container, Title } from './styles';

interface InfoFieldProps {
  title: string;
  value?: string;
}

export const InfoField = ({ title, value }: InfoFieldProps): ReactElement => {
  return (
    <Container>
      <Title color="text.secondary" variant="body1">
        {title}:
      </Title>
      <Typography color="text.secondary" variant="body1">
        {value ?? '-'}
      </Typography>
    </Container>
  );
};
