import { ApolloQueryResult } from '@apollo/client';
import { ReactElement } from 'react';

import {
  ISourcesResponseData,
  ISourcesVariablesQuery,
} from '@/lib/graphql/queries/useSources';
import { ISourceItem } from '@/types/sourceItem.interface';

import { SourceItem } from '../SourceItem';

interface ISourceList {
  sources: ISourceItem[] | undefined;
  searchSourceByName: string;
  refetch: (
    variables?: Partial<ISourcesVariablesQuery> | undefined,
  ) => Promise<ApolloQueryResult<ISourcesResponseData>>;
}

export const SourcesList: React.FC<ISourceList> = ({
  sources,
  searchSourceByName,
  refetch,
}): ReactElement => {
  const filtresSources =
    searchSourceByName === ''
      ? sources
      : sources?.filter((source) =>
          source.name
            ?.toLocaleLowerCase()
            .includes(searchSourceByName.toLocaleLowerCase().trim()),
        );

  return (
    <>
      {filtresSources?.map((source) => (
        <SourceItem key={source.id} refetchSources={refetch} source={source} />
      ))}
    </>
  );
};
