import { ApolloQueryResult } from '@apollo/client';
import LinkIcon from '@mui/icons-material/Link';
import {
  Divider,
  IconButton,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@/components/atoms/Toast/hooks';
import {
  ISourcesResponseData,
  ISourcesVariablesQuery,
} from '@/lib/graphql/queries/useSources';
import { ISourceItem } from '@/types/sourceItem.interface';

import { ActiveSwitcher } from './components/ActiveSwitcher';
import { DeleteButton } from './components/DeleteButton';
import { EditButton } from './components/EditButton';
import ScrapingActions from './components/ScrapingActions';
import StatusLabel from './components/StatusLabel';
import { AmountProductsSource, ListItem } from './styles';

interface ISourceItemProps {
  source: ISourceItem;
  refetchSources: (
    variables?: Partial<ISourcesVariablesQuery> | undefined,
  ) => Promise<ApolloQueryResult<ISourcesResponseData>>;
}

export const SourceItem = ({
  source,
  refetchSources,
}: ISourceItemProps): ReactElement => {
  const { id, name, url, status, isActive, amountProducts, lastScrapeAt } =
    source;
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleEdit = (id: number): void => navigate(`/sources/${id}`);

  const copyToClipboard = (text?: string) => (): void => {
    if (text) void navigator.clipboard.writeText(text);
    showToast(`Ссылка на источник ${name} скопирована`);
  };

  const handleClick = (id: number) => () => navigate(`/sources/${id}`);

  return (
    <ListItem>
      <Stack alignItems="center" direction="row" gap={2} width="100%">
        <StatusLabel status={status} />
        <ActiveSwitcher
          id={id}
          isActive={isActive}
          name={name}
          refetchSources={refetchSources}
        />

        <Tooltip title="Копировать ссылку на источник">
          <IconButton onClick={copyToClipboard(url)}>
            <LinkIcon />
          </IconButton>
        </Tooltip>

        <ListItemText
          primary={name}
          sx={{ flexGrow: 1, cursor: 'pointer' }}
          onClick={handleClick(id)}
        />

        {lastScrapeAt ? (
          <ListItemText
            primary={new Date(lastScrapeAt).toLocaleString('ru', {
              dateStyle: 'short',
              timeStyle: 'medium',
            })}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          />
        ) : null}

        <Divider flexItem orientation="vertical" />

        <Tooltip title="Amount of products in the database">
          <AmountProductsSource>{amountProducts}</AmountProductsSource>
        </Tooltip>

        <Divider flexItem orientation="vertical" />

        <ScrapingActions
          name={name}
          refetchSources={refetchSources}
          sourceId={id}
          status={status}
        />

        <EditButton id={id} onClick={handleEdit} />

        <DeleteButton refetchSources={refetchSources} source={source} />
      </Stack>
    </ListItem>
  );
};
