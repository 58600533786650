import { FetchResult } from '@apollo/client';
import { Paper } from '@mui/material';
import { Autocomplete } from 'mui-rff';
import { FC } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { CancelButton } from '@/components/atoms/buttons/CancelButton';
import { SaveButton } from '@/components/atoms/buttons/SaveButton';
import { TextFormField } from '@/components/atoms/TextFormField/input';
import { useToast } from '@/components/atoms/Toast/hooks';
import { required } from '@/constants/validate';
import {
  ICharacteristicItem,
  ICreateCharacteristicInput,
} from '@/types/characteristic.interface';
import { IMeasureItem } from '@/types/measure.interface';

import { ICreateCharacteristicResponseData } from '../../CreateCharacteristicPage/hooks';
import {
  IUpdateCharacteristicInput,
  IUpdateCharacteristicResponseData,
} from '../../UpdateCharacteristicPage/hooks/use-update-characteristic';

import { ICharacteristicFormData, IMeasureOption } from './interfaces';
import { Actions, Form } from './styles';

interface ICharacteristicFormProps {
  characteristic: ICharacteristicItem;
  measures: IMeasureItem[];
  onSubmit: (
    characteristic: ICreateCharacteristicInput | IUpdateCharacteristicInput,
  ) => Promise<
    FetchResult<
      ICreateCharacteristicResponseData | IUpdateCharacteristicResponseData
    >
  >;
}

export const CharacteristicForm: FC<ICharacteristicFormProps> = ({
  characteristic,
  measures,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const getMeasureOptions = (measures: IMeasureItem[]): IMeasureOption[] =>
    measures.map(({ id, name }) => ({
      id,
      label: name,
    }));

  const options = getMeasureOptions(measures);

  const selectedOptions = getMeasureOptions(characteristic.measures);

  const handleSave = async (
    formData: ICharacteristicFormData,
  ): Promise<void> => {
    const { id, name, measures } = formData;
    try {
      await onSubmit({
        id,
        name,
        measuresIds: measures.map((measure) => measure.id),
      });
      showToast('Характеристика сохранена');
      navigate('/characteristics');
    } catch (e) {
      showToast('Ошибка во время сохранения характеристики', 'error');
    }
  };

  const handleCancel = (): void => {
    navigate('/characteristics');
  };

  const isOptionEqualToValue = (
    option: IMeasureOption,
    value: IMeasureOption,
  ): boolean => option.id === value.id;

  return (
    <Paper>
      <FinalForm<ICharacteristicFormData>
        initialValues={{
          ...characteristic,
          measures: selectedOptions,
        }}
        onSubmit={handleSave}
      >
        {({ handleSubmit, hasValidationErrors }) => (
          <Form onSubmit={handleSubmit}>
            <TextFormField label="Название" name="name" validate={required} />
            <Autocomplete
              multiple
              isOptionEqualToValue={isOptionEqualToValue}
              label="Величины"
              name="measures"
              options={options}
            />
            <Actions>
              <SaveButton disabled={hasValidationErrors} type="submit" />
              <CancelButton type="button" onClick={handleCancel} />
            </Actions>
          </Form>
        )}
      </FinalForm>
    </Paper>
  );
};
