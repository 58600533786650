import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { PRODUCTS } from '../queries/useProducts';

interface IUseRemoveProduct {
  removeProduct: (id: string) => Promise<FetchResult<boolean>>;
  loading: boolean;
}

export const REMOVE_PRODUCT = gql`
  mutation removeProduct($id: String!) {
    removeProduct(id: $id)
  }
`;

export const useRemoveProduct = (): IUseRemoveProduct => {
  const [mutate, { loading }] = useMutation<boolean>(REMOVE_PRODUCT);

  const removeProduct = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: {
          id,
        },
        refetchQueries: [PRODUCTS],
        update(cache) {
          cache.evict({ fieldName: 'products' });
          cache.gc();
        },
      }),
    [mutate],
  );

  return { removeProduct, loading };
};
