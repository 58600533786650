import { FetchResult } from '@apollo/client';
import { Paper, Stack } from '@mui/material';
import { Autocomplete } from 'mui-rff';
import { ReactElement, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { CancelButton } from '@/components/atoms/buttons/CancelButton';
import { SaveButton } from '@/components/atoms/buttons/SaveButton';
import { TextFormField } from '@/components/atoms/TextFormField/input';
import { useToast } from '@/components/atoms/Toast/hooks';
import { required } from '@/constants/validate';
import { ICreateCategoryItem } from '@/lib/graphql/mutations/categories/useCreateCategory';
import { IUpdateCategoryItem } from '@/lib/graphql/mutations/categories/useUpdateCategory';
import { ICategoryItem } from '@/lib/graphql/queries/categories/useCategories';

import ChildCategories from '../ChildCategories';

interface CategoryDictionaryProps {
  category: ICategoryItem;
  categories: ICategoryItem[];
  handleSubmit:
    | ((category: ICreateCategoryItem) => Promise<FetchResult<ICategoryItem>>)
    | ((category: IUpdateCategoryItem) => Promise<FetchResult<ICategoryItem>>);
}

interface ICategoryAutocompleteOption {
  label: string;
  id: string;
}

interface ICategoryFormData extends Omit<ICategoryItem, 'parentCategory'> {
  parentCategory: ICategoryAutocompleteOption;
}

export function CategoryForm({
  category,
  categories,
  handleSubmit,
}: CategoryDictionaryProps): ReactElement {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const options = useMemo(
    () =>
      categories.map((category) => ({
        label: category.name,
        id: category.id,
      })),
    [categories],
  );

  const handleSave = async (formData: ICategoryFormData): Promise<void> => {
    await handleSubmit({
      ...formData,
      parentCategoryId:
        formData.parentCategory && formData.parentCategory.id !== ''
          ? formData.parentCategory.id
          : null,
    });
    showToast('Категория сохранена');
    navigate('/categories');
  };

  const handleCancel = (): void => navigate('/categories');

  return (
    <Paper>
      <Form<ICategoryFormData>
        initialValues={{
          ...category,
          parentCategory: {
            id: category.parentCategory?.id ?? '',
            label: category.parentCategory?.name ?? '',
          },
        }}
        onSubmit={handleSave}
      >
        {({ handleSubmit, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <Stack gap={2} p={3} width="100%">
              <Stack gap={3}>
                <Autocomplete
                  label="Parent category"
                  name="parentCategory"
                  options={options}
                />
                <TextFormField label="Name *" name="name" validate={required} />
                <TextFormField multiline label="Keywords" name="keywords" />
                <Stack direction="row" gap={2}>
                  <SaveButton disabled={hasValidationErrors} type="submit" />
                  <CancelButton type="button" onClick={handleCancel} />
                </Stack>
                {(category?.childCategories ?? []).length > 0 ? (
                  <ChildCategories nodes={category.childCategories ?? []} />
                ) : null}
              </Stack>
            </Stack>
          </form>
        )}
      </Form>
    </Paper>
  );
}
