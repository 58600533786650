import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@mui/material';
import { FC } from 'react';

interface IMoreButton {
  id: string;
  onClick: (id: string) => void;
}
export const MoreButton: FC<IMoreButton> = ({ id, onClick }) => {
  const handleChange = (): void => {
    onClick(id);
  };
  return (
    <IconButton onClick={handleChange}>
      <MoreHorizIcon />
    </IconButton>
  );
};
