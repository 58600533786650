import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const ModalContainer = styled.div`
  padding: 20px;
`;

export const ModalTitle = styled(Typography)`
  margin: 0 0 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
`;
