import * as t from 'io-ts';
import { isBoolean, isNaN, isNumber } from 'lodash';

export const numericString = new t.Type<number, string, unknown>(
  'number',
  (input: unknown): input is number => isNumber(Number(input)),
  (input, context) => {
    const number = Number(input);
    return isNaN(number) ? t.failure(number, context) : t.success(number);
  },
  String,
);

export const booleanString = new t.Type<boolean, string, unknown>(
  'boolean',
  (u: unknown): u is boolean => isBoolean(Boolean(u)),
  (u, c) => {
    if (u === 'true') return t.success(true);
    if (u === 'false') return t.success(false);
    return t.failure(u, c);
  },
  String,
);
