import styled from '@emotion/styled';
import {
  AppBar as BaseAppBar,
  IconButton as BaseIconButton,
  Menu as BaseMenu,
  css,
  Stack,
  Typography,
} from '@mui/material';

const OPEN_SIDE_BAR_WIDTH = '300px';

const openSideBar = css`
  width: calc(100% - ${OPEN_SIDE_BAR_WIDTH});
  margin: 0 0 0 ${OPEN_SIDE_BAR_WIDTH};
`;

const openSideBarBtn = css`
  display: none;
  padding: 100px;
`;

export const AppBar = styled(BaseAppBar)<{ open?: boolean }>`
  &.MuiAppBar-root {
    width: 100%;
    position: relative;
    height: 70px;
    z-index: 2;
    ${(props) => props.open && openSideBar}
  }
`;

export const IconButton = styled(BaseIconButton)<{ open?: boolean }>`
  &.MuiIconButton-root {
    margin: 0 5px 0 0;
    ${(props) => props.open && openSideBarBtn}
  }
`;

export const Menu = styled(BaseMenu)`
  & .MuiMenu-list {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
  }
`;

export const MainHeader = styled(Typography)`
  margin: 16px 0;
  font-size: 24px;
  text-align: center;
`;

export const HeadStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ChildrenStack = styled(HeadStack)`
  justify-content: flex-start;
  width: auto;
`;
