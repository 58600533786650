import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface ISession {
  accessToken: string;
  refreshToken: string;
}

interface ISignIn {
  signIn: (email: string, password: string) => Promise<ISession | undefined>;
}

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const useSignIn = (): ISignIn => {
  const [mutate] = useMutation(SIGN_IN);

  const signIn = useCallback(
    async (email: string, password: string): Promise<ISession | undefined> => {
      const response: FetchResult<{ signIn: ISession }> = await mutate({
        variables: {
          email,
          password,
        },
      });
      return response?.data?.signIn;
    },
    [mutate],
  );

  return { signIn };
};
