import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '@/components/atoms/Loader';
import { useProduct } from '@/lib/graphql/queries/useProduct';

import { ProductEditPage } from '../ProductEditPage';

export const ProductPage: FC = () => {
  const { id } = useParams();
  const { product, loading } = useProduct(id);

  if (loading) return <Loader />;
  if (!product) return null;
  return <ProductEditPage product={product} />;
};
