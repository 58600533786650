import { Stack } from '@mui/material';
import { ReactElement } from 'react';

import { LoginForm } from '@/components/molecules/LoginForm';

export const Login = (): ReactElement => {
  return (
    <Stack gap={5} width="100%">
      <LoginForm />
    </Stack>
  );
};
