import { IconButton, List, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loader } from '@/components/atoms/Loader';
import {
  ICategoryItem,
  useCategories,
} from '@/lib/graphql/queries/categories/useCategories';

import TreeView from '../components/TreeView';
import useCategoryActions from '../hooks/useCategoryActions';

import { StickyPaper } from './styles';

export const CategoryPage: FC = () => {
  const [categoriesFiltered, setCategoriesFiltered] = useState<ICategoryItem[]>(
    [],
  );
  const { categories, loading } = useCategories();
  const { handleEdit, handleDelete } = useCategoryActions();
  const navigate = useNavigate();

  if (loading) return <Loader />;

  const handleCategoryCreate = (): void => navigate('/categories/create');

  const handleCategoryFilter = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const { value } = e.target;
    const filteredCategories = categories.filter((category: ICategoryItem) =>
      category.name.includes(value),
    );
    setCategoriesFiltered(filteredCategories);
  };

  return (
    <Stack gap={5} width="100%">
      <StickyPaper>
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack alignItems="center" flexDirection="row" gap="30px">
            <IconButton size="large" onClick={handleCategoryCreate}>
              Создать категорию
            </IconButton>
          </Stack>

          <TextField
            label="Filter"
            name="filter"
            onChange={handleCategoryFilter}
          />
        </Stack>
      </StickyPaper>

      <List dense={false}>
        {
          <TreeView
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            nodes={
              categoriesFiltered.length > 0 ? categoriesFiltered : categories
            }
          />
        }
      </List>
    </Stack>
  );
};
