import styled from '@emotion/styled';

export const Form = styled.form`
  display: flex;
  gap: 15px;
  width: 100%;
  flex-direction: column;
  padding: 15px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 15px;
`;
