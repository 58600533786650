import { FetchResult, gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface IStopScrapingMutationData {
  stopScraping: string;
}

interface IStopScrapingVariables {
  sourceId: number;
}

interface IStopScraping extends MutationResult<IStopScrapingMutationData> {
  stopScraping: (
    sourceId: number,
  ) => Promise<FetchResult<IStopScrapingMutationData>>;
}

export const STOP_SCRAPING = gql`
  mutation stopScraping($sourceId: Int!) {
    stopScraping(sourceId: $sourceId)
  }
`;

export const useStopScraping = (): IStopScraping => {
  const [mutate, options] = useMutation<
    IStopScrapingMutationData,
    IStopScrapingVariables
  >(STOP_SCRAPING);

  const stopScraping = useCallback(
    (sourceId: number): Promise<FetchResult<IStopScrapingMutationData>> =>
      mutate({
        variables: { sourceId },
      }),
    [mutate],
  );

  return { stopScraping, ...options };
};
