import styled from '@emotion/styled';
import { Button as BaseButton } from '@mui/material';

export const Button = styled(BaseButton)`
  margin: 15px 15px 0 0;
`;

export const Container = styled.div`
  width: 100%;
`;
