import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { ICharacteristicItem } from '@/types/characteristic.interface';

interface ICharacteristicsResponseData {
  characteristics: ICharacteristicItem[];
}

interface ICharacteristics {
  characteristics?: ICharacteristicItem[];
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const CHARACTERISTICS_QUERY = gql`
  query characteristics {
    characteristics {
      id
      name
      measures {
        id
        name
      }
    }
  }
`;

export const useCharacteristics = (): ICharacteristics => {
  const { data, loading, refetch } = useQuery<ICharacteristicsResponseData>(
    CHARACTERISTICS_QUERY,
  );

  const characteristics = data?.characteristics ?? [];

  return { characteristics, loading, refetch };
};
