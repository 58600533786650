import { Stack } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '@/components/atoms/Loader';
import { useUpdateCategory } from '@/lib/graphql/mutations/categories/useUpdateCategory';
import { useCategory } from '@/lib/graphql/queries/categories/useCategory';
import { useParentCategories } from '@/lib/graphql/queries/categories/useParentCategories';

import { CategoryForm } from '../components/CategoryForm';

export const CategoryUpdatePage: FC = () => {
  const { id } = useParams();
  const { categories } = useParentCategories();
  const { category, loading } = useCategory(id);
  const { updateCategory } = useUpdateCategory();

  if (loading) return <Loader />;
  if (!category) return null;

  return (
    <Stack gap={5} width="100%">
      <CategoryForm
        categories={categories}
        category={category}
        handleSubmit={updateCategory}
      />
    </Stack>
  );
};
