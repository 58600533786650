import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IBannerItem } from '@/types/banner.interface';

import { BANNER_DTO_FRAGMENT } from '../fragments';

interface IUpdateBanner {
  updateBanner: (banner: IBannerItem) => Promise<FetchResult<IBannerItem>>;
}

export const UPDATE_BANNER = gql`
  ${BANNER_DTO_FRAGMENT}
  mutation updateBanner(
    $id: String!
    $isActive: Boolean!
    $name: String!
    $goToUrl: String!
    $title: String!
    $text: String
    $textColor: String
    $titleColor: String!
    $backgroundColor: String
    $backgroundImageUrl: String!
    $locale: String!
  ) {
    updateBanner(
      updateBannerInput: {
        id: $id
        isActive: $isActive
        name: $name
        goToUrl: $goToUrl
        title: $title
        text: $text
        titleColor: $titleColor
        textColor: $textColor
        backgroundColor: $backgroundColor
        backgroundImageUrl: $backgroundImageUrl
        locale: $locale
      }
    ) {
      ...BannerDTOFragment
    }
  }
`;

export const useUpdateBanner = (): IUpdateBanner => {
  const [mutate] = useMutation(UPDATE_BANNER);

  const updateBanner = useCallback(
    (banner: IBannerItem): Promise<FetchResult<IBannerItem>> =>
      mutate({
        variables: banner,
      }),
    [mutate],
  );

  return { updateBanner };
};
