import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { CreateButton } from './styles';

interface ListPageProps {
  createRoute: string;
  createButtonTitle: string;
  children: ReactNode;
}

export const ListPage: FC<ListPageProps> = ({
  createButtonTitle,
  createRoute,
  children,
}) => {
  const navigate = useNavigate();

  const handleClick = (): void => navigate(createRoute);

  return (
    <>
      <CreateButton variant="contained" onClick={handleClick}>
        {createButtonTitle}
      </CreateButton>
      {children}
    </>
  );
};
