import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';

import ModalWindow from '@/components/atoms/ModalWindow';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useRemoveProducts } from '@/lib/graphql/mutations/useRemoveProducts';

interface DeleteButtonProps {
  ids: string[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export const DeleteSelectedButton = ({
  ids,
  setSelectedProducts,
}: DeleteButtonProps): ReactElement => {
  const { showToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);

  const { removeProducts, loading } = useRemoveProducts();

  const handleOpen = (): void => setIsOpen(true);
  const handleClose = (): void => setIsOpen(false);

  const handleProductDelete = async (): Promise<void> => {
    await removeProducts(ids);
    setIsOpen(false);
    setSelectedProducts(new Set<string>());

    showToast('Products are deleted');
  };

  return (
    <Container>
      <IconButton aria-label="delete" edge="end" onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <ModalWindow isOpen={isOpen} onClose={handleClose}>
        <Stack p={1} spacing={4}>
          <Typography>Delete selected products?</Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button
              color="error"
              disabled={loading}
              variant="contained"
              onClick={handleProductDelete}
            >
              Delete
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </ModalWindow>
    </Container>
  );
};
