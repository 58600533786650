import styled from '@emotion/styled';
import {
  TableCell as BaseTableCell,
  TableContainer as BaseTableContainer,
  Paper,
} from '@mui/material';

import { defaultTableCellMinWidth } from './constants';

export const TablePaper = styled(Paper)`
  width: 100%;
  overflow: hidden;
`;

export const TableContainer = styled(BaseTableContainer)`
  max-height: 440px;
`;

export const TableCell = styled(BaseTableCell)<{ minWidth?: number }>`
  min-width: ${(props) => props.minWidth && defaultTableCellMinWidth}px;
`;
