import styled from '@emotion/styled';
import {
  List as BaseList,
  ListItem as BaseListItem,
  Stack,
  SxProps,
} from '@mui/material';

export const bannerSx: Record<'text' | 'title', SxProps> = {
  title: { margin: '15px 0 5px 15px', fontSize: 'calc(100vw * 0.008)' },
  text: { margin: '0 0 0 15px', fontSize: 'calc(100vw * 0.0065)' },
};

export const List = styled(BaseList)`
  width: 100%;
  padding: 20px 0;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
`;

export const ListItem = styled(BaseListItem)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonStack = styled(Stack)`
  width: max-content;
  height: max-content;
  border-radius: 25px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const HeaderStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
