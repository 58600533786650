import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { Form } from 'react-final-form';

import { InputField } from '@/components/atoms/InputField';
import { SelectLocale } from '@/components/atoms/SelectLocale';
import { useToast } from '@/components/atoms/Toast/hooks';
import { required } from '@/constants/validate';
import { useSendAnswerToFeedback } from '@/lib/graphql/mutations/useSendAnswer';
import { IFeedbackItem } from '@/types/feedback.interface';

import { Button, Container, IconButton, Title } from './styles';

export const AnswerForm: React.FC<{
  feedbackData: IFeedbackItem;
  onClose: () => void;
}> = ({ feedbackData, onClose }) => {
  const { showToast } = useToast();
  const [locale, setLocale] = useState<string>('ru');
  const { sendAnswerToFeedback } = useSendAnswerToFeedback(feedbackData);
  const handleSubmit = async ({ text }: { text: string }): Promise<void> => {
    const { data } = await sendAnswerToFeedback(text, locale);
    const success = data?.sendAnswerToFeedback.success;

    if (success) {
      showToast('Сообщение отправлено пользователю');
      onClose();
    }
  };

  return (
    <Container>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Form
        render={({ hasValidationErrors, submitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Title variant="h4">{`Отправить сообщение для ${feedbackData?.name}`}</Title>
            <SelectLocale value={locale} onChangeLocale={setLocale} />
            <InputField
              label={'Сообщение'}
              name={'text'}
              validator={required}
            />
            <Button
              fullWidth
              disabled={hasValidationErrors || submitting}
              type="submit"
              variant="contained"
            >
              Отправить
            </Button>
          </form>
        )}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};
