import { SelectChangeEvent } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';

import {
  ICategoryItem,
  useCategories,
} from '@/lib/graphql/queries/categories/useCategories';

import { ISaveProductForm } from '../../types';

import { CategorySelect } from './components/CategorySelect';
import { Stack } from './styles';

export const ProductCategory: FC = () => {
  const { categories } = useCategories();
  const { change } = useForm();
  const { values: formValues } = useFormState<ISaveProductForm>();

  const handleCategoryChange = (
    event: SelectChangeEvent<Maybe<string>>,
  ): void => {
    change('categoryId', event.target.value);
    change('subcategoryId', undefined);
    change('subchildCategoryId', undefined);
  };

  const handleSubcategoryChange = (
    event: SelectChangeEvent<Maybe<string>>,
  ): void => {
    change('subcategoryId', event.target.value);
    change('subchildCategoryId', undefined);
  };

  const handleSubchildСategoryChange = (
    event: SelectChangeEvent<Maybe<string>>,
  ): void => {
    change('subchildCategoryId', event.target.value);
  };

  const selectedCategory = categories.find(
    (category) => category.id === formValues?.categoryId,
  );

  const subcategories: ICategoryItem[] =
    selectedCategory?.childCategories ?? [];

  const selectedSubcategory = subcategories.find(
    (subcategory) => subcategory.id === formValues?.subcategoryId,
  );

  const subсhildCategory = selectedSubcategory?.childCategories ?? [];

  return (
    <Stack>
      <CategorySelect
        categories={categories}
        label={'category'}
        name="categoryId"
        onChange={handleCategoryChange}
      />

      <CategorySelect
        categories={subcategories}
        label={'subcategory'}
        name="subcategoryId"
        onChange={handleSubcategoryChange}
      />

      <CategorySelect
        categories={subсhildCategory}
        label={'type'}
        name="subchildCategoryId"
        onChange={handleSubchildСategoryChange}
      />
    </Stack>
  );
};
