import { ApolloQueryResult } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';

import ModalWindow from '@/components/atoms/ModalWindow';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useRemoveProductsFromSource } from '@/lib/graphql/mutations/useRemoveProductsFromSource';
import {
  ISourcesResponseData,
  ISourcesVariablesQuery,
} from '@/lib/graphql/queries/useSources';
import { ISourceItem, ScrapingStatus } from '@/types/sourceItem.interface';

interface DeleteButtonProps {
  source: ISourceItem;
  refetchSources: (
    variables?: Partial<ISourcesVariablesQuery> | undefined,
  ) => Promise<ApolloQueryResult<ISourcesResponseData>>;
}

export const DeleteButton = ({
  source,
  refetchSources,
}: DeleteButtonProps): ReactElement => {
  const { id, name, status } = source;

  const [isOpen, setIsOpen] = useState(false);
  const { removeProductsFromSource, loading } = useRemoveProductsFromSource();

  const handleOpen = (): void => setIsOpen(true);
  const handleClose = (): void => setIsOpen(false);

  const { showToast } = useToast();

  const handleDeleteButtonClick = async (): Promise<void> => {
    await removeProductsFromSource(id);
    setIsOpen(false);
    await refetchSources();
    showToast('Источник удален');
  };

  return (
    <>
      <IconButton
        aria-label="delete"
        disabled={status === ScrapingStatus.InProgress}
        edge="end"
        onClick={handleOpen}
      >
        <DeleteIcon />
      </IconButton>
      <ModalWindow isOpen={isOpen} onClose={handleClose}>
        <Stack p={1} spacing={4}>
          <Typography>{`Источник ${name} и все его продукты будут удалены. Это действие необратимо`}</Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button
              color="error"
              disabled={loading}
              variant="contained"
              onClick={handleDeleteButtonClick}
            >
              Продолжить
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={handleClose}
            >
              Отменить
            </Button>
          </Stack>
        </Stack>
      </ModalWindow>
    </>
  );
};
