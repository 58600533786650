import styled from '@emotion/styled';

export const Truncate = styled.span`
  color: #1976d2;
  font-size: 14px;
  cursor: pointer;
  font-family: Roboto;
`;

export const Text = styled.div`
  font-family: Roboto;
`;
