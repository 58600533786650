import styled from '@emotion/styled';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Typography } from '@mui/material';

import { Colors } from '@/variables/colors';

export const Text = styled(Typography)`
  cursor: pointer;
  text-decoration: none;
  font-family: Manrope;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.009375em;
  max-width: 44%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${Colors.Emperor};
`;

export const ArrowDownIcon = styled(ArrowDropDownIcon)``;

export const ArrowUpIcon = styled(ArrowDropUpIcon)``;
