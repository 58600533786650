import { gql, useQuery } from '@apollo/client';

import { IUser } from '@/types/user.interface';

interface ICurrentUser {
  user?: IUser;
  loading: boolean;
}

export const USER = gql`
  query User {
    user {
      id
      role
    }
  }
`;

export const useCurrentUser = (): ICurrentUser => {
  const { data, loading } = useQuery<ICurrentUser>(USER);
  const user = data?.user;

  return { user, loading };
};
