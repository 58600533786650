import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { BANNER_DTO_FRAGMENT } from '@/lib/graphql/fragments';
import { IBannerItem } from '@/types/banner.interface';

interface IBannersData {
  bannersByLocale?: IBannerItem[];
}

interface IBannersVariables {
  locale: string;
}

interface IBanners {
  banners?: IBannerItem[];
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<IBannersData>>;
}

export const BANNERS = gql`
  ${BANNER_DTO_FRAGMENT}
  query bannersByLocale($locale: String!) {
    bannersByLocale(locale: $locale) {
      ...BannerDTOFragment
    }
  }
`;

export const useBannersByLocale = (locale: string): IBanners => {
  const { data, loading, refetch } = useQuery<IBannersData, IBannersVariables>(
    BANNERS,
    {
      variables: { locale },
    },
  );

  const banners = data?.bannersByLocale;

  return { banners, loading, refetch };
};
