type OrganizationTableHead = {
  label: string;
  align: 'left' | 'right';
};

export const tableHeadColumns: OrganizationTableHead[] = [
  { label: 'Название', align: 'left' },
  { label: 'Email', align: 'left' },
  { label: 'ИНН', align: 'left' },
  { label: 'Активировать/Деактивировать', align: 'left' },
  { label: 'Удалить', align: 'right' },
  { label: 'Скрыть/Показывать', align: 'right' },
  { label: 'Подробнее', align: 'right' },
];
