import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { IUserRequestAttachmentFile } from '@/types/requests.interface';

interface IUploadedFileProps extends IUserRequestAttachmentFile {
  onDelete: (key: string) => Promise<void>;
  disabled: boolean;
}

export const UploadedFile = ({
  id,
  fileName,
  onDelete,
  disabled,
}: IUploadedFileProps): ReactElement => {
  const handleCloseButtonClick = (): void => {
    void onDelete(id);
  };

  return (
    <Stack direction="row" display="flex">
      <Typography key={id}>{fileName}</Typography>
      <Button disabled={disabled} onClick={handleCloseButtonClick}>
        <CloseIcon />
      </Button>
    </Stack>
  );
};
