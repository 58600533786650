import { ApolloQueryResult } from '@apollo/client';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';

import ModalWindow from '@/components/atoms/ModalWindow';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useUpdateOrganizationStatus } from '@/lib/graphql/mutations/useUpdateOrganizationStatus';
import { OrganizationsQueryResult } from '@/lib/graphql/queries/useOrganizations';
import { useModal } from '@/lib/hooks/useModal';

import { ModalForm } from '../ModalForm';

interface HideButtonProps {
  id: string;
  isHidden: boolean;
  refetch: () => Promise<ApolloQueryResult<OrganizationsQueryResult>>;
}

export const HideButton: FC<HideButtonProps> = ({ id, isHidden, refetch }) => {
  const [isOpen, toggle] = useModal();

  const { updateOrganizationStatus } = useUpdateOrganizationStatus();

  const { showToast } = useToast();

  const handleClick = async (): Promise<void> => {
    await updateOrganizationStatus({ id, isHidden: !isHidden });

    isHidden
      ? showToast(`Организация показывается`)
      : showToast(`Организация скрыта`);

    toggle();
    await refetch();
  };

  const handleToggle = (): void => toggle();

  const title = isHidden
    ? 'Вы действительно хотите ПОКАЗЫВАТЬ организацию и все её продукты?'
    : 'Вы действительно хотите СКРЫТЬ организацию и все её продукты?';

  return (
    <>
      <IconButton onClick={handleToggle}>
        {isHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
      <ModalWindow isOpen={isOpen} onClose={handleToggle}>
        <ModalForm
          buttonText={isHidden ? 'Показывать' : 'Скрыть'}
          title={title}
          onClick={handleClick}
          onClose={handleToggle}
        />
      </ModalWindow>
    </>
  );
};
