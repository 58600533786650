import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { CATEGORY_DTO_FRAGMENT } from '../../fragments';

import { ICategoryItem } from './useCategories';

interface ICategory {
  category?: ICategoryItem;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const CATEGORY = gql`
  ${CATEGORY_DTO_FRAGMENT}
  query getCategoryById($id: String!) {
    getCategoryById(id: $id) {
      ...CategoryDTOFragment
    }
  }
`;

interface ICategoryResponse {
  getCategoryById?: ICategoryItem;
}

export const useCategory = (id: string | undefined): ICategory => {
  const { data, loading, refetch } = useQuery<ICategoryResponse>(CATEGORY, {
    variables: { id },
  });

  const category = data?.getCategoryById;

  return { category, loading, refetch };
};
