import { css, GlobalStyles } from '@mui/material';
import React from 'react';

const globalStyle = css`
  html {
    height: 100%;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    height: 100%;
  }
  #root {
    height: 100%;
    width: 100%;
  }
`;

export const GlobalStyle: React.FC = () => (
  <GlobalStyles styles={globalStyle} />
);
