import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { FC } from 'react';

import { muiContainerStyles, muiFieldStyles } from '../../styles';

interface DatePickerFieldProps {
  label: string;
  name: string;
  onChange: (date: Date | null) => void;
}

export const DatePickerField: FC<DatePickerFieldProps> = ({
  label,
  name,
  onChange,
}) => {
  return (
    <DemoContainer components={['DatePicker']} sx={muiContainerStyles}>
      <DatePicker
        label={label}
        name={name}
        slotProps={{
          actionBar: {
            actions: ['clear'],
          },
          field: { clearable: true },
        }}
        sx={muiFieldStyles}
        onChange={onChange}
      />
    </DemoContainer>
  );
};
