export const enum Colors {
  Denim = '#1976D2',
  White = '#FFFFFF',
  WildSand = '#F5F5F5',
  Alabaster = '#F8F8F8',
  CuttySark = '#546E7A',
  Emperor = '#4F4F4F',
  Divider = '#E0E0E0',
  Silver = `#C0C0C0`,
  Black = '#000',
  SunsetOrange = '#FF4842',
  KellyGreen = '#4CBB17',
  Amber = '#FFBF00',
}
