import { Typography } from '@mui/material';
import { ReactElement } from 'react';

import { ImageCover } from './styles';

interface OrganizationImageProps {
  imageUrl?: string | null;
}

export const OrganizationImage = ({
  imageUrl,
}: OrganizationImageProps): ReactElement => {
  return imageUrl != null ? (
    <ImageCover src={imageUrl} />
  ) : (
    <Typography color="text.secondary" variant="body1">
      Изображение отсутствует
    </Typography>
  );
};
