import styled from '@emotion/styled';
import { Paper as BasePaper } from '@mui/material';

export const Paper = styled(BasePaper)`
  &.MuiPaper-root {
    width: 100%;
    padding: 10px 20px;
    margin: 0 0 32px;
    position: sticky;
    top: 0;
  }
`;

export const ControlRow = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr repeat(2, min-content);
  gap: 16px;
  align-items: center;
`;
