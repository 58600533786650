import { ApolloProvider } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FC } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { GlobalStyle } from './components/atoms/GlobalStyle';
import Toast from './components/atoms/Toast';
import { ToastProvider } from './components/atoms/Toast/ToastProvider';
import { BannerList } from './components/pages/BannerList';
import { CreateBanner } from './components/pages/CreateBanner';
import { EditBanner } from './components/pages/EditBanner';
import { FeedbackListPage } from './components/pages/FeedbackListPage';
import { Login } from './components/pages/Login';
import { OrganizationList } from './components/pages/OrganizationList';
import { OrganizationPage } from './components/pages/OrganizationPage';
import { ProductList } from './components/pages/ProductList';
import { ProductPage } from './components/pages/ProductPage';
import { RequestsPage } from './components/pages/RequestsPage';
import { CategoryPage } from './components/pages/scenes/Categories/all';
import { CategoryCreatePage } from './components/pages/scenes/Categories/create';
import { CategoryUpdatePage } from './components/pages/scenes/Categories/update';
import { CharacteristicsPage } from './components/pages/scenes/Characteristics/CharacteristicListPage';
import { CreateCharacteristicPage } from './components/pages/scenes/Characteristics/CreateCharacteristicPage';
import { UpdateCharacteristicPage } from './components/pages/scenes/Characteristics/UpdateCharacteristicPage';
import { InvitesPage } from './components/pages/scenes/Invites/InvitesPage';
import { CreateMeasurePage } from './components/pages/scenes/Measures/CreateMeasurePage';
import { MeasuresPage } from './components/pages/scenes/Measures/MeasureListPage';
import { UpdateMeasurePage } from './components/pages/scenes/Measures/UpdateMeasurePage';
import { TestResultsPage } from './components/pages/scenes/TestResults/all';
import { TestResultPage } from './components/pages/scenes/TestResults/show';
import { SourcePage } from './components/pages/SourcePage';
import { SourcesPage } from './components/pages/SourcesPage';
import { MainTemplate } from './components/templates/MainTemplate';
import { AdminGuard } from './lib/api/AdminGuard';
import { apolloClient } from './lib/graphql/client';
import { browserNavigator } from './lib/utils/browser-navigator';

export const App: FC = () => {
  browserNavigator.navigate = useNavigate();

  return (
    <>
      <GlobalStyle />
      <ApolloProvider client={apolloClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastProvider>
            <Toast />
            <Routes>
              <Route element={<Login />} path="login" />
              <Route
                element={
                  <AdminGuard>
                    <Navigate to="products" />
                  </AdminGuard>
                }
                path="/"
              />
              <Route
                element={
                  <AdminGuard>
                    <MainTemplate />
                  </AdminGuard>
                }
              >
                <Route element={<ProductList />} path="products" />
                <Route element={<ProductPage />} path="products/:id" />
                <Route element={<SourcesPage />} path="sources" />
                <Route element={<SourcePage />} path="sources/:id" />
                <Route element={<BannerList />} path="banners" />
                <Route element={<CreateBanner />} path="banners/create" />
                <Route element={<EditBanner />} path="banners/:id/edit" />
                <Route element={<FeedbackListPage />} path="feedbacks" />
                <Route element={<CategoryPage />} path="categories" />
                <Route element={<TestResultsPage />} path="test-results" />
                <Route element={<TestResultPage />} path="test-result/:id" />
                <Route element={<InvitesPage />} path="invites" />
                <Route
                  element={<CategoryUpdatePage />}
                  path="categories/:id/edit"
                />
                <Route
                  element={<CategoryCreatePage />}
                  path="categories/create"
                />
                <Route element={<OrganizationList />} path="organizations" />
                <Route
                  element={<OrganizationPage />}
                  path="organizations/:id"
                />

                <Route element={<RequestsPage />} path="user_requests" />
                <Route
                  element={<CharacteristicsPage />}
                  path="characteristics"
                />
                <Route
                  element={<CreateCharacteristicPage />}
                  path="characteristics/create"
                />
                <Route
                  element={<UpdateCharacteristicPage />}
                  path="characteristics/:id/edit"
                />
                <Route element={<MeasuresPage />} path="measures" />
                <Route element={<CreateMeasurePage />} path="measures/create" />
                <Route
                  element={<UpdateMeasurePage />}
                  path="measures/:id/edit"
                />
              </Route>
            </Routes>
          </ToastProvider>
        </LocalizationProvider>
      </ApolloProvider>
    </>
  );
};
