import Button from '@mui/material/Button';
import { FC } from 'react';

interface ICancelButton {
  onClick: () => void;
  type?: 'submit' | 'button' | 'reset';
}
export const CancelButton: FC<ICancelButton> = (props) => {
  return (
    <Button variant="contained" {...props}>
      Cancel
    </Button>
  );
};
