import { Pagination as MuiPagination } from '@mui/material';
import React from 'react';

import { PaginationStack } from './styles';

interface IPagination {
  total: number;
  page: number;
  limit?: number;
  onChange: (value: number) => void;
}

export const Pagination: React.FC<IPagination> = ({
  total,
  page,
  limit = 30,
  onChange,
}) => {
  return (
    <PaginationStack>
      <MuiPagination
        count={Math.ceil(total / limit)}
        page={page}
        onChange={(e, page) => onChange(page)}
      />
    </PaginationStack>
  );
};
