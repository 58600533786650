import { Alert } from '@mui/material';
import { ReactElement } from 'react';

import { ContainerTemplate } from './styles';

export const ListEmpty = (): ReactElement => {
  return (
    <ContainerTemplate>
      <Alert severity="info">This list is empty!</Alert>
    </ContainerTemplate>
  );
};
