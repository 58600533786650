import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { IProductItem } from '@/types/productItem.interface';

import {
  BRAND_AND_PROVIDER_FRAGMENT,
  CATALOG_FRAGMENT,
  CHARACTERISTICS_FRAGMENT,
  IMAGE_DTO_FRAGMENT,
  PRODUCT_DTO_FRAGMENT,
  TD_MODEL_FRAGMENT,
} from '../fragments';

interface IProduct {
  product?: IProductItem;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const PRODUCT = gql`
  ${BRAND_AND_PROVIDER_FRAGMENT}
  ${PRODUCT_DTO_FRAGMENT}
  ${IMAGE_DTO_FRAGMENT}
  ${CATALOG_FRAGMENT}
  ${TD_MODEL_FRAGMENT}
  ${CHARACTERISTICS_FRAGMENT}
  query product($id: String!) {
    product(id: $id) {
      ...ProductDTOFragment
      images {
        ...ImageDTOFragment
      }
      ...BrandAndProviderLogoFragment
      ...CatalogsDownloadFragment
      ...TdModelsDownloadFragment
      ...CharacteristicsFragment
    }
  }
`;

export const useProduct = (id: string | undefined): IProduct => {
  const { data, loading, refetch } = useQuery<IProduct>(PRODUCT, {
    variables: { id },
  });

  const product = data?.product;

  return { product, loading, refetch };
};
