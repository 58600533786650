import { FC } from 'react';

import { DownloadFileButton } from '@/components/molecules/DownloadFileButton';
import { ICatalog } from '@/types/catalog.interface';

interface Props {
  catalogs?: ICatalog[];
  onCatalogDownloadClick: (fileUrl: string) => void;
  onToggleCatalogsModalShow: () => void;
}

export const CatalogDownloadButton: FC<Props> = ({
  catalogs = [],
  onCatalogDownloadClick,
  onToggleCatalogsModalShow,
}) => {
  const hasManyCatalogs = catalogs.length > 1;

  return (
    <DownloadFileButton
      fileUrl={hasManyCatalogs ? '' : catalogs[0]?.file.url}
      name={`Download catalog`}
      variant="outlined"
      onClick={
        hasManyCatalogs ? onToggleCatalogsModalShow : onCatalogDownloadClick
      }
    />
  );
};
