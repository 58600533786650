import Button from '@mui/material/Button';
import { FC } from 'react';

interface ICloseButton {
  onClick: () => void;
}
export const CloseButton: FC<ICloseButton> = ({ onClick }) => {
  return (
    <Button variant="contained" onClick={onClick}>
      Close
    </Button>
  );
};
