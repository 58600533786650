'use client';

import { useCallback, useState } from 'react';

type UseToggle = (
  defaultValue?: boolean,
) => [isToggled: boolean, toggle: () => void];

const useToggle: UseToggle = (defaultValue = false) => {
  const [isToggled, setIsToggled] = useState<boolean>(defaultValue);

  const toggle = useCallback((): void => setIsToggled((prev) => !prev), []);

  return [isToggled, toggle];
};

export default useToggle;
