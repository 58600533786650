import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  IconButton as BaseIconButton,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccess } from '@/lib/hooks/useAccess';
import { clearLocalStorageSession } from '@/lib/utils/session';

import { AppBar, ChildrenStack, HeadStack, IconButton, Menu } from './styles';

interface HeaderProps {
  title?: string;
  isOpen?: boolean;
  onDrawerOpen?: () => void;
}

export const Header = (props: HeaderProps): ReactElement => {
  const navigate = useNavigate();
  const { title, isOpen, onDrawerOpen } = props;
  const { user } = useAccess();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void =>
    setAnchorElUser(event.currentTarget);

  const handleCloseUserMenu = (): void => setAnchorElUser(null);

  const handleLogout = (): void => {
    clearLocalStorageSession();
    navigate('/login');
  };

  return (
    <AppBar open={isOpen}>
      <Toolbar>
        <HeadStack>
          {isOpen ? (
            <></>
          ) : (
            <ChildrenStack>
              <IconButton
                color="inherit"
                edge="start"
                open={isOpen}
                onClick={onDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <Typography noWrap color="inherit" component="h1" variant="h6">
                {title}
              </Typography>
            </ChildrenStack>
          )}
          <Stack alignItems="flex-end" direction="row">
            <Typography variant="h5">Enki Project</Typography>
            <Typography
              color={({ palette }) => palette.grey[400]}
              sx={{ ml: 1 }}
              variant="body2"
            >
              admin panel
            </Typography>
          </Stack>
          <Tooltip title="Open settings">
            <BaseIconButton onClick={handleOpenUserMenu}>
              <Avatar alt={user?.firstName} src={user?.picture} />
            </BaseIconButton>
          </Tooltip>
          <Menu
            keepMounted
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key="Profile" onClick={handleCloseUserMenu}>
              <Typography textAlign="center">Profile info</Typography>
            </MenuItem>
            <MenuItem key="Logout" onClick={handleCloseUserMenu}>
              <Typography textAlign="center" onClick={handleLogout}>
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </HeadStack>
      </Toolbar>
    </AppBar>
  );
};
