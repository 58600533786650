import { Box, Paper, Stack, TextField } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

import { SaveButton } from '@/components/atoms/buttons/SaveButton';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useCreateSocialNetwork } from '@/lib/graphql/mutations/useCreateSocialNetwork';
import { useRemoveSocialNetwork } from '@/lib/graphql/mutations/useRemoveSocialNetwork';
import { useUpdateSocialNetwork } from '@/lib/graphql/mutations/useUpdateSocialNetwork';
import { useUpdateSource } from '@/lib/graphql/mutations/useUpdateSource';
import { SocialNetworkType } from '@/types/source.enum';
import { ISocialNetworkItem, ISourceItem } from '@/types/sourceItem.interface';

import { ActiveSwitcher } from './components/ActiveSwitcher';

const SOCIAL_NETWORKS = [
  {
    label: 'Telegram',
    name: SocialNetworkType.Telegram,
  },
  {
    label: 'WhatsApp',
    name: SocialNetworkType.WhatsApp,
  },
  {
    label: 'VK',
    name: SocialNetworkType.VK,
  },
  {
    label: 'YouTube',
    name: SocialNetworkType.YouTube,
  },
  {
    label: 'Pinterest',
    name: SocialNetworkType.Pinterest,
  },
  {
    label: 'Instagram',
    name: SocialNetworkType.Instagram,
  },
  {
    label: 'Facebook',
    name: SocialNetworkType.Facebook,
  },
  {
    label: 'Dzen',
    name: SocialNetworkType.Dzen,
  },
];

interface SourceFormProps {
  source: ISourceItem;
}

export function SourceForm({ source }: SourceFormProps): ReactElement {
  const [currentSource, setCurrentSource] = useState<ISourceItem>(source);
  const [isValid, setIsValid] = useState(false);

  const { socialNetworks } = source;
  const { socialNetworks: currentNetworks } = currentSource;

  const { updateSource } = useUpdateSource();
  const { createSocialNetwork } = useCreateSocialNetwork();
  const { updateSocialNetwork } = useUpdateSocialNetwork();
  const { removeSocialNetwork } = useRemoveSocialNetwork();
  const { showToast } = useToast();

  useEffect(() => {
    if (isFormValid(source, currentSource, socialNetworks, currentNetworks))
      return setIsValid(true);

    setIsValid(false);
  }, [currentNetworks, currentSource, socialNetworks, source]);

  const handleChangeCurrentSource = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { name, value } = e.target;
    setCurrentSource({ ...currentSource, [name]: value });
  };

  const handleChangeCurrentSourceActive = (isActive: boolean): void => {
    setCurrentSource({ ...currentSource, isActive });
  };

  const handleChangeSocialNetworkUrl = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { name, value } = e.target;
    const updatedSocialNetworks = currentNetworks.map((network) => {
      return network.type === name ? { ...network, url: value } : network;
    });

    const foundNetwork = updatedSocialNetworks.find(
      (network) => network.type === name,
    );

    if (!foundNetwork) {
      updatedSocialNetworks.push({
        type: name,
        url: value,
        sourceId: source.id,
      });
    }

    setCurrentSource({
      ...currentSource,
      socialNetworks: updatedSocialNetworks,
    });
  };

  const handleSave = async (): Promise<void> => {
    await updateSource(currentSource);
    showToast('Источник сохранен');
    currentNetworks.forEach(async (network) => {
      if (
        socialNetworks.find(
          (sourceNetwork) => sourceNetwork.type === network.type,
        )?.url !== network.url &&
        network.url.length > 0
      ) {
        network.id
          ? await updateSocialNetwork(network)
          : await createSocialNetwork(network);
      }
      if (network.id && network.url.length < 1)
        await removeSocialNetwork(network.id);
    });
  };

  return (
    <Paper>
      <Stack gap={2} p={3} width="100%">
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <ActiveSwitcher
            value={currentSource.isActive}
            onChange={handleChangeCurrentSourceActive}
          />
        </Stack>
        <Stack gap={3}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={currentSource.name}
            onChange={handleChangeCurrentSource}
          />
          <TextField
            fullWidth
            label="Url"
            name="url"
            value={currentSource.url}
            onChange={handleChangeCurrentSource}
          />
          {SOCIAL_NETWORKS.map(({ label, name }) => (
            <TextField
              key={name}
              fullWidth
              label={label}
              name={name}
              value={
                currentSource.socialNetworks?.find(({ type }) => type === name)
                  ?.url
              }
              onChange={handleChangeSocialNetworkUrl}
            />
          ))}
        </Stack>
        <Box>
          <SaveButton disabled={!isValid} onClick={handleSave} />
        </Box>
      </Stack>
    </Paper>
  );
}

function areSocialNetworksChanged(
  sourceSocialNetworks: ISocialNetworkItem[],
  currentSocialNetworks: ISocialNetworkItem[],
): boolean {
  return (
    sourceSocialNetworks.map((network) => network.url).join('') !==
    currentSocialNetworks.map((network) => network.url).join('')
  );
}

function isFormValid(
  source: ISourceItem,
  current: ISourceItem,
  networks: ISocialNetworkItem[],
  currentNetworks: ISocialNetworkItem[],
): boolean {
  const { isActive, name, url } = current;
  if (
    areSocialNetworksChanged(networks, currentNetworks) ||
    source.isActive !== isActive ||
    source.name !== name ||
    (source.url !== url && name?.trim() !== '' && url?.trim() !== '')
  )
    return true;

  return false;
}
