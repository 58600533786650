import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { TEST_RESULT_DTO_FRAGMENT } from '../../fragments';
import { ITestResultResponseItem } from '../../queries/test-results/useTestResults';

interface ICreateTestResultResponse {
  testProducts: (
    ids?: number[],
  ) => Promise<FetchResult<ITestResultResponseItem>>;
}
export const TEST_ALL = gql`
  ${TEST_RESULT_DTO_FRAGMENT}
  mutation createScrapeTestResults($ids: [Int!]!) {
    createScrapeTestResults(ids: $ids) {
      ...TestResultDTOFragment
    }
  }
`;

export const useTestProducts = (): ICreateTestResultResponse => {
  const [mutate] = useMutation(TEST_ALL);

  const testProducts = useCallback(
    (sourceIds?: number[]) =>
      mutate({
        variables: { ids: sourceIds },
      }),
    [mutate],
  );

  return { testProducts };
};
