import { RoleEnum } from '@/types/role.enum';
import { IUser } from '@/types/user.interface';

import { useCurrentUser } from '../graphql/queries/useCurrentUser';

interface IAccess {
  user?: IUser;
  isAdmin: boolean;
  loading: boolean;
}

export function useAccess(): IAccess {
  const { user, loading } = useCurrentUser();

  return {
    user,
    isAdmin: user?.role === RoleEnum.Admin,
    loading,
  };
}
