import { ReactElement, useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';

import { Text, Truncate } from './styles';

export const Message = ({ text }: { text: string }): ReactElement => {
  const [showMore, setShowMore] = useState(false);

  const readMoreEllipsis = (
    <Truncate>
      ... <Truncate onClick={() => setShowMore(!showMore)}>развернуть</Truncate>
    </Truncate>
  );

  return (
    <Text>
      {showMore ? (
        <div>
          {text}
          <Truncate onClick={() => setShowMore(!showMore)}>
            {' свернуть'}
          </Truncate>
        </div>
      ) : (
        <TruncateMarkup ellipsis={readMoreEllipsis} lines={4}>
          <div>{text}</div>
        </TruncateMarkup>
      )}
    </Text>
  );
};
