import { Checkbox, ListItemText } from '@mui/material';
import { ReactElement } from 'react';

import { IProductItem } from '@/types/productItem.interface';

import { Paper } from '../../styles';

import { DeleteSelectedButton } from './DeleteSelectedButton';
import { HideSelectedButton } from './HideSelectedButton';
import { ControlRow } from './styles';

interface ControlPanelProps {
  selectedProducts: Set<string>;
  products: IProductItem[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export const ControlPanel = ({
  selectedProducts,
  products,
  setSelectedProducts,
}: ControlPanelProps): ReactElement => {
  const handleToggleProducts = (products: IProductItem[]): void => {
    setSelectedProducts(
      selectedProducts.size === products.length
        ? new Set()
        : new Set(products.map((product) => product.id)),
    );
  };

  return (
    <Paper>
      <ControlRow>
        <Checkbox
          checked={selectedProducts.size === products?.length}
          indeterminate={
            selectedProducts.size > 0
              ? selectedProducts.size < products?.length
              : undefined
          }
          onChange={() => handleToggleProducts(products)}
        />
        <ListItemText primary="Select All" />
        <HideSelectedButton
          ids={selectedProducts}
          products={products}
          setSelectedProducts={setSelectedProducts}
        />
        <DeleteSelectedButton
          ids={[...selectedProducts]}
          setSelectedProducts={setSelectedProducts}
        />
      </ControlRow>
    </Paper>
  );
};
