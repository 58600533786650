import { Delete, QuestionAnswer } from '@mui/icons-material';
import React from 'react';

import { useToast } from '@/components/atoms/Toast/hooks';
import { useRemoveFeedback } from '@/lib/graphql/mutations/useRemoveFeedback';
import { IFeedbackItem } from '@/types/feedback.interface';

import { Information } from './components/Information';
import { Actions, Button, Feedback } from './styles';

export const FeedbackList: React.FC<{
  feedbacks?: IFeedbackItem[];
  toggleModal: (feedbackData?: IFeedbackItem) => void;
  refetch: () => void;
}> = ({ feedbacks, toggleModal, refetch }) => {
  const { removeFeedback } = useRemoveFeedback();
  const { showToast } = useToast();

  const handleDeleteClick = async (
    feedbackData: Partial<IFeedbackItem>,
  ): Promise<void> => {
    const { id } = feedbackData;
    if (!id) return;
    await removeFeedback(id);
    refetch();
    showToast('Сообщение удалено', 'success');
  };

  const handleAnswerClick = (feedbackData: IFeedbackItem): void =>
    toggleModal(feedbackData);

  return (
    <>
      {feedbacks?.map((feedback: IFeedbackItem) => (
        <Feedback key={feedback.id}>
          <Information feedback={feedback} />
          <Actions>
            <Button
              feedback={feedback}
              icon={<QuestionAnswer />}
              onClick={handleAnswerClick}
            />
            <Button
              feedback={feedback}
              icon={<Delete />}
              onClick={handleDeleteClick}
            />
          </Actions>
        </Feedback>
      ))}
    </>
  );
};
