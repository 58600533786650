import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { defaultQueryParams } from '@/lib/graphql/constants';
import { IFeedbackItem } from '@/types/feedback.interface';
import { QueryParams } from '@/types/query-params.type';

import { FEEDBACK_DTO_FRAGMENT } from '../fragments';

interface IFeedbacks {
  feedbacks?: IFeedbackItem[];
  total: number;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

interface IFeedbacksResponse {
  total: number;
  feedbacks?: {
    nodes?: IFeedbackItem[];
  };
}

export const FEEDBACKS = gql`
  ${FEEDBACK_DTO_FRAGMENT}
  query feedbacks($params: FeedbacksParamsInput) {
    feedbacks(params: $params) {
      nodes {
        ...FeedbackDTOFragment
      }
      total
    }
  }
`;

export const useFeedbacks = (params?: QueryParams): IFeedbacks => {
  const { data, loading, refetch } = useQuery<IFeedbacksResponse>(FEEDBACKS, {
    variables: { params: { ...defaultQueryParams, ...params } },
  });

  const feedbacks = data?.feedbacks?.nodes ?? [];

  const total = data?.total ?? 0;

  return { feedbacks, total, loading, refetch };
};
