import { MoreVert } from '@mui/icons-material';
import { IconButton, ListItemText, Stack } from '@mui/material';
import { ReactElement } from 'react';

import { IRequestItem } from '@/types/requests.interface';

import { ListItem } from './styles';

interface IRequestItemProps {
  request: IRequestItem;
  onOpen: () => void;
  setUserRequestId: React.Dispatch<React.SetStateAction<string>>;
}

export const RequestItem = ({
  request,
  onOpen,
  setUserRequestId,
}: IRequestItemProps): ReactElement => {
  const { id: userRequestId, users, checkedStatus, product } = request;
  const { name: productName } = product;

  const handleOpenModalWindow = (): void => {
    setUserRequestId(userRequestId);
    onOpen();
  };

  return (
    <ListItem>
      <Stack alignItems="center" direction="row" gap={2} width="100%">
        <ListItemText primary={productName} />
        <ListItemText primary={users.length} />
        <ListItemText primary={checkedStatus} />
        <IconButton onClick={handleOpenModalWindow}>
          <MoreVert />
        </IconButton>
      </Stack>
    </ListItem>
  );
};
