import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '@/components/atoms/Loader';
import { useMeasures } from '@/lib/graphql/queries/measures/useMeasures';

import { CharacteristicForm } from '../components/CharacteristicForm';

import { useCharacteristic } from './hooks/use-characteristic';
import { useUpdateCharacteristic } from './hooks/use-update-characteristic';
import { Container } from './styles';

export const UpdateCharacteristicPage: FC = () => {
  const { id = '' } = useParams();

  const { characteristic, loading } = useCharacteristic(id);
  const { updateCharacteristic } = useUpdateCharacteristic();
  const { measures } = useMeasures();

  if (loading) return <Loader />;
  if (!characteristic) return null;

  return (
    <Container>
      <CharacteristicForm
        characteristic={characteristic}
        measures={measures}
        onSubmit={updateCharacteristic}
      />
    </Container>
  );
};
