import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  IOrganization,
  UpdateOrganizationStatusInput,
} from '@/types/organization.interface';

import { ORGANIZATION_DTO_FRAGMENT } from '../fragments';

interface IUpdateOrganizationStatus {
  updateOrganizationStatus: (
    updateOrganizationInput: UpdateOrganizationStatusInput,
  ) => Promise<FetchResult<IOrganization>>;
}

export const UPDATE_ORGANIZATION_STATUS = gql`
  ${ORGANIZATION_DTO_FRAGMENT}
  mutation updateOrganizationStatus(
    $updateOrganizationStatusInput: UpdateOrganizationStatusInput!
  ) {
    updateOrganizationStatus(
      updateOrganizationStatusInput: $updateOrganizationStatusInput
    ) {
      ...OrganizationDTOFragment
    }
  }
`;

export const useUpdateOrganizationStatus = (): IUpdateOrganizationStatus => {
  const [mutate] = useMutation(UPDATE_ORGANIZATION_STATUS);

  const updateOrganizationStatus = useCallback(
    (
      updateOrganizationStatusInput: UpdateOrganizationStatusInput,
    ): Promise<FetchResult<IOrganization>> =>
      mutate({
        variables: { updateOrganizationStatusInput },
      }),
    [mutate],
  );

  return { updateOrganizationStatus };
};
