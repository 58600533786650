import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { TableCell, TableRow } from '@mui/material';
import { FC, useCallback } from 'react';

import { useRemoveInvite } from '@/lib/graphql/mutations/invites/useRemoveInvite';
import { useResendInvite } from '@/lib/graphql/mutations/invites/useResendInvite';
import { useUpdateInvite } from '@/lib/graphql/mutations/invites/useUpdateInvite';
import { IInvite } from '@/lib/graphql/queries/invites/useInvites';

interface IInviteTableRow {
  row: IInvite;
}

export const InviteTableRow: FC<IInviteTableRow> = ({
  row: { id, email, inviteCode, isActive },
}) => {
  const { updateInvite } = useUpdateInvite();
  const { removeInvite } = useRemoveInvite();
  const { resendInvite } = useResendInvite();

  const handleDelete = useCallback(
    async () => await removeInvite(id),
    [id, removeInvite],
  );
  const handleToggle = useCallback(
    async () => await updateInvite(id, !isActive),
    [id, isActive, updateInvite],
  );
  const handleResend = useCallback(
    async () => await resendInvite(id),
    [id, resendInvite],
  );

  const linkUrl = `${
    process.env.FRONT_URL ?? 'https://kuvo.info'
  }/?invite=${inviteCode}`;

  return (
    <TableRow key={id} hover role="checkbox" tabIndex={-1}>
      <TableCell>{email}</TableCell>
      <TableCell>{inviteCode}</TableCell>
      <TableCell>{linkUrl}</TableCell>
      <TableCell align="right">
        {isActive ? (
          <ToggleOnIcon
            color="success"
            sx={{ cursor: 'pointer' }}
            onClick={handleToggle}
          />
        ) : (
          <ToggleOffIcon
            color="error"
            sx={{ cursor: 'pointer' }}
            onClick={handleToggle}
          />
        )}
      </TableCell>
      <TableCell align="right">
        <ReplayIcon sx={{ cursor: 'pointer' }} onClick={handleResend} />
      </TableCell>
      <TableCell align="right">
        <DeleteIcon sx={{ cursor: 'pointer' }} onClick={handleDelete} />
      </TableCell>
    </TableRow>
  );
};
