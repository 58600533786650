import { gql, QueryResult, useQuery } from '@apollo/client';

import { ISourceItem } from '@/types/sourceItem.interface';

import { SOURCE_DTO_FRAGMENT } from '../fragments';
import { EActiveStatusSources } from '../types/sources.types';

export interface ISourcesResponseData {
  sources: ISourceItem[];
}

export interface ISourcesVariablesQuery {
  activeStatus: EActiveStatusSources;
}

interface IUseSources
  extends Omit<
    QueryResult<ISourcesResponseData, ISourcesVariablesQuery>,
    'data'
  > {
  sources?: ISourceItem[];
}

export const SOURCES = gql`
  ${SOURCE_DTO_FRAGMENT}
  query ($activeStatus: EActiveStatusSources) {
    sources(activeStatus: $activeStatus) {
      ...SourceDTOFragment
    }
  }
`;

export const useSources = (activeStatus: EActiveStatusSources): IUseSources => {
  const { data, ...otherOptions } = useQuery<
    ISourcesResponseData,
    ISourcesVariablesQuery
  >(SOURCES, { variables: { activeStatus } });

  const sources = data?.sources;

  return { sources, ...otherOptions };
};
