import { CircularProgress } from '@mui/material';
import { ReactElement } from 'react';

import { ContainerTemplate } from './styles';

export const Loader = (): ReactElement => {
  return (
    <ContainerTemplate>
      <CircularProgress />
    </ContainerTemplate>
  );
};
