import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';

import ModalWindow from '@/components/atoms/ModalWindow';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useRemoveProduct } from '@/lib/graphql/mutations/useRemoveProduct';
interface DeleteButtonProps {
  id: string;
  name?: string;
}

export const DeleteButton = (props: DeleteButtonProps): ReactElement => {
  const { id, name } = props;

  const { showToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);

  const { removeProduct, loading } = useRemoveProduct();

  const handleOpen = (): void => setIsOpen(true);
  const handleClose = (): void => setIsOpen(false);

  const handleProductDelete = async (): Promise<void> => {
    await removeProduct(id);
    setIsOpen(false);
    showToast('Продукт удален');
  };

  return (
    <>
      <IconButton aria-label="delete" edge="end" onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <ModalWindow isOpen={isOpen} onClose={handleClose}>
        <Stack p={1} spacing={4}>
          <Typography>{`Delete product ${name}?`}</Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button
              color="error"
              disabled={loading}
              variant="contained"
              onClick={handleProductDelete}
            >
              Delete
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </ModalWindow>
    </>
  );
};
