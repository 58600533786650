import { FC } from 'react';

import { StatusNames } from '@/types/moderation-status-names.enum';

import { Approved, NeedChanges, OnModeration } from './styles';

export const ModerationStatus: FC<{
  moderationStatusName: string | undefined;
}> = ({ moderationStatusName }) => {
  switch (moderationStatusName) {
    case StatusNames.moderation:
      return <OnModeration />;
    case StatusNames.approve:
      return <Approved />;
    case StatusNames.change:
      return <NeedChanges />;
    default:
      /* TODO: change grid styles */
      return <div />;
  }
};
