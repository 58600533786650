import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { FieldValidator } from 'final-form';
import { FC, MouseEvent, useState } from 'react';
import { Field } from 'react-final-form';

interface Props<T> {
  label?: string;
  name: string;
  validate?: FieldValidator<T>;
}

export const PasswordField: FC<Props<string>> = ({ label, name, validate }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
  };

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const error = Boolean(meta.error) && meta.touched;

        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel error={error}>{label ?? name}</InputLabel>
            <OutlinedInput
              {...input}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              error={error}
              label={label}
              name={name}
              type={showPassword ? 'text' : 'password'}
            />
            <FormHelperText error={error}>
              {error ? meta.error : undefined}
            </FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};
