import { Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner';
import { Loader } from '@/components/atoms/Loader';
import { SelectLocale } from '@/components/atoms/SelectLocale';
import { useToast } from '@/components/atoms/Toast/hooks';
import { CreateButton } from '@/components/pages/BannerList/components/CreateButton';
import { useRemoveBanner } from '@/lib/graphql/mutations/useRemoveBanner';
import { useUpdateBanner } from '@/lib/graphql/mutations/useUpdateBanner';
import { IBannerItem } from '@/types/banner.interface';

import { ActiveSwitcher } from './components/ActiveSwitcher';
import { DeleteButton } from './components/DeleteButton';
import { EditButton } from './components/EditButton';
import { ListEmpty } from './components/ListEmpty';
import { useBannersByLocale } from './hooks/useBannerByLocale';
import { bannerSx, ButtonStack, HeaderStack, List, ListItem } from './styles';

export const BannerList: React.FC<{
  currentLocale?: string;
}> = ({ currentLocale }) => {
  const [locale, setLocale] = useState<string>(currentLocale ?? 'ru');

  const { banners, loading, refetch } = useBannersByLocale(locale);
  const { updateBanner } = useUpdateBanner();
  const { removeBanner } = useRemoveBanner();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleChangeActive = async (bannerItem: IBannerItem): Promise<void> => {
    await updateBanner(bannerItem);
    await refetch();
  };

  const handleEdit = (id: string): void => navigate(`/banners/${id}/edit`);

  const handleDelete = async (id: string): Promise<void> => {
    await removeBanner(id);
    showToast('Баннер удален');
    await refetch();
  };

  const handleCreate = (): void => {
    navigate('/banners/create');
  };

  if (loading) return <Loader />;
  if (!loading && !banners?.length)
    return <ListEmpty selectedLocale={locale} />;

  return (
    <>
      <SelectLocale value={locale} onChangeLocale={setLocale} />
      <List dense={false}>
        {banners?.map((banner: IBannerItem) => (
          <ListItem key={banner.id} sx={{ boxShadow: 3 }}>
            <HeaderStack>
              <Typography>{banner.name}</Typography>
              <ButtonStack>
                <ActiveSwitcher banner={banner} onChange={handleChangeActive} />
                <EditButton id={banner.id} onClick={handleEdit} />
                <DeleteButton id={banner.id} onClick={handleDelete} />
              </ButtonStack>
            </HeaderStack>
            <Banner banner={banner} sx={bannerSx} />
          </ListItem>
        ))}
      </List>
      <CreateButton onClick={handleCreate} />
    </>
  );
};
