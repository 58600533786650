import { Stack } from '@mui/material';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import { Loader } from '@/components/atoms/Loader';
import ModalWindow from '@/components/atoms/ModalWindow';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useApproveRequest } from '@/lib/graphql/mutations/useApproveRequest';
import { useBindFile } from '@/lib/graphql/mutations/useBindFile';
import { useRejectRequest } from '@/lib/graphql/mutations/useRejectRequest';
import { useRemoveAttachmentFile } from '@/lib/graphql/mutations/useRemoveAttachmentFile';
import { useGetRequestById } from '@/lib/graphql/queries/useGetRequestById';
import { CheckedStatusType } from '@/types/checked-status.enum';
import { IUserRequestAttachmentFile } from '@/types/requests.interface';

import { ApprovalControls } from './components/ApprovalControls';
import { ModalHeader } from './components/ModalHeader';
import { UploadButton } from './components/UploadButton';
import { UploadedFiles } from './components/UploadedFiles';

interface IRequestModalWindow {
  userRequestId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const RequestModalWindow = ({
  userRequestId,
  isOpen,
  onClose,
}: IRequestModalWindow): ReactElement | null => {
  const {
    request,
    loading: isRequestLoading,
    refetch: refetchRequest,
  } = useGetRequestById(userRequestId);
  const { rejectUserRequest } = useRejectRequest();
  const { approveUserRequest } = useApproveRequest();
  const { bindFile, loading: isLoadingFile } = useBindFile();
  const { removeAttachmentFile } = useRemoveAttachmentFile();
  const { showToast } = useToast();

  const [files, setFiles] = useState<IUserRequestAttachmentFile[]>([]);

  useEffect(() => {
    void refetchRequest({ id: userRequestId });
  }, [refetchRequest, userRequestId]);

  useEffect(() => {
    setFiles(request?.attachmentFiles ?? []);
  }, [request]);

  if (isRequestLoading) return <Loader />;
  if (request == null) return <ModalWindow isOpen={isOpen} onClose={onClose} />;

  const isSendStatus = request.checkedStatus === CheckedStatusType.Sent;
  const isSaveButtonDisabled = isLoadingFile || files.length < 1;

  const handleUpload = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (event.target.files != null && event.target.files.length > 0) {
      const file = event.target.files[0];

      const getFileExtensionFromFileName = (
        fileName: string,
      ): string | undefined => {
        const splitFileName = fileName.split('.');
        if (splitFileName.length > 1) return splitFileName.at(-1);
      };

      const { contentType, ...bindFileArgs } = {
        contentType: file.type,
        fileName: file.name,
        extension: getFileExtensionFromFileName(file.name),
        lastModified: new Date(file.lastModified),
      };

      const badBuffer = await toBase64(file);
      const substring = `data:${contentType};base64,`;
      const buffer = badBuffer.slice(substring.length);

      const { data } = await bindFile({
        buffer,
        contentType,
        userRequestId: request?.id,
        ...bindFileArgs,
      });

      if (data?.bindFile != null) setFiles([...files, data.bindFile]);
    }
  };

  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === 'string') resolve(result);
        reject(new Error('Wrong FileReader return type'));
      };

      reader.onerror = (error) => reject(error);
    });

  const handleReject = async (): Promise<void> => {
    await rejectUserRequest(userRequestId);
    showToast('Уведомление об отсутствии отправлено');
  };

  const handleApprove = async (): Promise<void> => {
    await approveUserRequest(userRequestId);
    onClose();
    showToast('Модель отправлена пользователю');
  };

  const handleDelete = async (attachmentFileId: string): Promise<void> => {
    await removeAttachmentFile(attachmentFileId);
    setFiles((prevState) =>
      prevState.filter(
        (attachmentFile) => attachmentFile.id !== attachmentFileId,
      ),
    );
  };

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose}>
      <Stack direction="column">
        <ModalHeader request={request} onClose={onClose} />
        {!isSendStatus ? (
          <UploadButton disabled={isLoadingFile} onUpload={handleUpload} />
        ) : null}
        <UploadedFiles
          files={files}
          isRemoveFilesDisabled={isSendStatus}
          onDelete={handleDelete}
        />
        {!isSendStatus ? (
          <ApprovalControls
            isSaveButtonDisabled={isSaveButtonDisabled}
            onApproveClick={handleApprove}
            onRejectClick={handleReject}
          />
        ) : null}
      </Stack>
    </ModalWindow>
  );
};
