import CloseIcon from '@mui/icons-material/Close';
import { Button, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { IRequestItem } from '@/types/requests.interface';

import { Header, HeaderText, Stack } from './styles';

interface IModalHeaderProps {
  request: IRequestItem;
  onClose: () => void;
}

export const ModalHeader = ({
  request,
  onClose,
}: IModalHeaderProps): ReactElement => {
  const { users, checkedStatus, product } = request;
  const { id: productId, name: productName } = product;

  return (
    <Stack>
      <Header direction="row">
        <HeaderText variant="h4">Заявка</HeaderText>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </Header>
      <Stack>
        <Typography>{`ID товара: ${productId}`}</Typography>
        <Typography>{`Название товара: ${productName}`}</Typography>
        <Typography>{`Количество запросов: ${users.length}`}</Typography>
        <Typography>{`Статус: ${checkedStatus}`}</Typography>
      </Stack>
    </Stack>
  );
};
