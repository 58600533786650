import { FC, ReactElement, useCallback } from 'react';

import CommercialButton from '@/components/atoms/CommercialButton';

import { ButtonContent, DownloadFileButtonName } from './styles';

interface DownloadFileButtonProps {
  name?: string | null | ReactElement;
  fileUrl?: string;
  tdModelId?: string;
  variant?: 'contained' | 'text' | 'outlined';
  icon?: ReactElement;
  onClick: (fileUrl: string) => void;
}

export const DownloadFileButton: FC<DownloadFileButtonProps> = ({
  name,
  fileUrl,
  tdModelId,
  icon,
  variant,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick(tdModelId ?? fileUrl ?? '');
  }, [onClick, tdModelId, fileUrl]);

  return (
    <CommercialButton variant={variant} onClick={handleClick}>
      <ButtonContent>
        {icon}
        <DownloadFileButtonName>{name}</DownloadFileButtonName>
      </ButtonContent>
    </CommercialButton>
  );
};
