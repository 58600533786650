import styled from '@emotion/styled';
import { Box, Stack, Typography } from '@mui/material';

import { Colors } from '@/variables/colors';

export const BannerBox = styled(Box)<{
  backgroundColor?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 11%;
  position: relative;
  background-image: linear-gradient(
    138deg,
    ${Colors.Alabaster} 1.22%,
    ${Colors.White} 1.22%,
    ${Colors.White} 2.03%,
    ${Colors.Alabaster} 2.03%,
    ${Colors.Alabaster} 3.66%,
    ${Colors.White} 3.66%,
    ${Colors.White} 7.72%,
    ${Colors.Alabaster} 7.72%,
    ${Colors.Alabaster} 50%,
    ${Colors.Alabaster} 50%,
    ${Colors.Alabaster} 51.22%,
    ${Colors.White} 51.22%,
    ${Colors.White} 52.03%,
    ${Colors.Alabaster} 52.03%,
    ${Colors.Alabaster} 53.66%,
    ${Colors.White} 53.66%,
    ${Colors.White} 57.72%,
    ${Colors.Alabaster} 57.72%,
    ${Colors.Alabaster} 100%
  );
  background-size: 85% 100%;
`;

export const ImageBox = styled(Box)<{
  backgroundImageUrl?: string;
  backgroundColor?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  clip-path: polygon(41% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  background: no-repeat center/cover url(${(props) => props.backgroundImageUrl});
  background-color: ${(props) => props.backgroundColor};
`;

export const Title = styled(Typography)`
  text-decoration: none;
  font-family: El Messiri;
  font-style: normal;
  font-weight: bold;
  line-height: 1.27;
  letter-spacing: 0.009375em;
  max-width: 52%;
  color: ${Colors.CuttySark};
`;

export const Text = styled(Typography)`
  text-decoration: none;
  font-family: Manrope;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.009375em;
  max-width: 44%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${Colors.Emperor};
`;

export const TextStack = styled(Stack)`
  height: inherit;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
`;
