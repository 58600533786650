import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  gap: 50px;
`;

export const Description = styled.div`
  width: 100%;
  margin: 20px 0 0;
`;

export const Text = styled.div`
  padding: 10px 0 0;
  font-size: 20px;
  word-wrap: break-word;
  text-align: justify;
`;

export const BoldText = styled.span`
  font-size: 20px;
  font-weight: bold;
`;
