import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface IUseRemoveOrganization {
  removeOrganization: (id: string) => Promise<FetchResult<boolean>>;
  loading: boolean;
}

export const REMOVE_ORGANIZATION = gql`
  mutation removeOrganization($id: String!) {
    removeOrganization(id: $id)
  }
`;

export const useRemoveOrganization = (): IUseRemoveOrganization => {
  const [mutate, { loading }] = useMutation<boolean>(REMOVE_ORGANIZATION);

  const removeOrganization = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: {
          id,
        },
      }),
    [mutate],
  );

  return { removeOrganization, loading };
};
