import { TableHead, TableRow } from '@mui/material';
import { ReactElement } from 'react';

import { tableHeadColumns } from './constants';
import { HeadTableCell } from './styles';

export const OrganizationsTableHead = (): ReactElement => {
  return (
    <TableHead>
      <TableRow>
        {tableHeadColumns.map((column) => (
          <HeadTableCell key={column.label} align={column.align}>
            {column.label}
          </HeadTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
