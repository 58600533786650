import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const Justify = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 70px;
  width: 70px;
`;
