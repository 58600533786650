export type ValidationResult = string | undefined;

export type Validator = (value: string) => ValidationResult;

export function composeValidators(
  ...validators: Array<Validator | undefined>
): Validator {
  return (value: string) =>
    validators.reduce(
      (error: ValidationResult, validator?: Validator) =>
        error ?? validator?.(value),
      undefined,
    );
}

export function required(value: string): ValidationResult {
  return value ? undefined : 'Required';
}

const regex =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const re = new RegExp(regex);

export const isEmail = (value: string): string | undefined => {
  return re.test(value) ? undefined : 'Invalid email';
};

export function checkLinkToImage(value: string): ValidationResult {
  const matchValue = value.match(
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)\.(jpg|jpeg|png|gif|svg|webp)$/gi,
  );
  return matchValue ? undefined : 'The link should point to the picture';
}

export function checkLocalLink(value: string): ValidationResult {
  return value.match(/^\//) ? undefined : 'Must start with the "/" character';
}
