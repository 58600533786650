import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const ButtonContent = styled(Typography)`
  display: flex;
  align-items: center;
  word-break: break-word;

  svg {
    margin-right: 10px;
    min-width: 24px;
    min-height: 24px;
  }
`;

export const DownloadFileButtonName = styled.span`
  margin: 0;
  font-size: 20px;
  word-wrap: break-word;
`;
