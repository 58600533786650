import { Stack } from '@mui/material';
import { ReactElement, useState } from 'react';

import { ICategoryItem } from '@/lib/graphql/queries/categories/useCategories';

import useCategoryActions from '../../hooks/useCategoryActions';
import TreeView from '../TreeView';

import { ArrowDownIcon, ArrowUpIcon, Text } from './styles';

interface IChildCategoriesProps {
  nodes: ICategoryItem[];
}

const ChildCategories = ({ nodes }: IChildCategoriesProps): ReactElement => {
  const [isCategoriesShown, setIsCategoriesShown] = useState<boolean>(false);
  const toggleChildCategoriesVisibility = (): void =>
    setIsCategoriesShown(!isCategoriesShown);
  const { handleDelete, handleEdit } = useCategoryActions();

  return (
    <Stack>
      <Stack direction="row">
        <Text onClick={toggleChildCategoriesVisibility}>Child categories</Text>
        {isCategoriesShown ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </Stack>
      {isCategoriesShown ? (
        <TreeView
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          nodes={nodes}
        />
      ) : null}
    </Stack>
  );
};

export default ChildCategories;
