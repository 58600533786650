import { Chip, Paper, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { useScraperTasks } from '@/lib/graphql/queries/useScraperTasks';

interface ScrapeHistoryProps {
  sourceId: number;
}

export default function ScrapeHistory(props: ScrapeHistoryProps): ReactElement {
  const { sourceId } = props;

  const { scraperTasks } = useScraperTasks({ page: 1, perPage: 30, sourceId });

  return (
    <Paper>
      <Stack gap={3} p={3}>
        {scraperTasks.map((task) => (
          <Stack
            key={task.runId}
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Stack
              alignItems="center"
              direction="row"
              gap={3}
              justifyContent="space-between"
            >
              <Chip
                color={task.mode === 'dev' ? 'info' : 'warning'}
                label={task.mode.toUpperCase()}
                variant="outlined"
              />
              <Chip
                color={task.inProgress ? 'secondary' : 'success'}
                label={task.inProgress ? 'In progress...' : 'Completed'}
              />
              <Typography variant="body2">{task.runId}</Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              gap={3}
              justifyContent="space-between"
            >
              <Typography variant="caption">by: {task.user.email}</Typography>
              <Typography variant="caption">
                {new Date(task.createdAt).toLocaleString('ru', {
                  dateStyle: 'long',
                  timeStyle: 'medium',
                })}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
}
