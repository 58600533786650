import { gql, useQuery } from '@apollo/client';

import { CATEGORY_DTO_FRAGMENT } from '../../fragments';

import { ICategory, ICategoryItem } from './useCategories';

export const PARENT_CATEGORIES = gql`
  ${CATEGORY_DTO_FRAGMENT}
  query getCategoriesForAdminPanelForm {
    getCategoriesForAdminPanelForm {
      ...CategoryDTOFragment
    }
  }
`;

interface IParentCategoryResponse {
  getCategoriesForAdminPanelForm: ICategoryItem[];
}

export const useParentCategories = (): ICategory => {
  const { data, loading, refetch } =
    useQuery<IParentCategoryResponse>(PARENT_CATEGORIES);

  const categories = data?.getCategoriesForAdminPanelForm ?? [];

  return { categories, loading, refetch };
};
