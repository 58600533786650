import { FC } from 'react';

import { DownloadFileButton } from '@/components/molecules/DownloadFileButton';
import { ITdModel } from '@/types/td-model.interface';

import { Modal } from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onFileDownload: (fileUrl: string) => void;
  tdModels: ITdModel[] | null | undefined;
}

export const TdModelsModal: FC<Props> = ({
  isOpen,
  onClose,
  onFileDownload,
  tdModels,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <>
        {tdModels?.map((tdModel) => (
          <DownloadFileButton
            key={tdModel.id}
            name={tdModel.name}
            tdModelId={tdModel.id}
            onClick={onFileDownload}
          />
        ))}
      </>
    </Modal>
  );
};
