import { ReactElement } from 'react';

import { IOrganization } from '@/types/organization.interface';

import { ActivityStatus } from './components/ActivityStatus';
import { InfoField } from './components/InfoField';
import { OrganizationImage } from './components/OrganizationImage';
import { contactNameByType } from './constants';
import { GridTable, OrganizationContainer } from './styles';

interface OrganizationProps {
  organization: IOrganization;
}

export const Organization = ({
  organization,
}: OrganizationProps): ReactElement => {
  const {
    name,
    email,
    isActive,
    taxpayerIdentificationNumber,
    country,
    city,
    address,
    contactName,
    organizationLogo,
    contacts,
  } = organization;

  return (
    <OrganizationContainer>
      <OrganizationImage imageUrl={organizationLogo?.url} />
      <ActivityStatus isActive={isActive} />
      <GridTable>
        <div>
          <InfoField title="Название" value={name} />
          <InfoField title="Email" value={email} />
          <InfoField title="ИНН" value={taxpayerIdentificationNumber} />
        </div>
        <div>
          <InfoField title="Страна" value={country} />
          <InfoField title="Город" value={city} />
          <InfoField title="Адрес" value={address} />
        </div>
        <div>
          <InfoField title="Контактное лицо" value={contactName} />
          {contacts != null && contacts?.length !== 0
            ? contacts.map((contact) => (
                <InfoField
                  key={contact.id}
                  title={contactNameByType[contact.socialType]}
                  value={contact.identifier}
                />
              ))
            : null}
        </div>
      </GridTable>
    </OrganizationContainer>
  );
};
