import styled from '@emotion/styled';
import { Stack, SxProps, Typography } from '@mui/material';

export const bannerSx: Record<'text' | 'title', SxProps> = {
  title: { margin: '35px 0 25px 40px', fontSize: 'calc(100vw * 0.023)' },
  text: { margin: '0 0 0 40px', fontSize: 'calc(100vw * 0.014)' },
};

export const Title = styled(Typography)``;

export const ButtonStack = styled(Stack)`
  margin: 20px 0 0 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
`;

export const HeaderStack = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
