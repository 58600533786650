import { FC } from 'react';

import { useMeasures } from '@/lib/graphql/queries/measures/useMeasures';
import { ICharacteristicItem } from '@/types/characteristic.interface';

import { CharacteristicForm } from '../components/CharacteristicForm';

import { useCreateCharacteristic } from './hooks';
import { Container } from './styles';

const defaultCharacteristic: ICharacteristicItem = {
  id: '',
  name: '',
  measures: [],
};

export const CreateCharacteristicPage: FC = () => {
  const { createCharacteristic } = useCreateCharacteristic();
  const { measures } = useMeasures();

  return (
    <Container>
      <CharacteristicForm
        characteristic={defaultCharacteristic}
        measures={measures}
        onSubmit={createCharacteristic}
      />
    </Container>
  );
};
