import { FC } from 'react';

import CommercialButton from '@/components/atoms/CommercialButton';

import { Text } from '../../styles';

import { ButtonContent } from './styles';

interface TdModelsButtonProps {
  isDownload: boolean;
  onClick: () => void;
}

const TdModelsButton: FC<TdModelsButtonProps> = ({ isDownload, onClick }) => {
  return (
    <CommercialButton onClick={onClick}>
      <ButtonContent>
        <Text>Download model</Text>
      </ButtonContent>
    </CommercialButton>
  );
};

export default TdModelsButton;
