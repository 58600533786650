export const enum SocialNetworkType {
  WhatsApp = 'whats_app',
  Telegram = 'telegram',
  VK = 'vk',
  YouTube = 'you_tube',
  Pinterest = 'pinterest',
  Instagram = 'instagram',
  Facebook = 'facebook',
  Dzen = 'dzen',
}

export enum SourceEnum {
  Laparet,
}
