import { IconButton } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { IFeedbackItem } from '@/types/feedback.interface';

type ButtonProps = {
  feedback: IFeedbackItem;
  onClick: (feedbackData: IFeedbackItem) => void;
  icon: ReactNode;
  className?: string;
};

export const Button = ({
  feedback,
  onClick,
  icon,
  className,
}: ButtonProps): ReactElement => {
  const handleClick = (): void => onClick(feedback);

  return (
    <IconButton className={className} onClick={handleClick}>
      {icon}
    </IconButton>
  );
};
