import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { ISourceItem } from '@/types/sourceItem.interface';

import { SOURCE_DTO_FRAGMENT } from '../fragments';

interface IUpdateSource {
  updateSource: (source: ISourceItem) => Promise<FetchResult<ISourceItem>>;
}

export const UPDATE_SOURCE = gql`
  ${SOURCE_DTO_FRAGMENT}
  mutation updateSource(
    $id: Int!
    $isActive: Boolean!
    $name: String!
    $url: String!
  ) {
    updateSource(
      updateSourceInput: {
        id: $id
        isActive: $isActive
        name: $name
        url: $url
      }
    ) {
      ...SourceDTOFragment
    }
  }
`;

export const useUpdateSource = (): IUpdateSource => {
  const [mutate] = useMutation(UPDATE_SOURCE);

  const updateSource = useCallback(
    (source: ISourceItem): Promise<FetchResult<ISourceItem>> =>
      mutate({
        variables: {
          id: source.id,
          isActive: source.isActive,
          name: source.name,
          url: source.url,
        },
      }),
    [mutate],
  );

  return { updateSource };
};
