import { gql, QueryResult, useQuery } from '@apollo/client';

import { IRequestItem } from '@/types/requests.interface';

import { USER_REQUESTS_DTO_FRAGMENT } from '../fragments';

interface IGetRequestByIdResponse {
  getRequestById?: IRequestItem;
}

interface IGetRequestByIdVariables {
  id: string;
}

interface IUseGetRequestById
  extends Omit<
    QueryResult<IGetRequestByIdResponse, IGetRequestByIdVariables>,
    'data'
  > {
  request?: IRequestItem;
}

export const GET_REQUEST_BY_ID = gql`
  ${USER_REQUESTS_DTO_FRAGMENT}

  query getRequestById($id: String!) {
    getRequestById(id: $id) {
      ...UserRequestDTOFragment
    }
  }
`;

export const useGetRequestById = (id: string): IUseGetRequestById => {
  const { data, ...otherOptions } = useQuery<
    IGetRequestByIdResponse,
    IGetRequestByIdVariables
  >(GET_REQUEST_BY_ID, {
    variables: { id },
  });

  const request = data?.getRequestById;

  return { request, ...otherOptions };
};
