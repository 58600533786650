import { MenuItem } from '@mui/material';
import { FC } from 'react';
import { Field } from 'react-final-form';

import { TextFormField } from '@/components/atoms/TextFormField/input';
import {
  checkLinkToImage,
  checkLocalLink,
  composeValidators,
  required,
} from '@/constants/validate';

import { SelectInput } from './components';
import { FieldStack } from './styles';

export const BannerFormFields: FC = () => {
  return (
    <FieldStack>
      <TextFormField label="Name" name="name" validate={required} />
      <TextFormField label="Title" name="title" validate={required} />
      <TextFormField label="Text" name="text" />
      <TextFormField
        label="Background image url"
        name="backgroundImageUrl"
        validate={composeValidators(required, checkLinkToImage)}
      />
      <TextFormField
        label="Link for button"
        name="goToUrl"
        validate={composeValidators(required, checkLocalLink)}
      />
      <Field<string>
        component={SelectInput}
        label="Locale"
        name="locale"
        validate={required}
      >
        <MenuItem value="ru">Russian</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </Field>
    </FieldStack>
  );
};
