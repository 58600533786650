import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '@/components/atoms/Loader';
import { ListEmpty } from '@/components/molecules/ListEmpty';
import { useTestResult } from '@/lib/graphql/queries/test-results/useTestResult';
import { ITestResultItem } from '@/lib/graphql/queries/test-results/useTestResults';
import { formattedDate } from '@/utils/date';

import { TestResultAccordion } from '../components/TestResultAccordion';
import { TestResultItem } from '../components/TestResultItem';

import { Paper, TextElement } from './styles';

export type IGroupedTestResult = Record<string, ITestResultItem[]>;

// eslint-disable-next-line @typescript-eslint/ban-types
const groupedItems = (items: ITestResultItem[]): IGroupedTestResult | {} => {
  if (items == null) return {};

  return items.reduce((grouped: IGroupedTestResult, item) => {
    const { productName, ...restData } = item;
    grouped[productName] = grouped[productName] || [];
    grouped[productName].push({ ...restData, productName });
    return grouped;
  }, {});
};

export const TestResultPage: FC = () => {
  const { id } = useParams();
  const { testResult, loading } = useTestResult(id);

  if (loading) return <Loader />;
  if (!testResult) return <ListEmpty />;

  return (
    <Paper>
      <TextElement>{formattedDate(testResult.createdAt)}</TextElement>
      {testResult.testedSources.length > 0 ? (
        testResult.testedSources.map((testedSource) => {
          return (
            <TestResultAccordion
              key={testedSource.id}
              title={testedSource.source.name}
            >
              {Object.entries(groupedItems(testedSource.items)).map(
                (item, index) => {
                  return (
                    <TestResultAccordion key={index} title={item[0].toString()}>
                      <TestResultItem testResult={item[1]} />
                    </TestResultAccordion>
                  );
                },
              )}
            </TestResultAccordion>
          );
        })
      ) : (
        <ListEmpty />
      )}
    </Paper>
  );
};
