import { FetchResult, gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IRequestItem } from '@/types/requests.interface';

import { USER_REQUESTS_DTO_FRAGMENT } from '../fragments';
import { GET_ALL_REQUESTS } from '../queries/useGetAllRequests';

interface IApproveRequestResponseData {
  approveUserRequest: IRequestItem;
}

interface IApproveRequestVariables {
  userRequestId: string;
}

interface IApproveRequest extends MutationResult<IApproveRequestResponseData> {
  approveUserRequest: (
    userRequestId: string,
  ) => Promise<FetchResult<IApproveRequestResponseData>>;
}

export const APPROVE_REQUEST = gql`
  ${USER_REQUESTS_DTO_FRAGMENT}

  mutation approveUserRequest($userRequestId: String!) {
    approveUserRequest(id: $userRequestId) {
      ...UserRequestDTOFragment
    }
  }
`;

export const useApproveRequest = (): IApproveRequest => {
  const [mutate, otherOptions] = useMutation<
    IApproveRequestResponseData,
    IApproveRequestVariables
  >(APPROVE_REQUEST, {
    refetchQueries: [GET_ALL_REQUESTS],
  });

  const approveUserRequest = useCallback(
    (
      userRequestId: string,
    ): Promise<FetchResult<IApproveRequestResponseData>> =>
      mutate({
        variables: { userRequestId },
      }),
    [mutate],
  );

  return { approveUserRequest, ...otherOptions };
};
