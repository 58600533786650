import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { FC, ReactNode } from 'react';

import { TextElement } from './styles';

interface ITestResultAccordionProps {
  title: string;
  children: ReactNode;
}

export const TestResultAccordion: FC<ITestResultAccordionProps> = ({
  title,
  children,
}) => {
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
      >
        <TextElement>{title}</TextElement>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
