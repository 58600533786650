import { List } from '@mui/material';
import { FC, Fragment } from 'react';

import { Loader } from '@/components/atoms/Loader';
import { ListEmpty } from '@/components/molecules/ListEmpty';
import { useTestResults } from '@/lib/graphql/queries/test-results/useTestResults';

import { StickyHeader } from '../components/StickyHeader';
import { TestResultTitle } from '../components/TestResultTitle';

import { Paper } from './styles';

export const TestResultsPage: FC = () => {
  const { testResults, loading, refetch } = useTestResults();

  if (loading) return <Loader />;

  return (
    <>
      <StickyHeader refetch={refetch} />
      <Paper>
        <List dense={false}>
          {testResults.length > 0 ? (
            testResults.map((testResult, index) => (
              <Fragment key={index}>
                <TestResultTitle testResult={testResult} />
              </Fragment>
            ))
          ) : (
            <ListEmpty />
          )}
        </List>
      </Paper>
    </>
  );
};
