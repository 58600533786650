import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IFeedbackItem } from '@/types/feedback.interface';

interface IUseSendAnswerToFeedbackMutationResult {
  sendAnswerToFeedback: { success: boolean };
}

interface IUseSendAnswerToFeedback {
  sendAnswerToFeedback: (
    text: string,
    locale: string,
  ) => Promise<FetchResult<IUseSendAnswerToFeedbackMutationResult>>;
}

export const SEND_ANSWER_TO_FEEDBACK = gql`
  mutation sendAnswerToFeedback($data: SendAnswerToFeedbackInput!) {
    sendAnswerToFeedback(data: $data) {
      success
    }
  }
`;

export const useSendAnswerToFeedback = (
  feedbackData: IFeedbackItem,
): IUseSendAnswerToFeedback => {
  const [mutate] = useMutation<
    IUseSendAnswerToFeedbackMutationResult,
    { data: Partial<IFeedbackItem> }
  >(SEND_ANSWER_TO_FEEDBACK);

  const { email, createdAt, name } = feedbackData;

  const sendAnswerToFeedback = useCallback(
    (
      text: string,
      locale: string,
    ): Promise<FetchResult<IUseSendAnswerToFeedbackMutationResult>> => {
      return mutate({
        variables: {
          data: {
            email,
            text,
            name,
            createdAt,
            locale,
          },
        },
      });
    },
    [createdAt, email, mutate, name],
  );

  return { sendAnswerToFeedback };
};
