import Button from '@mui/material/Button';
import { CSSProperties, FC } from 'react';

interface ILoginbButton {
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const LoginButton: FC<ILoginbButton> = (props: ILoginbButton) => {
  return (
    <Button variant="contained" {...props}>
      Login
    </Button>
  );
};
