import styled from '@emotion/styled';
import { Stack as BaseStack, Typography } from '@mui/material';

export const Header = styled(BaseStack)`
  display: flex;
  justify-content: space-between;
`;

export const Stack = styled(BaseStack)`
  margin: 15px;
`;

export const HeaderText = styled(Typography)`
  flex: 1;
  text-align: center;
`;
