import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ITestResultResponseItem } from '@/lib/graphql/queries/test-results/useTestResults';
import { formattedDate } from '@/utils/date';

import { TextElement } from './styles';

interface TestResultTitleProps {
  testResult: ITestResultResponseItem;
}

export const TestResultTitle: FC<TestResultTitleProps> = ({ testResult }) => {
  const [currentTestResult] = useState(testResult);
  const navigate = useNavigate();
  const handleNavigateToResult = useCallback(
    () => navigate(`/test-result/${currentTestResult.id}`),
    [currentTestResult.id, navigate],
  );

  return (
    <TextElement onClick={handleNavigateToResult}>
      {formattedDate(testResult.createdAt)}
    </TextElement>
  );
};
