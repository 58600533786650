import styled from '@emotion/styled';
import TextFieldInput from '@mui/material/TextField';

export const Container = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;

export const TextField = styled(TextFieldInput)`
  width: 250px;
  text-align: center;
`;
