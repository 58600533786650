import { Alert } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SelectLocale } from '@/components/atoms/SelectLocale';

import { BannerList } from '../..';
import { useBannersByLocale } from '../../hooks/useBannerByLocale';
import { CreateButton } from '../CreateButton';

import { ContainerTemplate } from './styles';

export const ListEmpty: React.FC<{ selectedLocale: string }> = ({
  selectedLocale,
}) => {
  const [locale, setLocale] = useState<string>(selectedLocale);
  const navigate = useNavigate();
  const { banners } = useBannersByLocale(locale);

  const handleCreate = (): void => {
    navigate('/banners/create');
  };

  if (banners?.length) return <BannerList currentLocale={locale} />;

  return (
    <>
      <SelectLocale value={locale} onChangeLocale={setLocale} />
      <ContainerTemplate>
        <Alert severity="info">This list is empty!</Alert>
        <CreateButton onClick={handleCreate} />
      </ContainerTemplate>
    </>
  );
};
