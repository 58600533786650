import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { ReactElement } from 'react';

interface IDeleteButton {
  id: string;
  onClick: (id: string) => void;
}

export const DeleteButton = ({ id, onClick }: IDeleteButton): ReactElement => {
  const handleChange = (): void => {
    onClick(id);
  };

  return (
    <IconButton onClick={handleChange}>
      <DeleteIcon />
    </IconButton>
  );
};
