import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { moderationStatuses } from '@/types/moderation-status-names.enum';

import { ISaveProductForm } from '../../types';

import { FormControl, Stack } from './styles';

export const ProductCardStatus: FC = () => {
  const { change } = useForm();
  const { values } = useFormState<ISaveProductForm>();

  const selectedStatus = values?.moderationStatus?.name ?? '';
  const selectedDescription = values?.moderationStatus?.description ?? '';

  const handleChangeSelect = (event: SelectChangeEvent): void => {
    change('moderationStatus.name', event.target.value);

    if (event.target.value !== 'change')
      change('moderationStatus.description', '');
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    change('moderationStatus.description', event.target.value);
  };

  return (
    <Stack>
      <FormControl>
        <InputLabel id="card-status-select">Card status</InputLabel>
        <Select
          label="Card status"
          value={selectedStatus}
          onChange={handleChangeSelect}
        >
          {Object.entries(moderationStatuses).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        disabled={values?.moderationStatus?.name !== 'change'}
        label="Status description"
        value={selectedDescription}
        onChange={handleDescriptionChange}
      />
    </Stack>
  );
};
