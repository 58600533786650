import { Stack } from '@mui/material';
import { ReactElement } from 'react';

import { IUserRequestAttachmentFile } from '@/types/requests.interface';

import { UploadedFile } from './components/UploadedFile';

interface IUploadedFiles {
  files: IUserRequestAttachmentFile[];
  onDelete: (id: string) => Promise<void>;
  isRemoveFilesDisabled: boolean;
}

export const UploadedFiles = ({
  files = [],
  onDelete,
  isRemoveFilesDisabled,
}: IUploadedFiles): ReactElement => {
  return (
    <Stack>
      {files.map((file) => (
        <UploadedFile
          {...file}
          key={file.fileEtag}
          disabled={isRemoveFilesDisabled}
          onDelete={onDelete}
        />
      ))}
    </Stack>
  );
};
