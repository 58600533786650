import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { IOrganization } from '@/types/organization.interface';

interface OrganizationQueryResult {
  organization?: IOrganization;
}

interface IOrganizationItem {
  organization?: IOrganization;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<OrganizationQueryResult>>;
}

export const ORGANIZATION = gql`
  query organization($id: String!) {
    organization(id: $id) {
      id
      name
      email
      isActive
      taxpayerIdentificationNumber
      country
      city
      address
      contactName
      contacts {
        id
        socialType
        identifier
      }
      organizationLogo {
        id
        name
        url
      }
    }
  }
`;

export const useOrganization = (id?: string): IOrganizationItem => {
  const { data, loading, refetch } = useQuery<OrganizationQueryResult>(
    ORGANIZATION,
    {
      variables: { id },
    },
  );

  const organization = data?.organization;

  return { organization, loading, refetch };
};
