import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { ScrapeMode } from '@/types/scrapeMode.enum';
import { SourceEnum } from '@/types/source.enum';

interface IStartScraping {
  startScraping: (
    startScrapingInput: IStartScrapingInput,
  ) => Promise<FetchResult<string>>;
}

export interface IStartScrapingInput {
  sourceId: SourceEnum;
  mode: ScrapeMode;
}

export const START_SCRAPING = gql`
  mutation startScraping($sourceId: Int!, $mode: String!) {
    startScraping(startScrapingInput: { sourceId: $sourceId, mode: $mode })
  }
`;

export const useStartScraping = (): IStartScraping => {
  const [mutate] = useMutation(START_SCRAPING);

  const startScraping = useCallback(
    (startScrapingInput: IStartScrapingInput): Promise<FetchResult<string>> =>
      mutate({
        variables: startScrapingInput,
      }),
    [mutate],
  );

  return { startScraping };
};
