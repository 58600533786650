import { Table, TableBody, TableContainer } from '@mui/material';
import { FC } from 'react';

import { Loader } from '@/components/atoms/Loader';
import { Pagination } from '@/components/atoms/Pagination';
import { ListEmpty } from '@/components/molecules/ListEmpty';
import { DEFAULT_INITIAL_PAGE_NUMBER } from '@/constants/constants';
import { useOrganizations } from '@/lib/graphql/queries/useOrganizations';
import useQueryState from '@/lib/hooks/useQueryState';
import * as scheme from '@/lib/utils/io-ts/codec';

import { OrganizationRow } from './components/OrganizationRow';
import { OrganizationsTableHead } from './components/OrganizationsTableHead';
import { Paper } from './styles';

export const OrganizationList: FC = () => {
  const [page, setPage] = useQueryState('page', scheme.numericString, {
    defaultValue: DEFAULT_INITIAL_PAGE_NUMBER,
  });

  const { organizations, total, loading, refetch } = useOrganizations({
    page,
  });

  if (loading) return <Loader />;
  if (!loading && !organizations?.length) return <ListEmpty />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <OrganizationsTableHead />
          <TableBody>
            {organizations.map((organization) => (
              <OrganizationRow
                key={organization.id}
                organization={organization}
                refetch={refetch}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination page={page} total={total} onChange={setPage} />
    </>
  );
};
