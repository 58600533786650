import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { CATEGORY_DTO_FRAGMENT } from '../../fragments';
import { ICategoryItem } from '../../queries/categories/useCategories';

type IPickCategoryItemProps = 'id' | 'name' | 'keywords' | 'parentCategoryId';

export interface IUpdateCategoryItem
  extends Pick<ICategoryItem, IPickCategoryItemProps> {}

interface IUpdateCategory {
  updateCategory: (
    category: IUpdateCategoryItem,
  ) => Promise<FetchResult<ICategoryItem>>;
}

export const UPDATE_CATEGORY = gql`
  ${CATEGORY_DTO_FRAGMENT}
  mutation updateCategory($updateCategoryInput: UpdateCategoryInput!) {
    updateCategory(updateCategoryInput: $updateCategoryInput) {
      ...CategoryDTOFragment
    }
  }
`;

export const useUpdateCategory = (): IUpdateCategory => {
  const [mutate] = useMutation(UPDATE_CATEGORY);

  const updateCategory = useCallback(
    (category: IUpdateCategoryItem): Promise<FetchResult<ICategoryItem>> =>
      mutate({
        variables: {
          updateCategoryInput: {
            id: category.id,
            name: category.name,
            keywords: category.keywords ?? '',
            parentCategoryId: category.parentCategoryId,
          },
        },
      }),
    [mutate],
  );

  return { updateCategory };
};
