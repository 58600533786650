import { List } from '@mui/material';
import { useState } from 'react';

import { Loader } from '@/components/atoms/Loader';
import { ListEmpty } from '@/components/molecules/ListEmpty';
import { useGetAllRequests } from '@/lib/graphql/queries/useGetAllRequests';

import { RequestItem } from './components/RequestItem';
import { RequestModalWindow } from './components/RequestModalWindow';
import { Paper } from './styles';

export const RequestsPage: React.FC = () => {
  const { requests, loading, error } = useGetAllRequests();

  const [isOpen, setIsOpen] = useState(false);
  const [userRequestId, setUserRequestId] = useState<string>('');

  if (loading) return <Loader />;
  if (error) return <span>{error.message}</span>;

  const isRequestListEmpty = requests != null && requests.length > 0;

  const handleOpen = (): void => setIsOpen(true);
  const handleClose = (): void => setIsOpen(false);

  return (
    <Paper>
      <List dense={false}>
        {isRequestListEmpty ? (
          requests?.map((request) => (
            <RequestItem
              key={request?.id}
              request={request}
              setUserRequestId={setUserRequestId}
              onOpen={handleOpen}
            />
          ))
        ) : (
          <ListEmpty />
        )}

        {userRequestId == null || userRequestId === '' ? null : (
          <RequestModalWindow
            isOpen={isOpen}
            userRequestId={userRequestId}
            onClose={handleClose}
          />
        )}
      </List>
    </Paper>
  );
};
