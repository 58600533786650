import {
  CheckCircle,
  DataUsage,
  Error,
  NightsStay,
  SvgIconComponent,
} from '@mui/icons-material';
import { Chip } from '@mui/material';
import { ReactElement } from 'react';

import { ScrapingStatus } from '@/types/sourceItem.interface';

interface StatusLabelProps {
  status: ScrapingStatus;
}

const icons: Record<ScrapingStatus, SvgIconComponent | null> = {
  [ScrapingStatus.Completed]: CheckCircle,
  [ScrapingStatus.InProgress]: DataUsage,
  [ScrapingStatus.Failed]: Error,
  [ScrapingStatus.Sleeping]: NightsStay,
};

const labels: Record<ScrapingStatus, string> = {
  [ScrapingStatus.Completed]: 'Completed',
  [ScrapingStatus.InProgress]: 'In progress',
  [ScrapingStatus.Failed]: 'Failed',
  [ScrapingStatus.Sleeping]: 'Sleeping',
};

const colors: Record<ScrapingStatus, 'success' | 'info' | 'error' | 'primary'> =
  {
    [ScrapingStatus.Completed]: 'success',
    [ScrapingStatus.InProgress]: 'info',
    [ScrapingStatus.Failed]: 'error',
    [ScrapingStatus.Sleeping]: 'primary',
  };

export default function StatusLabel(props: StatusLabelProps): ReactElement {
  const { status } = props;

  const Icon = icons[status];

  return (
    <Chip
      color={colors[status]}
      icon={Icon ? <Icon fontSize="small" /> : undefined}
      label={labels[status]}
      sx={{ pl: 1 }}
      variant={status === ScrapingStatus.Sleeping ? 'outlined' : 'filled'}
    />
  );
}
