import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { List } from '@mui/material';
import { FC } from 'react';

import { ITestResultItem } from '@/lib/graphql/queries/test-results/useTestResults';

import { ListItem } from './styles';

interface TestResultItemProps {
  testResult: Array<Partial<ITestResultItem>>;
}

export const TestResultItem: FC<TestResultItemProps> = ({ testResult }) => {
  return (
    <>
      {testResult.map((item, index) => {
        const { name, result } = item;
        return (
          <List key={index}>
            <ListItem>
              <p>{name}</p>
              <p>
                {result ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
              </p>
            </ListItem>
          </List>
        );
      })}
    </>
  );
};
