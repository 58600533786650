import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CloseButton } from '@/components/atoms/buttons/CloseButton';
import { Loader } from '@/components/atoms/Loader';
import { useOrganization } from '@/lib/graphql/queries/useOrganization';

import { ActivateButton } from './components/ActivateButton';
import { Organization } from './components/Organization';
import { Container } from './styles';

export const OrganizationPage: FC = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { organization, loading } = useOrganization(id);

  if (loading) return <Loader />;
  if (!organization || !id) return null;

  const handleClose = (): void => navigate('/organizations');

  return (
    <>
      <Organization organization={organization} />
      <Container>
        <ActivateButton
          id={id}
          isActive={organization.isActive}
          name={organization.name}
        />
        <CloseButton onClick={handleClose} />
      </Container>
    </>
  );
};
