import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { IBannerItem } from '@/types/banner.interface';

import { BANNER_DTO_FRAGMENT } from '../fragments';

interface IBanner {
  banner?: IBannerItem;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const BANNER = gql`
  ${BANNER_DTO_FRAGMENT}
  query banner($id: String!) {
    banner(id: $id) {
      ...BannerDTOFragment
    }
  }
`;

export const useBanner = (id: string | undefined): IBanner => {
  const { data, loading, refetch } = useQuery<IBanner>(BANNER, {
    variables: { id },
  });

  const banner = data?.banner;

  return { banner, loading, refetch };
};
