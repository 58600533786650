import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { FC } from 'react';

import { ProductVisibleSortOrderField } from '@/lib/graphql/types/product-fields.enum';

import { muiFieldStyles } from '../../styles';

import { productVisibleSortOptions } from './const';

interface StatusInputProps {
  name: string;
  visibilityStatus: ProductVisibleSortOrderField;
  onChange: (event: SelectChangeEvent<ProductVisibleSortOrderField>) => void;
}

export const StatusInput: FC<StatusInputProps> = ({
  name,
  visibilityStatus,
  onChange,
}) => {
  return (
    <FormControl sx={muiFieldStyles}>
      <InputLabel>Active status</InputLabel>
      <Select<ProductVisibleSortOrderField>
        label="Active status"
        name={name}
        value={visibilityStatus}
        onChange={onChange}
      >
        {productVisibleSortOptions.map(({ value, label }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
