import { FC } from 'react';

import { IImage } from '@/types/image.interface';

import {
  Container,
  EmptyImage,
  MainImage,
  RestImagesWrapper,
  SmallImage,
} from './styles';

interface ProductCardAppearanceImages {
  images?: IImage[];
}

export const ImageComponent: FC<ProductCardAppearanceImages> = ({ images }) => {
  if (!images || images.length === 0)
    return <EmptyImage>Images are not loaded</EmptyImage>;

  const [mainImage, ...restImages] = images;

  return (
    <Container>
      <MainImage src={mainImage.file.url} />
      <RestImagesWrapper>
        {restImages.map((item) => (
          <SmallImage key={item.id} src={item.file.url} />
        ))}
      </RestImagesWrapper>
    </Container>
  );
};
