import { ApolloQueryResult } from '@apollo/client';
import { Refresh, Stop } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

import { useStopScrapingAllSources } from '@/lib/graphql/mutations/useStopScrapingAllSources';
import {
  ISourcesResponseData,
  ISourcesVariablesQuery,
} from '@/lib/graphql/queries/useSources';
import { EActiveStatusSources } from '@/lib/graphql/types/sources.types';

import { Container, TextField } from './styles';

interface IAuxiliaryMenu {
  refetchSources: (
    variables?: Partial<ISourcesVariablesQuery> | undefined,
  ) => Promise<ApolloQueryResult<ISourcesResponseData>>;
  handleSearchBySourcesName: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

export const AuxiliaryMenu = ({
  refetchSources,
  handleSearchBySourcesName,
}: IAuxiliaryMenu): ReactElement => {
  const [scraperStatusFilter, setScraperStatusFilter] = useState(
    EActiveStatusSources.active,
  );
  const { stopScrapingAllSources } = useStopScrapingAllSources();

  useEffect(() => {
    void refetchSources({ activeStatus: scraperStatusFilter });
  }, [refetchSources, scraperStatusFilter]);

  const handleSelectChange = (
    event: SelectChangeEvent<EActiveStatusSources>,
  ): void => {
    setScraperStatusFilter(event.target.value as EActiveStatusSources);
  };

  const handleRefreshButtonClick = (): void => {
    void refetchSources();
  };

  const handleStopScrapingAllSources = (): void => {
    void stopScrapingAllSources();
    void refetchSources();
  };

  return (
    <Container>
      <FormControl>
        <InputLabel>Active status</InputLabel>
        <Select<EActiveStatusSources>
          label="Active status"
          sx={{ width: '125px' }}
          value={scraperStatusFilter}
          onChange={handleSelectChange}
        >
          <MenuItem value={EActiveStatusSources.all}>All</MenuItem>
          <MenuItem value={EActiveStatusSources.active}>Active</MenuItem>
          <MenuItem value={EActiveStatusSources.disabled}>Disabled</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Search source"
        type="search"
        variant="outlined"
        onChange={handleSearchBySourcesName}
      />
      <div>
        <Tooltip title="Refresh sources">
          <IconButton size="large" onClick={handleRefreshButtonClick}>
            <Refresh fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Stop scraping resources">
          <IconButton size="large" onClick={handleStopScrapingAllSources}>
            <Stop fontSize="large" />
          </IconButton>
        </Tooltip>
      </div>
    </Container>
  );
};
