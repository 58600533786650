import { FetchResult, gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IBindFileInput, IBindFileResponse } from '@/types/bind-file.interface';

import { USER_REQUEST_ATTACHMENT_DTO_FRAGMENT } from '../fragments';
import { GET_REQUEST_BY_ID } from '../queries/useGetRequestById';

interface IBindFile extends MutationResult<IBindFileResponse> {
  bindFile: (
    payload: IBindFileInput,
  ) => Promise<FetchResult<IBindFileResponse>>;
}

export const BIND_FILE = gql`
  ${USER_REQUEST_ATTACHMENT_DTO_FRAGMENT}

  mutation bindFile(
    $buffer: String!
    $contentType: String!
    $userRequestId: String!
    $fileName: String!
    $lastModified: DateTime!
    $extension: String
  ) {
    bindFile(
      userRequestId: $userRequestId
      fileName: $fileName
      file: {
        buffer: $buffer
        contentType: $contentType
        lastModified: $lastModified
      }
      extension: $extension
    ) {
      ...UserRequestAttachmentDTOFragment
    }
  }
`;

export const useBindFile = (): IBindFile => {
  const [mutate, otherOptions] = useMutation(BIND_FILE, {
    refetchQueries: [GET_REQUEST_BY_ID],
  });

  const bindFile = useCallback(
    (payload: IBindFileInput): Promise<FetchResult<IBindFileResponse>> =>
      mutate({
        variables: payload,
      }),
    [mutate],
  );

  return { bindFile, ...otherOptions };
};
