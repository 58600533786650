import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IMeasureItem } from '@/types/measure.interface';

export interface IUpdateMeasureResponseData {
  updateMeasure: IMeasureItem;
}

interface IUpdateMeasureVariables {
  id: string;
  name: string;
}

interface IUpdateMeasure {
  updateMeasure: (
    measure: IMeasureItem,
  ) => Promise<FetchResult<IUpdateMeasureResponseData>>;
  loading: boolean;
  error?: ApolloError;
}

export const UPDATE_MEASURE = gql`
  mutation updateMeasure($id: String!, $name: String!) {
    updateMeasure(options: { id: $id, name: $name }) {
      id
      name
    }
  }
`;

export const useUpdateMeasure = (): IUpdateMeasure => {
  const [mutate, { loading, error }] = useMutation<
    IUpdateMeasureResponseData,
    IUpdateMeasureVariables
  >(UPDATE_MEASURE);

  const updateMeasure = useCallback(
    (measure: IMeasureItem): Promise<FetchResult<IUpdateMeasureResponseData>> =>
      mutate({
        variables: measure,
      }),
    [mutate],
  );

  return { updateMeasure, loading, error };
};
