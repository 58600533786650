import { Stack } from '@mui/material';
import { FC } from 'react';

import { useCreateCategory } from '@/lib/graphql/mutations/categories/useCreateCategory';
import { ICategoryItem } from '@/lib/graphql/queries/categories/useCategories';
import { useParentCategories } from '@/lib/graphql/queries/categories/useParentCategories';

import { CategoryForm } from '../components/CategoryForm';

export const CategoryCreatePage: FC = () => {
  const category: ICategoryItem = {
    id: '',
    name: '',
    keywords: '',
  };

  const { createCategory } = useCreateCategory();
  const { categories } = useParentCategories();

  return (
    <Stack gap={5} width="100%">
      <CategoryForm
        categories={categories}
        category={category}
        handleSubmit={createCategory}
      />
    </Stack>
  );
};
