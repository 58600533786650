import styled from '@emotion/styled';
import { AppBar as BaseAppBar, Stack } from '@mui/material';

export const AppBar = styled(BaseAppBar)`
  background-color: inherit;
  position: sticky;
  z-index: 1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;

  .MuiToolbar-root {
    min-height: 48px;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 100%;
  }
`;

export const HeadStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 48px;
`;
