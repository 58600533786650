import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { ISourceItem } from '@/types/sourceItem.interface';

import { SOURCE_DTO_FRAGMENT } from '../fragments';
import { PRODUCTS } from '../queries/useProducts';

interface IUpdateSourceActive {
  updateSourceActive: ({
    id,
    isActive,
  }: Pick<ISourceItem, 'id' | 'isActive'>) => Promise<FetchResult<ISourceItem>>;
}

export const UPDATE_SOURCE_ACTIVE = gql`
  ${SOURCE_DTO_FRAGMENT}
  mutation updateSource($id: Int!, $isActive: Boolean!) {
    updateSource(updateSourceInput: { id: $id, isActive: $isActive }) {
      ...SourceDTOFragment
    }
  }
`;

export const useUpdateSourceActive = (): IUpdateSourceActive => {
  const [mutate] = useMutation(UPDATE_SOURCE_ACTIVE);

  const updateSourceActive = useCallback(
    ({
      id,
      isActive,
    }: Pick<ISourceItem, 'id' | 'isActive'>): Promise<
      FetchResult<ISourceItem>
    > =>
      mutate({
        variables: {
          id,
          isActive,
        },
        refetchQueries: [PRODUCTS],
        update: (cache) => {
          cache.evict({ fieldName: 'products' });
          cache.gc();
        },
      }),
    [mutate],
  );

  return { updateSourceActive };
};
