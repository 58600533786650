import TextFieldInput from '@mui/material/TextField';
import { DebouncedFunc } from 'lodash';
import { FC } from 'react';
import { Field, useFormState } from 'react-final-form';

import { ProductVisibleSortOrderField } from '@/lib/graphql/types/product-fields.enum';

import { ProductListParams } from '../..';

import { DatePickerField } from './components/DatePickerField';
import { OrganizationsSelect } from './components/OrganizationsSelect';
import { StatusInput } from './components/StatusInput';
import { Container, FieldWrapper, muiFieldStyles } from './styles';
interface FiltesPanelProps {
  changeFilterValues: DebouncedFunc<(values: ProductListParams) => void>;
}

export const FiltersPanel: FC<FiltesPanelProps> = ({ changeFilterValues }) => {
  const { values: formValues } = useFormState<ProductListParams>();
  changeFilterValues(formValues);

  return (
    <Container>
      <FieldWrapper>
        <Field<string[]> name="organizationsIds">
          {(props) => (
            <OrganizationsSelect
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
            />
          )}
        </Field>
      </FieldWrapper>
      <FieldWrapper>
        <Field<string> name="search">
          {(props) => (
            <TextFieldInput
              label="Search product"
              name={props.input.name}
              sx={muiFieldStyles}
              type="search"
              value={props.input.value}
              variant="outlined"
              onChange={props.input.onChange}
            />
          )}
        </Field>
      </FieldWrapper>
      <FieldWrapper>
        <Field name="fromDate">
          {(props) => (
            <DatePickerField
              label="From date"
              name={props.input.name}
              onChange={props.input.onChange}
            />
          )}
        </Field>
      </FieldWrapper>
      <FieldWrapper>
        <Field name="toDate">
          {(props) => (
            <DatePickerField
              label="To date"
              name={props.input.name}
              onChange={props.input.onChange}
            />
          )}
        </Field>
      </FieldWrapper>
      <FieldWrapper>
        <Field name="minPrice">
          {(props) => (
            <TextFieldInput
              label="Min price"
              name={props.input.name}
              sx={muiFieldStyles}
              onChange={props.input.onChange}
            />
          )}
        </Field>
      </FieldWrapper>
      <FieldWrapper>
        <Field name="maxPrice">
          {(props) => (
            <TextFieldInput
              label="Max price"
              name={props.input.name}
              sx={muiFieldStyles}
              onChange={props.input.onChange}
            />
          )}
        </Field>
      </FieldWrapper>
      <FieldWrapper>
        <Field<ProductVisibleSortOrderField> name="visibilityStatus">
          {(props) => (
            <StatusInput
              name={props.input.name}
              visibilityStatus={props.input.value}
              onChange={props.input.onChange}
            />
          )}
        </Field>
      </FieldWrapper>
    </Container>
  );
};
