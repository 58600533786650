import { useNavigate } from 'react-router-dom';

import { useToast } from '@/components/atoms/Toast/hooks';
import { useRemoveCategory } from '@/lib/graphql/mutations/categories/useRemoveCategory';
import { useCategories } from '@/lib/graphql/queries/categories/useCategories';

interface ICategoryActions {
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => Promise<void>;
}
const useCategoryActions = (): ICategoryActions => {
  const { removeCategory } = useRemoveCategory();
  const { refetch } = useCategories();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleEdit = (id: string): void => {
    navigate(`/categories/${id}/edit`);
  };

  const handleDelete = async (id: string): Promise<void> => {
    await removeCategory(id);
    await refetch();
    showToast('Категория удалена');
  };

  return { handleEdit, handleDelete };
};

export default useCategoryActions;
