import DashboardIcon from '@mui/icons-material/Dashboard';
import {
  ListItem as BaseListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

interface IListItem {
  icon?: ReactNode;
  label: string;
  path: string;
}

export const ListItem = (props: IListItem): ReactElement => {
  const { icon = <DashboardIcon />, label, path } = props;

  return (
    <NavLink style={{ textDecoration: 'none' }} to={path}>
      {({ isActive }) => (
        <BaseListItem>
          <ListItemIcon>{icon}</ListItemIcon>
          <Typography
            sx={({ palette, typography }) => ({
              color: isActive ? palette.primary.main : palette.common.black,
              fontWeight: isActive
                ? typography.fontWeightBold
                : typography.fontWeightRegular,
              textDecoration: 'none !important',
            })}
            variant="body1"
          >
            {label}
          </Typography>
        </BaseListItem>
      )}
    </NavLink>
  );
};
