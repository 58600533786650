import styled from '@emotion/styled';

export const Container = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;

export const FieldWrapper = styled.div`
  margin: 0 5px;
`;

export const muiFieldStyles = {
  width: '150px !important', // NOTE: MUI overrides styles without the "!important" property
};

export const muiContainerStyles = {
  padding: 0,
  margin: 0,
};
