import { List } from '@mui/material';
import { useState } from 'react';

import { Loader } from '@/components/atoms/Loader';
import { ListEmpty } from '@/components/molecules/ListEmpty';
import { useSources } from '@/lib/graphql/queries/useSources';
import { EActiveStatusSources } from '@/lib/graphql/types/sources.types';

import { AuxiliaryMenu } from './components/AuxiliaryMenu';
import { SourcesList } from './components/SourceList';
import { Paper } from './styles';

export const SourcesPage: React.FC = () => {
  const { sources, loading, refetch } = useSources(EActiveStatusSources.active);
  const [searchSourceByName, setSearchSourceByName] = useState('');

  if (loading) return <Loader />;

  const handleSearchBySourcesName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setSearchSourceByName(event.target.value);
  };

  return (
    <Paper>
      <AuxiliaryMenu
        handleSearchBySourcesName={handleSearchBySourcesName}
        refetchSources={refetch}
      />
      <List dense={false}>
        {sources?.length ? (
          <SourcesList
            refetch={refetch}
            searchSourceByName={searchSourceByName}
            sources={sources}
          />
        ) : (
          <ListEmpty />
        )}
      </List>
    </Paper>
  );
};
