import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface IRemoveSocialNetwork {
  removeSocialNetwork: (id: string) => Promise<FetchResult<boolean>>;
}

export const REMOVE_SOCIAL_NETWORK = gql`
  mutation removeSocialNetwork($id: String!) {
    removeSocialNetwork(id: $id)
  }
`;

export const useRemoveSocialNetwork = (): IRemoveSocialNetwork => {
  const [mutate] = useMutation<boolean>(REMOVE_SOCIAL_NETWORK);

  const removeSocialNetwork = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: {
          id,
        },
      }),
    [mutate],
  );

  return { removeSocialNetwork };
};
