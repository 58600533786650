export enum StatusNames {
  draft = 'draft',
  moderation = 'moderation',
  change = 'change',
  approve = 'approve',
}

export const moderationStatuses: Record<StatusNames, string> = {
  approve: 'The card is approved',
  change: 'Changes are required',
  draft: 'The card in the draft',
  moderation: 'The card is under moderation',
};
