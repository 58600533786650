import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const FieldStack = styled(Stack)`
  width: 100%;
  margin: 20px 0 0 0;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 20px;
`;
