import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface IRemoveCategory {
  removeCategory: (id: string) => Promise<FetchResult<boolean>>;
}

export const REMOVE_CATEGORY = gql`
  mutation removeCategory($id: String!) {
    removeCategory(id: $id)
  }
`;

export const useRemoveCategory = (): IRemoveCategory => {
  const [mutate] = useMutation(REMOVE_CATEGORY);

  const removeCategory = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: { id },
      }),
    [mutate],
  );

  return { removeCategory };
};
