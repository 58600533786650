import { FetchResult, gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IRequestItem } from '@/types/requests.interface';

import { USER_REQUESTS_DTO_FRAGMENT } from '../fragments';
import { GET_ALL_REQUESTS } from '../queries/useGetAllRequests';

interface IRejectRequestResponseData {
  rejectUserRequest: IRequestItem;
}

interface IRejectRequestVariables {
  userRequestId: string;
}

interface IRejectRequest extends MutationResult<IRejectRequestResponseData> {
  rejectUserRequest: (
    userRequestId: string,
  ) => Promise<FetchResult<IRejectRequestResponseData>>;
}

export const REJECT_REQUEST = gql`
  ${USER_REQUESTS_DTO_FRAGMENT}

  mutation rejectUserRequest($userRequestId: String!) {
    rejectUserRequest(id: $userRequestId) {
      ...UserRequestDTOFragment
    }
  }
`;

export const useRejectRequest = (): IRejectRequest => {
  const [mutate, otherOptions] = useMutation<
    IRejectRequestResponseData,
    IRejectRequestVariables
  >(REJECT_REQUEST, { refetchQueries: [GET_ALL_REQUESTS] });

  const rejectUserRequest = useCallback(
    (userRequestId: string): Promise<FetchResult<IRejectRequestResponseData>> =>
      mutate({
        variables: { userRequestId },
      }),
    [mutate],
  );

  return { rejectUserRequest, ...otherOptions };
};
