import { ApolloQueryResult } from '@apollo/client';
import { Switch, TableCell } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { MoreButton } from '@/components/atoms/buttons/MoreButton';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useUpdateOrganizationStatus } from '@/lib/graphql/mutations/useUpdateOrganizationStatus';
import { OrganizationsQueryResult } from '@/lib/graphql/queries/useOrganizations';
import { IOrganization } from '@/types/organization.interface';

import { DeleteButton } from './components/DeleteButton';
import { HideButton } from './components/HideButton';
import { TableRow } from './styles';

interface OrganizationRowProps {
  organization: IOrganization;
  refetch: () => Promise<ApolloQueryResult<OrganizationsQueryResult>>;
}

export const OrganizationRow = ({
  organization,
  refetch,
}: OrganizationRowProps): ReactElement => {
  const { id, name, email, taxpayerIdentificationNumber, isActive, isHidden } =
    organization;

  const navigate = useNavigate();

  const { updateOrganizationStatus } = useUpdateOrganizationStatus();
  const { showToast } = useToast();

  const handleMore = (id: string): void => navigate(`/organizations/${id}`);

  const handleActivateClick = async (): Promise<void> => {
    await updateOrganizationStatus({ id, isActive: !isActive });

    isActive
      ? showToast(`Учетная запись ${name} деактивирована`)
      : showToast(`Учетная запись ${name} активирована`);
  };

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{taxpayerIdentificationNumber ?? '-'}</TableCell>
      <TableCell>
        <Switch checked={isActive} onChange={handleActivateClick} />
      </TableCell>
      <TableCell align="right">
        <DeleteButton id={id} refetch={refetch} />
      </TableCell>
      <TableCell align="right">
        <HideButton id={id} isHidden={isHidden} refetch={refetch} />
      </TableCell>
      <TableCell align="right">
        <MoreButton id={id} onClick={handleMore} />
      </TableCell>
    </TableRow>
  );
};
