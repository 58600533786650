import { ListItemText, Switch } from '@mui/material';
import { ReactElement } from 'react';

import { IBannerItem } from '@/types/banner.interface';

import { Justify } from './styles';

interface IActiveSwitcher {
  banner: IBannerItem;
  onChange: (banner: IBannerItem) => Promise<void>;
}

export const ActiveSwitcher = ({
  banner,
  onChange,
}: IActiveSwitcher): ReactElement => {
  const { isActive, ...bannerItem } = banner;

  const handleChange = (): void => {
    void onChange({ ...bannerItem, isActive: !isActive });
  };

  return (
    <Justify>
      <Switch checked={isActive} onChange={handleChange} />
      <ListItemText primary={`${isActive ? 'Active' : 'Inactive'}`} />
    </Justify>
  );
};
