import { FetchResult, gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { INVITES_DTO_FRAGMENT } from '../../fragments';
import { IInvite, INVITES } from '../../queries/invites/useInvites';

interface IUpdateInviteResponseData {
  updateInvite: IInvite;
}

interface IUpdateInviteVariables {
  id: string;
  isActive: boolean;
}

interface IUpdateInvite extends MutationResult<IUpdateInviteResponseData> {
  updateInvite: (
    id: string,
    isActive: boolean,
  ) => Promise<FetchResult<IUpdateInviteResponseData>>;
}

export const UPDATE_INVITE = gql`
  ${INVITES_DTO_FRAGMENT}
  mutation updateInvite($id: String!, $isActive: Boolean) {
    updateInvite(updateInviteInput: { id: $id, isActive: $isActive }) {
      ...InviteDTOFragment
    }
  }
`;

export const useUpdateInvite = (): IUpdateInvite => {
  const [mutate, otherOptions] = useMutation<
    IUpdateInviteResponseData,
    IUpdateInviteVariables
  >(UPDATE_INVITE, {
    refetchQueries: [INVITES],
  });

  const updateInvite = useCallback(
    (
      id: string,
      isActive: boolean,
    ): Promise<FetchResult<IUpdateInviteResponseData>> =>
      mutate({
        variables: { id, isActive },
      }),
    [mutate],
  );

  return { updateInvite, ...otherOptions };
};
