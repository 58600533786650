import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';

import { useMeasures } from '@/lib/graphql/queries/measures/useMeasures';

import { columns } from './columns';
import { MeasureTableRow } from './components/MeasureRow';
import { TableCell, TableContainer, TablePaper } from './styles';

export const MeasureTable: FC = () => {
  const { measures } = useMeasures();

  return (
    <TablePaper>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} minWidth={column.minWidth}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {measures?.map((row) => <MeasureTableRow key={row.id} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </TablePaper>
  );
};
