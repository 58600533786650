import { AlertColor } from '@mui/material';
import { useState } from 'react';

import { ToastProviderProps } from './interfaces';
import { ToastContext } from './сontext';

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<string | null>(null);
  const [severity, setSeverity] = useState<AlertColor>();

  const showToast = (message: string, newSeverity?: AlertColor): void => {
    setToast(message);
    setSeverity(newSeverity);
  };

  const hideToast = (): void => {
    setToast(null);
  };

  return (
    <ToastContext.Provider value={{ toast, showToast, hideToast, severity }}>
      {children}
    </ToastContext.Provider>
  );
};
