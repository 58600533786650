import {
  gql,
  LazyQueryExecFunction,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

export type UseRequestTDModelUrlOutput = {
  available: boolean;
  tdModelUrl: string | null;
  newAmountOfDownloads: number;
};

const REQUEST_TD_MODEL_URL_QUERY = gql`
  query requestTdModelUrl($id: String!) {
    requestTdModelUrl(id: $id) {
      available
      tdModelUrl
      newAmountOfDownloads
    }
  }
`;

export const useRequestTdModelsUrlLazyQuery = (): LazyQueryExecFunction<
  {
    requestTdModelUrl: UseRequestTDModelUrlOutput;
  },
  OperationVariables
> => {
  const [query] = useLazyQuery<{
    requestTdModelUrl: UseRequestTDModelUrlOutput;
  }>(REQUEST_TD_MODEL_URL_QUERY, { fetchPolicy: 'network-only' });

  return query;
};
