import styled from '@emotion/styled';
import { Paper as BasePaper } from '@mui/material';

export const Paper = styled(BasePaper)`
  &.MuiPaper-root {
    width: 100%;
    padding: 10px 20px;
    margin: 0 0 32px;
  }
`;
