export enum ProductOrderField {
  new = 'new',
  popular = 'popular',
  price = 'price',
  priceDesc = 'price_desc',
}

export enum ProductVisibleSortOrderField {
  active = 'active',
  hidden = 'hidden',
  all = 'all',
}
