import { List, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';

import { ICategoryItem } from '@/lib/graphql/queries/categories/useCategories';

import { DeleteButton } from '../DeleteButton';
import { EditButton } from '../EditButton';

import { CategoryListActions, Paper } from './styles';

interface TreeViewProps {
  nodes?: ICategoryItem[];
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  indent?: number;
}

const renderTree: FC<TreeViewProps> = ({
  nodes,
  handleEdit,
  handleDelete,
  indent = 0,
}): ReactElement => {
  return (
    <List dense={false}>
      {nodes?.map((node: ICategoryItem) => {
        const { id, name, childCategories } = node;
        return (
          <div key={id} style={{ marginLeft: `${indent * 40}px` }}>
            <Paper>
              <Typography variant="caption">
                Название категории: {name}
              </Typography>
              <CategoryListActions>
                <EditButton id={id} onClick={handleEdit} />
                <DeleteButton id={id} onClick={handleDelete} />
              </CategoryListActions>
            </Paper>
            {childCategories ? renderTree({
                nodes: childCategories,
                handleEdit,
                handleDelete,
                indent: indent + 1,
              }) : null}
          </div>
        );
      })}
    </List>
  );
};

const TreeView = ({
  nodes,
  handleEdit,
  handleDelete,
}: TreeViewProps): ReactElement => {
  return <div>{renderTree({ nodes, handleEdit, handleDelete })}</div>;
};

export default TreeView;
