import { useCallback, useState } from 'react';

export function useModal<Data = undefined>(
  initial = false,
): [isOpen: boolean, toggle: (data?: Data) => void, data: Data | undefined] {
  const [isOpen, setIsOpen] = useState(initial);
  const [data, setData] = useState<Data>();
  const toggle = useCallback(
    (modalData?: Data) => {
      if (modalData !== undefined) setData(modalData);
      setIsOpen(!isOpen);
    },
    [isOpen],
  );
  return [isOpen, toggle, data];
}
