import { Fade, Modal } from '@mui/material';
import { FC } from 'react';

import { Window } from './styles';
import { IModalWindowProps } from './types';

const ModalWindow: FC<IModalWindowProps> = (props) => {
  const { children, className, isOpen, onClose } = props;

  return (
    <Modal closeAfterTransition open={isOpen} onClose={onClose}>
      <Fade in={isOpen}>
        <Window className={className}>{children}</Window>
      </Fade>
    </Modal>
  );
};

export default ModalWindow;
