import { List } from '@mui/material';
import React from 'react';

import { Loader } from '@/components/atoms/Loader';
import { IProductItem } from '@/types/productItem.interface';

import { Item } from './components/Item';
import { Empty } from './styles';

interface ListItemsProps {
  loading: boolean;
  products: IProductItem[];
  selectedProducts: Set<string>;
  handleToggleProduct: (id: string) => void;
}

export const ListItems: React.FC<ListItemsProps> = ({
  loading,
  products,
  selectedProducts,
  handleToggleProduct,
}) => {
  if (loading) return <Loader />;
  if (!loading && !products?.length) return <Empty />;

  return (
    <List dense={false}>
      {products?.map((item: IProductItem) => (
        <Item
          key={item.id}
          handleToggleProduct={handleToggleProduct}
          item={item}
          selectedProducts={selectedProducts}
        />
      ))}
    </List>
  );
};
