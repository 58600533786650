import { FC } from 'react';

import { ListPage } from '../../ListPage';
import { MeasureTable } from '../components/MeasureTable';

export const MeasuresPage: FC = () => (
  <ListPage createButtonTitle="Создать величину" createRoute="/measures/create">
    <MeasureTable />
  </ListPage>
);
