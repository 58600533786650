import { Column } from './types';

export const columns: readonly Column[] = [
  { id: 'name', label: 'Название', minWidth: 170 },
  {
    id: 'update',
    label: 'Обновить',
    minWidth: 25,
  },
  {
    id: 'delete',
    label: 'Удалить',
    minWidth: 25,
  },
];
