import { FC } from 'react';

import { DownloadFileButton } from '@/components/molecules/DownloadFileButton';
import { ICatalog } from '@/types/catalog.interface';

import { Modal } from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onFileDownload: (fileUrl: string) => void;
  catalogs?: ICatalog[] | null;
}

export const CatalogsModal: FC<Props> = ({
  isOpen,
  onClose,
  onFileDownload,
  catalogs,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <>
        {catalogs?.map((catalog) => (
          <DownloadFileButton
            key={catalog.id}
            fileUrl={catalog.file.url}
            name={catalog.name}
            onClick={onFileDownload}
          />
        ))}
      </>
    </Modal>
  );
};
