import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface IResendInviteVariables {
  id: string;
}

interface IResendInvite {
  resendInvite: (id: string) => Promise<FetchResult<boolean>>;
}

export const RESEND_INVITE = gql`
  mutation resendInvite($id: String!) {
    resendInvite(id: $id)
  }
`;

export const useResendInvite = (): IResendInvite => {
  const [mutate, otherOptions] = useMutation<boolean, IResendInviteVariables>(
    RESEND_INVITE,
  );

  const resendInvite = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: { id },
      }),
    [mutate],
  );

  return { resendInvite, ...otherOptions };
};
