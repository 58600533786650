import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  ICharacteristicItem,
  ICreateCharacteristicInput,
} from '@/types/characteristic.interface';

export interface ICreateCharacteristicResponseData {
  createCharacteristic: ICharacteristicItem;
}

interface ICreateCharacteristic {
  createCharacteristic: (
    characteristic: ICreateCharacteristicInput,
  ) => Promise<FetchResult<ICreateCharacteristicResponseData>>;
  loading: boolean;
  error?: ApolloError;
}

export const CREATE_CHARACTERISTIC = gql`
  mutation createCharacteristic($name: String!, $measuresIds: [String!]!) {
    createCharacteristic(options: { name: $name, measuresIds: $measuresIds }) {
      id
      name
      measures {
        id
        name
      }
    }
  }
`;

export const useCreateCharacteristic = (): ICreateCharacteristic => {
  const [mutate, { loading, error }] = useMutation<
    ICreateCharacteristicResponseData,
    ICreateCharacteristicInput
  >(CREATE_CHARACTERISTIC);

  const createCharacteristic = useCallback(
    (
      characteristic: ICreateCharacteristicInput,
    ): Promise<FetchResult<ICreateCharacteristicResponseData>> =>
      mutate({
        variables: characteristic,
      }),
    [mutate],
  );

  return { createCharacteristic, loading, error };
};
