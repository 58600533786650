import { Checkbox, ListItemText } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MoreButton } from '@/components/atoms/buttons/MoreButton';
import { IProductItem } from '@/types/productItem.interface';

import { DeleteButton } from './components/DeleteButton';
import { HideButton } from './components/HideButton';
import { ModerationStatus } from './components/ModerationStatus';
import { ListItemName, ListItemRow } from './styles';

interface ListItemsProps {
  item: IProductItem;
  selectedProducts: Set<string>;
  handleToggleProduct: (id: string) => void;
}

export const Item: React.FC<ListItemsProps> = ({
  item,
  selectedProducts,
  handleToggleProduct,
}) => {
  const navigate = useNavigate();

  const handleMore = (id: string): void => navigate(`/products/${id}`);

  const dateDefinitions = new Date(item.createdAt).toLocaleString('ru', {
    dateStyle: 'short',
    timeStyle: 'medium',
  });

  return (
    <>
      <ListItemRow key={item.id}>
        <Checkbox
          checked={selectedProducts.has(item.id)}
          onChange={() => handleToggleProduct(item.id)}
        />
        <ListItemText primary={item.organization?.name} />
        <ListItemName
          href={`${process.env.PRODUCT_BASE_URL}/${item.slug}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {item.name}
        </ListItemName>
        <ListItemText primary={dateDefinitions} />
        <ListItemText primary={item.price} />
        <ModerationStatus moderationStatusName={item.moderationStatus?.name} />
        <MoreButton id={item.id} onClick={handleMore} />
        <HideButton id={item.id} isHidden={item.isHidden} />
        <DeleteButton id={item.id} name={item.name} />
      </ListItemRow>
    </>
  );
};
