import { FC } from 'react';

import { IMeasureItem } from '@/types/measure.interface';

import { MeasureForm } from '../components/MeasureForm';

import { useCreateMeasure } from './hooks';
import { Container } from './styles';

const defaultMeasure: IMeasureItem = {
  id: '',
  name: '',
};

export const CreateMeasurePage: FC = () => {
  const { createMeasure } = useCreateMeasure();

  return (
    <Container>
      <MeasureForm measure={defaultMeasure} onSubmit={createMeasure} />
    </Container>
  );
};
