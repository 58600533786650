import styled from '@emotion/styled';
import { Box, css } from '@mui/material';

import { Colors } from '@/variables/colors';

const OPEN_SIDE_BAR_WIDTH = '332px';

const openSideBar = css`
  padding: 32px 32px 0 ${OPEN_SIDE_BAR_WIDTH};
`;

export const HeadlineText = styled.h1`
  padding: 32px;
  border-radius: 4px;
  background-color: ${Colors.Denim};
  font-size: 24px;
  text-align: center;
  color: ${Colors.White};
  font-weight: bold;
`;

export const MainContainer = styled(Box)<{ isOpen: boolean }>`
  width: 100%;
  padding: 32px 0 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  position: relative;
  overflow: auto;
  background-color: ${Colors.WildSand};
  ${({ isOpen }) => isOpen && openSideBar};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
