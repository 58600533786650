import Button from '@mui/material/Button';
import { FC } from 'react';

import { useToast } from '@/components/atoms/Toast/hooks';
import { useUpdateOrganizationStatus } from '@/lib/graphql/mutations/useUpdateOrganizationStatus';

interface IActivateButtonProps {
  id: string;
  name: string;
  isActive: boolean;
}

export const ActivateButton: FC<IActivateButtonProps> = ({
  id,
  name,
  isActive,
}) => {
  const { updateOrganizationStatus } = useUpdateOrganizationStatus();

  const { showToast } = useToast();

  const handleActivateClick = async (): Promise<void> => {
    await updateOrganizationStatus({ id, isActive: !isActive });

    isActive
      ? showToast(`Учетная запись ${name} деактивирована`)
      : showToast(`Учетная запись ${name} активирована`);
  };

  return (
    <Button
      color={isActive ? 'error' : 'success'}
      variant="contained"
      onClick={handleActivateClick}
    >
      {isActive ? 'Деактивировать' : 'Активировать'}
    </Button>
  );
};
