import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { IProductItem } from '@/types/productItem.interface';
import { QueryParams } from '@/types/query-params.type';

import { defaultQueryParams } from '../constants';
import {
  IMAGE_DTO_FRAGMENT,
  PRODUCT_DTO_FRAGMENT,
  TEXTURE_DTO_FRAGMENT,
} from '../fragments';
import {
  ProductOrderField,
  ProductVisibleSortOrderField,
} from '../types/product-fields.enum';

interface ProductPageDTO {
  nodes: IProductItem[];
  total: number;
}

interface QueryProductsResult {
  products: ProductPageDTO;
}

interface IProducts {
  products: IProductItem[];
  total: number;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export interface ProductListParamsInput extends QueryParams {
  minPrice: number | null;
  maxPrice: number | null;
  fromDate: string | null;
  toDate: string | null;
  sortBy?: ProductOrderField;
  showHiddenProducts?: boolean;
  visibilityStatus?: ProductVisibleSortOrderField;
  search?: string;
  organizationsIds?: string[];
}

export const PRODUCTS = gql`
  ${PRODUCT_DTO_FRAGMENT}
  ${TEXTURE_DTO_FRAGMENT}
  ${IMAGE_DTO_FRAGMENT}
  query Products($params: ProductListParamsInput) {
    products(params: $params) {
      nodes {
        ...ProductDTOFragment
        organization {
          id
          name
        }
        images {
          ...ImageDTOFragment
        }
        textures {
          ...TextureDTOFragment
        }
        source {
          name
        }
        moderationStatus {
          id
          name
        }
      }
      total
    }
  }
`;

export const useProducts = (params?: ProductListParamsInput): IProducts => {
  const { data, loading, refetch } = useQuery<QueryProductsResult>(PRODUCTS, {
    variables: { params: { ...defaultQueryParams, ...params } },
  });

  const products = data?.products.nodes ?? [];
  const total = data?.products.total ?? 0;

  return { products, total, loading, refetch };
};
