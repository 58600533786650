import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { IconButton, Stack, Toolbar, Tooltip } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';

import { useTestProducts } from '@/lib/graphql/mutations/test-results/useTestAll';
import { useSources } from '@/lib/graphql/queries/useSources';
import { EActiveStatusSources } from '@/lib/graphql/types/sources.types';

import { TestResultForm } from '../TestResultForm';

import { AppBar, HeadStack } from './styles';

interface IStickyHeaderProps {
  refetch: () => void;
}

export const StickyHeader: FC<IStickyHeaderProps> = ({ refetch }) => {
  const { testProducts } = useTestProducts();
  const { sources } = useSources(EActiveStatusSources.active);

  const handleTestAllProducts = useCallback(async (): Promise<void> => {
    await testProducts([]);
    refetch();
  }, [refetch, testProducts]);

  const handleSave = useCallback(
    async (value: {
      source: Array<{ label: string; id: number }>;
    }): Promise<void> => {
      await testProducts(value.source.map((item) => item.id));
      refetch();
    },
    [refetch, testProducts],
  );

  const options = useMemo(
    () =>
      sources?.map((source) => ({
        label: source.name,
        id: source.id,
      })),
    [sources],
  );

  return (
    <AppBar position="sticky">
      <Toolbar>
        <HeadStack>
          {options ? (
            <TestResultForm handleSave={handleSave} options={options} />
          ) : null}
          <Stack>
            <Tooltip title="проверить все">
              <IconButton size="large" onClick={handleTestAllProducts}>
                <AllInclusiveIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </HeadStack>
      </Toolbar>
    </AppBar>
  );
};
