import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { FC } from 'react';

import { useOrganizations } from '@/lib/graphql/queries/useOrganizations';

import { muiFieldStyles } from '../../styles';

interface OrganizationsSelectProps {
  name: string;
  value: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
}

export const OrganizationsSelect: FC<OrganizationsSelectProps> = ({
  name,
  value,
  onChange,
}) => {
  const { organizations } = useOrganizations();

  const renderValues = (values: string[]): string => {
    return organizations
      .filter((organization) => values.includes(organization.id))
      .map((val) => val.name)
      .join(', ');
  };

  return (
    <FormControl sx={muiFieldStyles}>
      <InputLabel>Organizations</InputLabel>
      <Select
        multiple
        input={<OutlinedInput label="Organizations" />}
        name={name}
        renderValue={renderValues}
        value={value}
        onChange={onChange}
      >
        {organizations.map(({ id, name }) => (
          <MenuItem key={name} value={id}>
            <Checkbox checked={value.includes(id)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
