import { Button } from '@mui/material';
import { ChangeEvent, ReactElement } from 'react';

import { Stack } from './styles';

interface IUploadButtonProps {
  onUpload: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  disabled?: boolean;
}

export const UploadButton = ({
  onUpload,
  disabled,
}: IUploadButtonProps): ReactElement => {
  return (
    <Stack direction="column">
      <Button disabled={disabled} variant="contained">
        <label htmlFor="upload">Загрузить</label>
      </Button>
      <input hidden id="upload" type="file" onChange={onUpload}></input>
    </Stack>
  );
};
