import { isEmpty, isNil } from 'lodash';
import { FC } from 'react';

import { ITdModel } from '@/types/td-model.interface';

import useToggle from '../../hooks/hooks';

import TdModelsButton from './components/TdModelsButton';
import { TdModelsModal } from './components/TdModelsModal';
import { useRequestTdModelsUrlLazyQuery } from './hooks/useRequestTdModelsUrlLazyQuery';
import { Text } from './styles';

export const TdModelsDownload: FC<{ tdModels?: ITdModel[] }> = ({
  tdModels,
}) => {
  const tdModelUrlQuery = useRequestTdModelsUrlLazyQuery();

  const [isTdModelsModalShow, toggleTdModelsModalShow] = useToggle();

  if (tdModels == null) return null;

  const handleTdModelDownload = async (tdModelId?: string): Promise<void> => {
    const result = await tdModelUrlQuery({
      variables: { id: tdModelId },
    });

    const { tdModelUrl } = result?.data?.requestTdModelUrl ?? {};

    if (tdModelUrl !== null) {
      open(tdModelUrl);
    }
  };

  const hasTdModels = !isNil(tdModels) && !isEmpty(tdModels);

  if (!hasTdModels) return <Text>3D models are not loaded</Text>;

  return (
    <>
      <TdModelsButton
        isDownload={tdModels.length > 0}
        onClick={toggleTdModelsModalShow}
      />

      <TdModelsModal
        isOpen={isTdModelsModalShow}
        tdModels={tdModels}
        onClose={toggleTdModelsModalShow}
        onFileDownload={handleTdModelDownload}
      />
    </>
  );
};
