import styled from '@emotion/styled';
import { Paper as BasePaper, Stack } from '@mui/material';

export const Paper = styled(BasePaper)`
  &.MuiPaper-root {
    max-width: 700px;
    width: 100%;
    padding: 10px 20px;
    margin: 0 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CategoryListActions = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
