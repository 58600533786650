import styled from '@emotion/styled';
import { ListItem as BaseListItem } from '@mui/material';

import { Colors } from '@/variables/colors';

export const ListItem = styled(BaseListItem)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;

  &:hover {
    background: ${Colors.Alabaster};
  }
`;
