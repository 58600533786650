import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { PRODUCTS } from '../queries/useProducts';

interface IUseRemoveProduct {
  removeProducts: (ids: string[]) => Promise<FetchResult<boolean>>;
  loading: boolean;
}

export const REMOVE_PRODUCTS = gql`
  mutation removeProducts($ids: [String!]!) {
    removeProducts(ids: $ids)
  }
`;

export const useRemoveProducts = (): IUseRemoveProduct => {
  const [mutate, { loading }] = useMutation<boolean>(REMOVE_PRODUCTS);

  const removeProducts = useCallback(
    (ids: string[]): Promise<FetchResult<boolean>> =>
      mutate({
        variables: {
          ids,
        },
        refetchQueries: [PRODUCTS],
        update(cache) {
          cache.evict({ fieldName: 'products' });
          cache.gc();
        },
      }),
    [mutate],
  );

  return { removeProducts, loading };
};
