import { ApolloQueryResult } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { FC } from 'react';

import ModalWindow from '@/components/atoms/ModalWindow';
import { useToast } from '@/components/atoms/Toast/hooks';
import { useRemoveOrganization } from '@/lib/graphql/mutations/useRemoveOrganization';
import { OrganizationsQueryResult } from '@/lib/graphql/queries/useOrganizations';
import { useModal } from '@/lib/hooks/useModal';

import { ModalForm } from '../ModalForm';

interface DeleteButtonProps {
  id: string;
  refetch: () => Promise<ApolloQueryResult<OrganizationsQueryResult>>;
}

export const DeleteButton: FC<DeleteButtonProps> = ({ id, refetch }) => {
  const [isOpen, toggle] = useModal();
  const { removeOrganization } = useRemoveOrganization();

  const { showToast } = useToast();

  const handleClick = async (): Promise<void> => {
    await removeOrganization(id);

    showToast('Организация удалена');
    await refetch();
    toggle();
  };

  const handleToggle = (): void => toggle();

  return (
    <>
      <IconButton onClick={handleToggle}>
        <DeleteIcon />
      </IconButton>
      <ModalWindow isOpen={isOpen} onClose={handleToggle}>
        <ModalForm
          buttonText="Удалить"
          title="Вы действительно хотите УДАЛИТЬ организацию и все её продукты?"
          onClick={handleClick}
          onClose={handleToggle}
        />
      </ModalWindow>
    </>
  );
};
