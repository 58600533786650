import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { INVITES } from '../../queries/invites/useInvites';

interface IRemoveInviteVariables {
  id: string;
}

interface IRemoveInvite {
  removeInvite: (id: string) => Promise<FetchResult<boolean>>;
}

export const REMOVE_INVITE = gql`
  mutation removeInvite($id: String!) {
    removeInvite(id: $id)
  }
`;

export const useRemoveInvite = (): IRemoveInvite => {
  const [mutate, otherOptions] = useMutation<boolean, IRemoveInviteVariables>(
    REMOVE_INVITE,
    {
      refetchQueries: [INVITES],
    },
  );

  const removeInvite = useCallback(
    (id: string): Promise<FetchResult<boolean>> =>
      mutate({
        variables: { id },
      }),
    [mutate],
  );

  return { removeInvite, ...otherOptions };
};
