import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { IOrganization } from '@/types/organization.interface';
import { QueryParams } from '@/types/query-params.type';

import { defaultQueryParams } from '../constants';

interface OrganizationListDTO {
  nodes: IOrganization[];
  total: number;
}

export interface OrganizationsQueryResult {
  organizations: OrganizationListDTO;
}

interface IOrganizations {
  organizations: IOrganization[];
  total: number;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<OrganizationsQueryResult>>;
}

export const ORGANIZATIONS = gql`
  query Organizations($params: OrganizationsParamsInput) {
    organizations(params: $params) {
      nodes {
        id
        name
        email
        isActive
        isHidden
        taxpayerIdentificationNumber
      }
      total
    }
  }
`;

export const useOrganizations = (params?: QueryParams): IOrganizations => {
  const { data, loading, refetch } = useQuery<OrganizationsQueryResult>(
    ORGANIZATIONS,
    {
      variables: { params: { ...defaultQueryParams, ...params } },
    },
  );

  const organizations = data?.organizations.nodes ?? [];
  const total = data?.organizations.total ?? 0;

  return { organizations, total, loading, refetch };
};
