import { ApolloQueryResult } from '@apollo/client';
import { Switch } from '@mui/material';
import { ReactElement } from 'react';

import { useToast } from '@/components/atoms/Toast/hooks';
import { useUpdateSourceActive } from '@/lib/graphql/mutations/useUpdateSourceActive';
import {
  ISourcesResponseData,
  ISourcesVariablesQuery,
} from '@/lib/graphql/queries/useSources';

import { Justify } from './styles';

interface IActiveSwitcher {
  id: number;
  name: string | undefined;
  isActive: boolean;
  refetchSources: (
    variables?: Partial<ISourcesVariablesQuery> | undefined,
  ) => Promise<ApolloQueryResult<ISourcesResponseData>>;
}

export const ActiveSwitcher = ({
  id,
  name,
  isActive,
  refetchSources,
}: IActiveSwitcher): ReactElement => {
  const { updateSourceActive } = useUpdateSourceActive();
  const { showToast } = useToast();

  const handleChangeActive = async (): Promise<void> => {
    await updateSourceActive({ id, isActive: !isActive });
    if (isActive) showToast(`Источник ${name} выключен`);
    else showToast(`Источник ${name} включен`);
    await refetchSources();
  };

  return (
    <Justify>
      <Switch checked={isActive} onChange={handleChangeActive} />
    </Justify>
  );
};
