import { Toolbar } from '@mui/material';
import { FC } from 'react';

import InviteTable from '../components/InviteTable';
import { SendInviteForm } from '../components/SendInviteForm';

import { AppBar, HeadStack } from './styles';

export const InvitesPage: FC = () => {
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <HeadStack>
            <SendInviteForm />
          </HeadStack>
        </Toolbar>
      </AppBar>
      <InviteTable />
    </>
  );
};
