import React from 'react';

import { Loader } from '@/components/atoms/Loader';
import ModalWindow from '@/components/atoms/ModalWindow';
import { Pagination } from '@/components/atoms/Pagination';
import { AnswerForm } from '@/components/molecules/AnswerForm';
import { ListEmpty } from '@/components/molecules/ListEmpty';
import { MESSAGES_PER_PAGE } from '@/components/pages/FeedbackListPage/constants';
import { DEFAULT_INITIAL_PAGE_NUMBER } from '@/constants/constants';
import { useFeedbacks } from '@/lib/graphql/queries/useFeedbacks';
import { useModal } from '@/lib/hooks/useModal';
import useQueryState from '@/lib/hooks/useQueryState';
import * as scheme from '@/lib/utils/io-ts/codec';
import { IFeedbackItem } from '@/types/feedback.interface';

import { FeedbackList } from './components/FeedbackList';
import { Container, Paper } from './styles';

export const FeedbackListPage: React.FC = () => {
  const [isOpen, toggle, feedbackData] = useModal<IFeedbackItem>();
  const [page, setPage] = useQueryState('page', scheme.numericString, {
    defaultValue: DEFAULT_INITIAL_PAGE_NUMBER,
  });
  const { feedbacks, total, loading, refetch } = useFeedbacks({
    page,
    perPage: MESSAGES_PER_PAGE,
  });

  if (loading) return <Loader />;
  if (!loading && !feedbacks?.length) return <ListEmpty />;

  const handleModalClose = (): void => toggle();

  return (
    <Paper>
      <Container>
        <FeedbackList
          feedbacks={feedbacks}
          refetch={refetch}
          toggleModal={toggle}
        />
        <Pagination
          limit={MESSAGES_PER_PAGE}
          page={page}
          total={total}
          onChange={setPage}
        />
        {feedbackData ? (
          <ModalWindow isOpen={isOpen} onClose={handleModalClose}>
            <AnswerForm
              feedbackData={feedbackData}
              onClose={handleModalClose}
            />
          </ModalWindow>
        ) : null}
      </Container>
    </Paper>
  );
};
