import styled from '@emotion/styled';
import { Button as BaseButton } from '@mui/material';

export const Button = styled(BaseButton)<{ variant?: string }>`
  height: auto;
  margin: 15px 0 0 0;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  width: 100%;
  font-size: 16px;
`;
