export enum ScrapingStatus {
  Sleeping = 'Sleeping',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export interface ISocialNetworkItem {
  id?: string;
  type: string;
  url: string;
  sourceId: number;
}

export interface ISourceItem {
  id: number;
  url?: string;
  name?: string;
  status: ScrapingStatus;
  isActive: boolean;
  lastScrapeAt?: Date;
  amountProducts?: number;
  createdAt: Date;
  socialNetworks: ISocialNetworkItem[];
}
