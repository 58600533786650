import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { ISocialNetworkItem } from '@/types/sourceItem.interface';

import { SOURCE_SOCIAL_NETWORKS_DTO_FRAGMENT } from '../fragments';
import { SOURCE } from '../queries/useSource';

interface ICreateSocialNetwork {
  createSocialNetwork: (
    network: ISocialNetworkItem,
  ) => Promise<FetchResult<ISocialNetworkItem>>;
}

export const CREATE_SOCIAL_NETWORK = gql`
  ${SOURCE_SOCIAL_NETWORKS_DTO_FRAGMENT}
  mutation createSocialNetwork($type: String!, $url: String!, $sourceId: Int!) {
    createSocialNetwork(
      createSocialNetworkInput: { type: $type, url: $url, sourceId: $sourceId }
    ) {
      ...SourceSocialNetworkDTOFragment
    }
  }
`;

export const useCreateSocialNetwork = (): ICreateSocialNetwork => {
  const [mutate] = useMutation(CREATE_SOCIAL_NETWORK, {
    refetchQueries: [SOURCE],
  });

  const createSocialNetwork = useCallback(
    (network: ISocialNetworkItem): Promise<FetchResult<ISocialNetworkItem>> =>
      mutate({
        variables: {
          type: network.type,
          url: network.url,
          sourceId: network.sourceId,
        },
      }),
    [mutate],
  );

  return { createSocialNetwork };
};
