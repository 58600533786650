import styled from '@emotion/styled';
import { ListItem } from '@mui/material';

export const ListItemRow = styled(ListItem)`
  display: grid;
  grid-template-columns: min-content 1fr 4fr 2fr 1fr 1fr 1fr repeat(
      2,
      max-content
    );
`;

export const ListItemName = styled.a`
  color: inherit;
`;
