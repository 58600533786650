import { TableCell, TableRow } from '@mui/material';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ICharacteristicItem } from '@/types/characteristic.interface';

import { useRemoveCharacteristic } from './hooks';
import { CreateIcon, DeleteIcon } from './styles';

interface CharacteristicTableRowProps {
  row: ICharacteristicItem;
}

export const CharacteristicTableRow: FC<CharacteristicTableRowProps> = ({
  row: { id, name, measures },
}) => {
  const navigate = useNavigate();
  const { removeCharacteristic } = useRemoveCharacteristic();

  const handleDelete = (): void => {
    void removeCharacteristic(id);
  };

  const handleUpdate = (): void => {
    navigate(`/characteristics/${id}/edit`);
  };

  const measureNames = useMemo(
    () => measures.map((measure) => measure.name).join(', '),
    [measures],
  );

  return (
    <TableRow key={id} hover role="checkbox">
      <TableCell>{name}</TableCell>
      <TableCell>{measureNames}</TableCell>
      <TableCell onClick={handleUpdate}>
        <CreateIcon />
      </TableCell>
      <TableCell onClick={handleDelete}>
        <DeleteIcon />
      </TableCell>
    </TableRow>
  );
};
