import DoneAllIcon from '@mui/icons-material/DoneAll';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { Autocomplete } from 'mui-rff';
import { FC } from 'react';
import { Form } from 'react-final-form';

interface IOption {
  label: string | undefined;
  id: number;
}
interface ITestResultFormProps {
  options: IOption[];
  handleSave: (value: {
    source: Array<{ label: string; id: number }>;
  }) => Promise<void>;
}

export const TestResultForm: FC<ITestResultFormProps> = ({
  options,
  handleSave,
}) => {
  return (
    <Form initialValues={{}} onSubmit={handleSave}>
      {({ handleSubmit }) => (
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Stack direction="row">
            <Autocomplete
              fullWidth
              multiple
              label="source"
              name="source"
              options={options}
            />
            <Tooltip title="Проверить выбранныее источники">
              <IconButton size="large" type="submit">
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </form>
      )}
    </Form>
  );
};
