import { gql } from '@apollo/client';

export const PRODUCT_DTO_FRAGMENT = gql`
  fragment ProductDTOFragment on ProductDTO {
    id
    name
    price
    createdAt
    price
    vendorCode
    stockStatus
    description
    category {
      name
    }
    categoryChain {
      id
      name
    }
    sourcePageUrl
    slug
    isHidden
    moderationStatus {
      name
      description
      productId
    }
  }
`;

export const IMAGE_DTO_FRAGMENT = gql`
  fragment ImageDTOFragment on ImageDTO {
    id
    name
    file {
      id
      name
      url
    }
  }
`;

export const SOURCE_SOCIAL_NETWORKS_DTO_FRAGMENT = gql`
  fragment SourceSocialNetworkDTOFragment on SourceSocialNetworkDTO {
    id
    type
    url
  }
`;

export const SOURCE_DTO_FRAGMENT = gql`
  fragment SourceDTOFragment on SourceDTO {
    id
    name
    url
    status
    isActive
    lastScrapeAt
    amountProducts
  }
`;

export const ORGANIZATION_DTO_FRAGMENT = gql`
  fragment OrganizationDTOFragment on OrganizationDTO {
    id
    name
    isActive
    isHidden
  }
`;

export const BANNER_DTO_FRAGMENT = gql`
  fragment BannerDTOFragment on BannerDTO {
    id
    isActive
    name
    goToUrl
    text
    textColor
    title
    titleColor
    backgroundColor
    backgroundImageUrl
    locale
  }
`;

export const TEXTURE_DTO_FRAGMENT = gql`
  fragment TextureDTOFragment on TextureDTO {
    id
    name
    imageUrl
  }
`;

export const FEEDBACK_DTO_FRAGMENT = gql`
  fragment FeedbackDTOFragment on FeedbackDTO {
    id
    name
    email
    text
    createdAt
  }
`;

export const SCRAPER_TASK_DTO_FRAGMENT = gql`
  fragment ScraperTaskDTOFragment on ScraperTaskDTO {
    id
    mode
    inProgress
    runId
    createdAt
  }
`;

export const CATEGORY_DTO_FRAGMENT = gql`
  fragment CategoryDTOFragment on CategoryDTO {
    id
    name
    keywords
    parentCategoryId
    childCategories {
      id
      name
      keywords
      childCategories {
        id
        name
        keywords
        childCategories {
          id
          name
          keywords
        }
      }
    }
    parentCategory {
      id
      name
    }
  }
`;

export const USER_REQUESTS_DTO_FRAGMENT = gql`
  fragment UserRequestDTOFragment on UserRequestDTO {
    id
    requestType
    assignedAdmin {
      id
      firstName
      lastName
      email
    }
    users {
      id
    }
    product {
      id
      name
    }
    checkedStatus
    attachmentFiles {
      id
      fileName
      extension
      fileEtag
      file {
        id
        name
        size
        key
      }
    }
  }
`;

export const USER_REQUEST_ATTACHMENT_DTO_FRAGMENT = gql`
  fragment UserRequestAttachmentDTOFragment on UserRequestAttachmentDTO {
    id
    fileName
    fileEtag
    file {
      id
      name
      size
      key
    }
  }
`;

export const TEST_RESULT_DTO_FRAGMENT = gql`
  fragment TestResultDTOFragment on ScrapeTestResultDTO {
    id
    createdAt
    testedSources {
      id
      areAllTestsPassed
      items {
        id
        name
        result
        productName
      }
      source {
        id
        name
      }
    }
  }
`;

export const INVITES_DTO_FRAGMENT = gql`
  fragment InviteDTOFragment on InviteDTO {
    id
    email
    inviteCode
    isActive
  }
`;

export const BRAND_AND_PROVIDER_FRAGMENT = gql`
  fragment BrandAndProviderLogoFragment on ProductDTO {
    productBrand {
      id
      name
      url
    }
    productProvider {
      id
      name
      url
    }
  }
`;

export const CATALOG_FRAGMENT = gql`
  fragment CatalogsDownloadFragment on ProductDTO {
    catalogs {
      id
      name
      file {
        url
      }
    }
  }
`;

export const TD_MODEL_FRAGMENT = gql`
  fragment TdModelsDownloadFragment on ProductDTO {
    tdModels {
      id
      name
      extension
    }
  }
`;

export const CHARACTERISTICS_FRAGMENT = gql`
  fragment CharacteristicsFragment on ProductDTO {
    characteristics {
      measure {
        id
        name
      }
      characteristic {
        id
        name
      }
      value
    }
  }
`;
