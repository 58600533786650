import { FetchResult, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  IProductItem,
  UpdateProductInput,
} from '@/types/productItem.interface';

import { PRODUCT_DTO_FRAGMENT } from '../fragments';

interface IUpdateProduct {
  updateProduct: (
    updateProductInput: UpdateProductInput,
  ) => Promise<FetchResult<IProductItem>>;
}

export const UPDATE_PRODUCT = gql`
  ${PRODUCT_DTO_FRAGMENT}
  mutation updateProduct($updateProductInput: UpdateProductInput!) {
    updateProduct(updateProductInput: $updateProductInput) {
      ...ProductDTOFragment
    }
  }
`;

export const useUpdateProduct = (): IUpdateProduct => {
  const [mutate] = useMutation(UPDATE_PRODUCT);

  const updateProduct = useCallback(
    (
      updateProductInput: UpdateProductInput,
    ): Promise<FetchResult<IProductItem>> =>
      mutate({
        variables: { updateProductInput },
      }),
    [mutate],
  );

  return { updateProduct };
};
